import React, { Component } from "react";
import GPExportedPO from "../components/gp_exported_po";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class GPExportedPOScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<GPExportedPO />
			</CSSTransitionGroup>
		);
	}
}

export default GPExportedPOScene;
