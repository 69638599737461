import React from "react";
import CryptoJS from "crypto-js";
import { setCookie } from "cookies";
import { withRouter } from "react-router-dom";
import LoginIndicator from "../../purch/common/login_indicator";

/* A login page that takes credentials from a user sends them to the server then receives and
stores a token in the browser local storage with name 'toki' */

class Login extends React.Component {
	saveLoginInfo = () => {
		let secret = "i9cjRt1";
		let encrypted = new URLSearchParams(this.props.location.search).get("redirect");
		let bytes = CryptoJS.AES.decrypt(encrypted, secret);
		let decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		setCookie("toki", decrypted.token, decrypted.expiry);
		setCookie("expiry", decrypted.expiry, decrypted.expiry);
		setCookie("userId", decrypted.userId, decrypted.expiry);
		setCookie("username", decrypted.username, decrypted.expiry);
		this.props.history.push("/home");
	};

	componentDidMount() {
		this.saveLoginInfo();
	}

	render() {
		return <LoginIndicator />;
	}
}

export default withRouter(Login);
