export const sideMenu = (translation) => {
	return {
		Home: [
			{
				icon: "pe-7s-home",
				label: translation("home"),
				to: "#/home",
			},
		],
		PR: [
			{
				icon: "bi bi-cart4",
				label: translation("purchaseRequest"),
				content: [
					{
						label: translation("newPurchaseRequest"),
						to: "#/newpr",
					},
					{
						label: translation("purchaseRequests"),
						to: "#/listpr",
					},
				],
			},
		],
		PO: [
			{
				icon: "bi bi-bag",
				label: translation("purchaseOrder"),
				content: [
					{
						label: translation("newPurchaseOrder"),
						to: "#/newpo",
					},
					{
						label: translation("purchaseOrders"),
						to: "#/listpo",
					},
					{
						label: translation("gpExport"),
						to: "#/gpExportPO",
					},
				],
			},
		],
		Receiving: [
			{
				icon: "bi bi-truck",
				label: translation("receiving"),
				content: [
					{
						label: translation("receivePOItems"),
						to: "#/receiving",
					},
				],
			},
		],
		trx: [
			{
				icon: "pe-7s-notebook",
				label: translation("transactions"),
				content: [
					{
						permission: "view_transaction",
						label: translation("viewTransactions"),
						to: "#/viewtransaction",
					},
				],
			},
		],
		supplier: [
			{
				icon: "bi bi-building",
				label: translation("supplier"),
				content: [
					{
						permission: "add_supplier",
						label: translation("newSupplier"),
						to: "#/new-supplier",
					},
					{
						permission: "view_supplier",
						label: translation("listSuppliers"),
						to: "#/list-supplier",
					},
				],
			},
		],
		dataStudioReports: [
			{
				icon: "pe-7s-news-paper",
				label: translation("dataStudioReports"),
				content: [
					{
						permission: "add_purchase_order",
						label: translation("prPoRawData"),
						to: "#/ds-pr-po-raw",
					},
				],
			},
		],
	};
};
