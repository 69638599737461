import beep from "../../assets/utils/sounds/beep.mp3";
import beep06 from "../../assets/utils/sounds/beep06.mp3";

function Beep() {
	let sound = new Audio(beep);
	sound.play();
}

function TimerBeep() {
	let sound = new Audio(beep06);
	sound.loop = true;
	sound.play();
	setTimeout(() => {
		sound.loop = false;
	}, 3000);
}

export default Beep;
export { TimerBeep };
