import React, {Component} from "react";
import Receiving from "../components/receiving";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ReceivingScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}>
				<Receiving />
			</CSSTransitionGroup>
		);
	}
}

export default ReceivingScene;
