import i18n from "i18n";
import axios from "axios";
import React, { Component } from "react";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AlertNotification } from "purch/common/alert_notification";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import { getCurrentMonthDate } from "purch/helper/date";
import { withRouter } from "react-router-dom";

class GPExportedPO extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			selectedPOs: [],
			exportedPOs: [],
			dates: { startDate: "", endDate: "", todayDate: "" },
		};
	}

	reExportPo = () => {
		this.setState({ loading: true });
		const poIDS = this.state.selectedPOs.map((po) => po.id);
		axios
			.put(`po/bulkupdate/`, { ids: poIDS, isExportedGP: false })
			.then((res) => {
				const remainingPOs = this.state.exportedPOs.filter((po) => !poIDS.includes(po.id));
				this.setState({ exportedPOs: remainingPOs, selectedPOs: [] }, () => {
					this.setState({ loading: false });
					this.props.history.push({ pathname: "/gpExportPO" });
				});
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else {
					AlertNotification(null, "error");
				}
				this.setState({ loading: false });
			});
	};

	handleSelectDate = (e) => {
		let newDate = this.state.dates;
		newDate[e.target.name] = e.target.value;
		this.setState({ dates: newDate });
	};

	getExportedPOS = () => {
		let startDate = this.state.dates.startDate + " 00:00";
		let endDate = this.state.dates.endDate + " 23:59";
		let dateRange = [startDate, endDate];
		this.setState({ loading: true });
		axios
			.get(`po/?serializer=get&status=Approved&isExportedGP=true&creationDate__range=${dateRange}&ordering=-creationDate`)
			.then((res) => {
				this.setState({ exportedPOs: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else {
					AlertNotification(null, "error");
				}
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		const dates = getCurrentMonthDate();
		this.setState({ dates }, () => {
			this.getExportedPOS();
		});
	}

	render() {
		const t = i18n.t;
		const headerFormatter = (column, colIndex) => <span>{t(column.text)}</span>;
		const columns = [
			{
				text: "code",
				dataField: "code",
				headerFormatter: headerFormatter,
			},
			{
				text: "status",
				dataField: "status",
				classes: (cell) => "text-info",
				headerFormatter: headerFormatter,
			},
			{
				text: "supplier",
				dataField: "supplier.name",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "totalCostAfterTax",
				dataField: "totalCostAfterTax",
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
				headerFormatter: headerFormatter,
			},
			{
				text: "orderDate",
				dataField: "creationDate",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
		];

		const selectRow = {
			mode: "checkbox",
			headerColumnStyle: { backgroundColor: "white" },
			onSelect: (row, isSelect, rowIndex, e) => {
				let selectedPOs = [...this.state.selectedPOs];
				if (isSelect) {
					selectedPOs.push(row);
				} else {
					selectedPOs = selectedPOs.filter((po) => po.id !== row.id);
				}
				this.setState({ selectedPOs: selectedPOs });
			},
			onSelectAll: (isSelect, rows, e) => {
				if (isSelect) {
					this.setState({ selectedPOs: rows });
				} else {
					this.setState({ selectedPOs: [] });
				}
			},
		};

		return (
			<>
				<Row>
					<Col>
						<PageTitleAlt2 heading={t("purchaseOrders")} icon="bi bi-bag icon-gradient bg-happy-fisher" />
					</Col>
				</Row>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<Row>
									<Col xs="12" sm="4" md="3" lg="3" xl="3">
										<FormGroup>
											<Label>{t("startDate")}</Label>
											<Input
												type="date"
												name="startDate"
												onChange={this.handleSelectDate}
												max={this.state.dates.endDate}
												value={this.state.dates.startDate}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="4" md="3" lg="3" xl="3">
										<FormGroup>
											<Label>{t("endDate")}</Label>
											<Input
												type="date"
												name="endDate"
												onChange={this.handleSelectDate}
												min={this.state.dates.startDate}
												max={this.state.dates.todayDate}
												value={this.state.dates.endDate}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="4" md="2" lg="2" xl="2">
										<GetPermission perm="purch.view_purchaseorder">
											<div code="perm">
												<Button color="info" style={{ marginTop: "30px" }} onClick={() => this.getExportedPOS()}>
													{t("submit")}
												</Button>
											</div>
										</GetPermission>
									</Col>
									<Col className="text-right mb-4 mx-2">
										<GetPermission perm="purch.change_purchaseorder">
											<Button className="right" color="dark" disabled={this.state.selectedPOs.length === 0} onClick={this.reExportPo} code="perm">
												{t("gpReExport")}
											</Button>
										</GetPermission>
									</Col>
								</Row>
								<TableWithPaginationSearch
									scrollable
									search
									keyfield="id"
									columns={columns}
									data={this.state.exportedPOs}
									loading={this.state.loading}
									selectRow={selectRow}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		);
	}
}
export default withRouter(GPExportedPO);
