import moment from "moment";
import { getCookie } from "cookies";
import { Loader } from "react-loaders";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import { Card, ListGroup, ListGroupItem, CardHeader, Button } from "reactstrap";

class Approval extends Component {
	render() {
		const { t } = this.props;
		return (
			<LoadingOverlay
				tag="div"
				active={this.props.approvalLoading}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab">
						<i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher"> </i>
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">{t("approvals")}</div>
					</CardHeader>
					<ListGroup className="todo-list-wrapper" flush>
						{this.props.prApprovals.map((approval, index) => {
							let colors = { Approved: "info", Rejected: "danger", Pending: "warning" };
							let status = approval.approvalUser ? (approval.approved ? "Approved" : "Rejected") : "Pending";
							let previousApproval = approval.sortOrder > 1 ? !this.props.prApprovals[index - 1].approved : false;
							let approvalDate = moment(approval.approvalDate).format("DD-MM-YYYY hh:mm a");
							return (
								<ListGroupItem key={index}>
									<div className={`todo-indicator bg-${colors[status]}`} />
									<div className="widget-content p-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left mr-2"></div>
											<div className="widget-content-left">
												<div className="widget-heading">
													{approval.prApprovalEntity.title}
													<div className={`badge badge-${colors[status]} ml-2`}>{status}</div>
												</div>
												<div className="widget-subheading">
													<i>
														{approval.approvalUser ? (
															<div>
																<span>{approval.approvalUser.username}</span> @ <span>{approvalDate}</span>
															</div>
														) : (
															"....."
														)}
													</i>
												</div>
											</div>
											<div
												className="widget-content-right "
												hidden={
													!approval.prApprovalEntity.approvalUser.includes(Number(getCookie("userId"))) || previousApproval
												}>
												<div hidden={approval.approvalUser}>
													<Button index={index} color="info" approved="true" onClick={this.props.setapprovals}>
														{t("approve")}
													</Button>
													<Button index={index} color="danger" className="ml-1" onClick={this.props.setapprovals}>
														{t("reject")}
													</Button>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							);
						})}
					</ListGroup>
				</Card>
			</LoadingOverlay>
		);
	}
}

export default withTranslation()(Approval);
