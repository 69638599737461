import React, {Component} from "react";

class EditIcon extends Component {
	render() {
		return (
			<i
				hidden={this.props.hidden}
				onClick={this.props.onEdit}
				className={`bi bi-pencil-square info-hover ${this.props.cssclass}`}
				style={{fontSize: this.props.iconsize || "1rem", cursor: "pointer"}}
			/>
		);
	}
}
export default EditIcon;
