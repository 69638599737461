import React from "react";

const DeleteIcon = (props) => {
	return (
		<i
			index={props.index}
			hidden={props.hidden}
			onClick={props.ondelete}
			cardindex={props.cardindex}
			techindex={props.techindex}
			style={{fontSize: props.iconsize || "1rem", cursor: "pointer"}}
			className={`bi bi-trash danger-hover ${props.cssclass}`}></i>
	);
};

export default DeleteIcon;
