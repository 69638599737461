import { saveAs } from "file-saver";
import * as windows1256 from "../../../windows-1256";

const formatMacro = (data) => {
	let poMacro = `# DEXVERSION=12.00.0270.000 2 2\r\n\
CheckActiveWin dictionary 'default'  form 'POP_PO_Entry' window 'POP_PO_Entry' \r\n\
  MoveTo field 'PO Number' \r\n\
  TypeTo field 'PO Number' , '${data["code"]}'\r\n\
  MoveTo field 'Vendor ID' \r\n\
  TypeTo field 'Vendor ID' , '${data["supplier"]}'\r\n\
  MoveTo field 'Vendor Name' \r\n\
# Project Accounting information for this vendor does not exist.  Do you want to add the vendor's project information?\r\n\
NewActiveWin dictionary 'Project Accounting'  form DiaLog window DiaLog \r\n\
  MoveTo field CANCEL \r\n\
  ClickHit field CANCEL \r\n\
NewActiveWin dictionary 'default'  form 'POP_PO_Entry' window 'POP_PO_Entry' \r\n\
  MoveTo field 'Document Date' \r\n\
  TypeTo field 'Document Date' , '${data["creationDate"]}'`;

	let nextItemMacro = `  TransLinePrepare scrollwin 'Line_Scroll' \r\n\
	ScrollByLine down scrollwin 'Line_Scroll' `;

	let saveItemMacro = `  MoveTo field 'Save Button' \r\n\
  ClickHit field 'Save Button' `;

	// POItems macro format
	data["poItems"].forEach((poItem, index) => {
		// '3' here is the exact number of line items that appears in the Microsoft's dynamics GP Purchasing window
		// Because the macro when the macro MoveTo line 'x' commnad runs, 'x' means the line position on the screen not the number on PO
		// EG: if the PO has 5 Items and the PO window can only show 3 then item 4 and 5 would both have line No. '3'
		let lineNo = Math.min(index + 1, 3);
		let poItemMacro = `# Key 1: \r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'Item Number' \r\n\
# Key 1: '', '0', '0'\r\n\
  TypeTo line ${lineNo} scrollwin 'Line_Scroll' field 'Item Number' , '${poItem["itemCode"]}'\r\n\
# Key 1: '', '0', '0'\r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'U Of M' \r\n\
# Key 1: '', '0', '0'\r\n\
  TypeTo line ${lineNo} scrollwin 'Line_Scroll' field 'U Of M' , '${poItem["uom"] || ""}'\r\n\
# Key 1: '', '0', '0'\r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'QTY Ordered' \r\n\
# Key 1: '', '0', '0'\r\n\
  TypeTo line ${lineNo} scrollwin 'Line_Scroll' field 'QTY Ordered' , '${
			Number.isInteger(Number(poItem["quantity"])) ? Math.trunc(poItem["quantity"]) + "." : poItem["quantity"]
		}'\r\n\
# Key 1: '', '0', '0'\r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'Unit Cost' \r\n\
# Key 1: '', '0', '0'\r\n\
  TypeTo line ${lineNo} scrollwin 'Line_Scroll' field 'Unit Cost' , '${poItem["unitPrice"] || ""}'\r\n\
# Key 1: '', '0', '0'\r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'Location Code' \r\n\
# Key 1: '', '0', '0'\r\n\
  TypeTo line ${lineNo} scrollwin 'Line_Scroll' field 'Location Code' , '${poItem["warehouse"] || ""}'\r\n\
# Key 1: '', '0', '0'\r\n\
  MoveTo line ${lineNo} scrollwin 'Line_Scroll' field 'Extended Cost' `;

		if (index !== 0) poMacro += "\r\n" + nextItemMacro;
		poMacro += "\r\n" + poItemMacro;
	});

	poMacro += "\r\n" + saveItemMacro;

	return poMacro;
};

const downloadFile = (data, filename) => {
	data = windows1256.encode(data);
	var uint8 = new Uint8Array(data);
	let blob = new Blob([uint8], { type: "text/plain;charset=windows-1256" });
	saveAs(blob, filename);
};

const exportMacroFile = (pos) => {
	let script = "";
	let poCodes = [];
	pos.forEach((po) => {
		poCodes.push(po.code);
		script += formatMacro(po) + "\r\n";
	});
	downloadFile(script, `${poCodes.join(" ")}.mac`);
};

export default exportMacroFile;
