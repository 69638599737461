import axios from "axios";
import POForm from "./po_form";
import { Loader } from "react-loaders";
import Wizard from "../../common/wizard";
import DropDown from "../../common/drop_down";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Card, CardBody, Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import { AlertNotification } from "../../common/alert_notification";
import PRItems from "../../purchase_request_item/components/pr_items";

class NewPO extends Component {
	constructor(props) {
		super(props);

		this.state = {
			add: true,
			prTypes: [],
			prItems: [],
			poItems: [],
			paymentTerms: [],
			purchaseOrder: {
				id: null,
				code: "pr",
				supplier: null,
				paymentTerm: null,
				offerNumber: "",
				offerDate: null,
				deliveryFees: 0,
			},
			isFormValid: true,
			inputValidation: {},
			paymentTerm: null,
			disableNext: true,
			formLoading: false,
			renderPOForm: false,
			selectedSupplier: null,
			nonSelectablePRItems: [],
			disableSelectType: false,
			installments: [{ days: "", percentage: 100 }],
			selectedPRType: { id: 0, name: this.props.t("selectPRType") },
		};
	}

	componentDidMount() {
		this.setState({ formLoading: true });
		let poItems = this.props.history.location.poItems,
			prType = this.props.history.location.prType;
		if (poItems && poItems.length !== 0 && prType) {
			let purchaseOrder = this.state.purchaseOrder;
			purchaseOrder["poType"] = prType.id;
			this.setState({
				poItems: poItems,
				selectedPRType: prType,
				disableSelectType: true,
				renderPOForm: true,
				purchaseOrder: purchaseOrder,
			});
		}

		axios
			.get(`purchaseType/?serializer=get`)
			.then((res) =>
				this.setState({ prTypes: res.data }, () => {
					this.setState({ formLoading: false });
				})
			)
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
				this.setState({ formLoading: false });
			});
	}

	onSelectPRType = (event) => {
		const prTypeId = parseInt(event.target.getAttribute("data-id"));
		let purchaseOrder = this.state.purchaseOrder;
		purchaseOrder["poType"] = prTypeId;
		this.setState({ purchaseOrder });
		this.getPRItemsAPI(prTypeId);
	};

	getPRItemsAPI = (prTypeId) => {
		this.setState({ formLoading: true });
		axios
			.get(
				`purchaseRequestItem/?serializer=get&status__ne=Received&purchaseRequest__prType=${prTypeId}&purchaseRequest__status=Approved`
			)
			.then((res) => {
				let nonSelectablePRItems = [];
				for (let prItem of res.data) {
					if (prItem.originalQTY !== prItem.quantity) nonSelectablePRItems.push(prItem.id);
				}
				this.setState({ nonSelectablePRItems: nonSelectablePRItems, prItems: res.data, formLoading: false });
			})
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
				this.setState({ formLoading: true });
			});
	};

	calculateTotalCostBeforeTaxPerItem = (item) => {
		if (item["quantity"] && item["unitPrice"]) {
			item["unitPriceAfterDiscount"] = item["unitPrice"] - item["unitPrice"] * (item["discount"] / 100);
			return item["quantity"] * item["unitPriceAfterDiscount"];
		}
		return null;
	};

	calculateTotalCostAfterTaxPerItem = (item) => {
		let totalCostAfterTax = item.totalCostBeforeTax;
		if (this.state.selectedSupplier.hasTax) {
			totalCostAfterTax = totalCostAfterTax + item.totalCostBeforeTax * (this.state.purchaseOrder.tax / 100);
			if (this.state.selectedSupplier.hasWithHolding)
				totalCostAfterTax = totalCostAfterTax - item.totalCostBeforeTax * (item.withHoldingTax / 100);
		}
		return parseFloat(totalCostAfterTax.toFixed(2));
	};

	calculatePOTotalCost = () => {
		let { purchaseOrder, poItems } = this.state;
		let totalCostBeforeTax = 0;
		let totalCostAfterTax = 0;
		poItems.forEach((item) => {
			totalCostBeforeTax += item.totalCostBeforeTax;
			totalCostAfterTax += item.totalCostAfterTax;
		});
		purchaseOrder["totalCostBeforeTax"] = totalCostBeforeTax;
		purchaseOrder["totalCostAfterTax"] = parseFloat(totalCostAfterTax.toFixed(2));
		if (parseFloat(purchaseOrder["deliveryFees"]))
			purchaseOrder["totalCostAfterTax"] =
				parseFloat(purchaseOrder["totalCostAfterTax"]) + parseFloat(purchaseOrder["deliveryFees"]);

		this.setState({ purchaseOrder: purchaseOrder });
	};

	handleNumericInput = (event, itemIndex, fieldName) => {
		let poItems = [...this.state.poItems];
		let item = { ...poItems[itemIndex] };
		item[fieldName] = event.target.value || null;
		if (item[fieldName]) {
			item[`is${fieldName}InValid_${itemIndex}`] = false;
		}

		let totalCost = this.calculateTotalCostBeforeTaxPerItem(item);
		if (totalCost) {
			item.totalCostBeforeTax = parseFloat(totalCost.toFixed(2));
			item.totalCostAfterTax = this.calculateTotalCostAfterTaxPerItem(item);
		}
		poItems[itemIndex] = item;
		this.setState({ poItems: poItems }, function() {
			this.calculatePOTotalCost();
		});
	};

	handleNote = (event, itemIndex) => {
		let poItems = [...this.state.poItems];
		poItems[itemIndex]["note"] = event.target.value;
		this.setState({ poItems: poItems });
	};

	handleOnSelectSupplier = (selectedSupplier) => {
		let { purchaseOrder, poItems } = this.state;
		purchaseOrder["supplier"] = selectedSupplier.id;
		purchaseOrder["paymentTerm"] = selectedSupplier.paymentTerm.id;
		purchaseOrder["tax"] = null;
		if (selectedSupplier.hasTax) purchaseOrder["tax"] = selectedSupplier.tax.VAT;
		if (selectedSupplier.hasWithHolding) {
			poItems = poItems.map((item) => {
				item["withHoldingTax"] = selectedSupplier.tax.withHolding;
				return item;
			});
		}
		this.setState({
			purchaseOrder,
			poItems,
			selectedSupplier: selectedSupplier,
			supplierInValid: false,
			paymentTerm: selectedSupplier.paymentTerm,
		});
	};

	handleOnSelectPaymentTerm = (selectedPaymentTerm) => {
		let purchaseOrder = this.state.purchaseOrder;
		purchaseOrder["paymentTerm"] = selectedPaymentTerm.id;
		this.setState({ purchaseOrder, paymentTerm: selectedPaymentTerm });
	};

	handleInputChange = (event) => {
		let { purchaseOrder, inputValidation } = this.state;
		const fieldName = event.target.name;
		purchaseOrder[fieldName] = event.target.value;
		inputValidation[`is${fieldName}InValid`] = false;

		if (fieldName === "offerNumber" && event.target.value === "") {
			purchaseOrder["offerDate"] = null;
			inputValidation[`isofferDateInValid`] = false;
		}

		this.setState({ purchaseOrder, inputValidation }, function() {
			if (fieldName === "deliveryFees") this.calculatePOTotalCost();
		});
	};

	getRemainingPercentage = () => {
		let totalPercentage =
			this.state.installments.reduce(
				(previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue.percentage),
				0
			) || 0;
		if (totalPercentage === 0) return 0;
		return 100 - totalPercentage;
	};

	handleInstallmentChange = (event) => {
		let index = event.target.getAttribute("index");
		let { installments, inputValidation } = this.state;
		installments.at(index)[event.target.name] = parseFloat(event.target.value) || 0;
		inputValidation[`is${event.target.name}InValid_${index}`] = false;
		if (event.target.name === "percentage") {
			let remainingPercentage = this.getRemainingPercentage();
			let lastIndex = installments.length - 1;
			installments[lastIndex]["percentage"] += remainingPercentage;
			installments[lastIndex]["percentage"] =
				installments[lastIndex]["percentage"] < 0 ? 0 : installments[lastIndex]["percentage"];
		}
		this.setState({ installments });
	};

	addNewInstallment = () => {
		let installments = this.state.installments;
		installments.splice(-1, 0, { days: "", percentage: "" });
		this.setState({ installments });
	};

	removeInstallment = (event) => {
		let index = event.target.getAttribute("index");
		let installments = this.state.installments;
		installments.splice(index, 1);
		installments.at(-1)["percentage"] += this.getRemainingPercentage();
		this.setState({ installments });
	};

	onInvalidForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.name;
		const validationMessage = event.target.validationMessage;
		let { inputValidation } = this.state;
		let poItems = [...this.state.poItems];

		let inputWithIndex = ["percentage", "days"];
		let poItemInput = ["quantity", "unitPrice", "discount"];

		if (inputWithIndex.includes(fieldName)) {
			inputValidation[`is${fieldName}InValid_${event.target.getAttribute("index")}`] = true;
			inputValidation[`${fieldName}Feedback_${event.target.getAttribute("index")}`] = validationMessage;
		} else if (poItemInput.includes(fieldName)) {
			const index = event.target.getAttribute("index");
			let item = { ...poItems[index] };
			item[`is${fieldName}InValid_${index}`] = true;
			item[`${fieldName}Feedback_${index}`] = validationMessage;
			poItems[index] = item;
			this.setState({ poItems: poItems });
		} else {
			inputValidation[`is${fieldName}InValid`] = true;
			inputValidation[`${fieldName}Feedback`] = validationMessage;
		}
		this.setState({ isFormValid: false, inputValidation, poItems });
	};

	onSubmit = (event) => {
		event.preventDefault();
		let { poItems, purchaseOrder, installments } = this.state;
		purchaseOrder["poItems"] = poItems;
		purchaseOrder["installment"] = this.state.purchaseOrder.paymentTerm === 9 ? installments : null;

		let totalPercentage =
			installments.reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue.percentage), 0) ||
			0;
		if (totalPercentage !== 100) {
			AlertNotification("Please handle percentage as total is not equal 100%", "error");
			return;
		}

		if (purchaseOrder["offerDate"]) purchaseOrder["offerDate"] += " 00:00";
		this.setState({ formLoading: true });
		axios
			.post(`po/`, purchaseOrder)
			.then((res) => {
				purchaseOrder["id"] = res.data.id;
				this.setState({ purchaseOrder, formLoading: false });
				this.handleNextButton(true);
				AlertNotification("PO Created Successfully", "success");
				this.props.history.push({
					pathname: "/viewpo/",
					poID: this.state.purchaseOrder.id,
				});
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error) console.log(error.response);
				AlertNotification(null, "error");
			});
	};

	/* To enable or disable next button in wizard */
	handleNextButton = (disableValue) => this.setState({ disableNext: disableValue });

	makePOTypeDisabled = () => this.setState({ disableSelectType: true });

	render() {
		const { t } = this.props;
		const selectRow = {
			mode: "checkbox",
			nonSelectable: this.state.nonSelectablePRItems,
			onSelect: (row, isSelect) => {
				let { poItems } = this.state;
				if (isSelect) {
					row["purchaseRequestItem"] = row.id;
					row["note"] = "";
					row["withHoldingTax"] = null;
					row["discount"] = 0;
					row["unitPriceAfterDiscount"] = 0;
					row["totalCostBeforeTax"] = 0;
					row["totalCostAfterTax"] = 0;
					poItems.push(row);
				} else {
					const prItemIndex = poItems.findIndex((item) => item.id === row.id);
					poItems.splice(prItemIndex, 1);
				}
				this.setState({ poItems });
				this.handleNextButton(false);
			},
		};

		const wizards = [
			{
				name: t("purchaseRequestItems"),
				component: <PRItems prItems={this.state.prItems} selectRow={selectRow} />,
			},
			{
				name: t("purchaseOrdersDetails"),
				component: (
					<POForm
						poItems={this.state.poItems}
						installments={this.state.installments}
						addNewInstallment={this.addNewInstallment}
						removeInstallment={this.removeInstallment}
						handleNote={this.handleNote}
						handleNumericInput={this.handleNumericInput}
						handleNextButton={this.handleNextButton}
						purchaseOrder={this.state.purchaseOrder}
						handleOnSelectSupplier={this.handleOnSelectSupplier}
						paymentTerm={this.state.paymentTerm}
						handleOnSelectPaymentTerm={this.handleOnSelectPaymentTerm}
						handleInputChange={this.handleInputChange}
						handleOnChangeTax={this.handleOnChangeTax}
						handleInstallmentChange={this.handleInstallmentChange}
						onSubmit={this.onSubmit}
						onInvalidForm={this.onInvalidForm}
						inputValidation={this.state.inputValidation}
						isFormValid={this.state.isFormValid}
						disabledSubmit={!this.state.disableNext}
						makePOTypeDisabled={this.makePOTypeDisabled}
					/>
				),
			},
		];

		return (
			<Fragment>
				<PageTitleAlt2 heading={t("createPurchaseOrder")} icon="bi bi-bag-plus icon-gradient bg-happy-fisher" />

				<Row>
					<Col>
						<DropDown
							title={this.state.selectedPRType.name}
							values={this.state.prTypes}
							disabled={this.state.disableSelectType}
							onclick={this.onSelectPRType}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="12" lg="12">
						<LoadingOverlay
							tag="div"
							active={this.state.formLoading}
							styles={{
								overlay: (base) => ({
									...base,
									background: "#fff",
									opacity: 0.5,
								}),
							}}
							spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
							<Card className="card-hover-shadow-2x main-card mb-3">
								<CardBody>
									<Wizard
										id={this.state.purchaseOrder.id}
										showNavigation={true}
										disableNext={this.state.disableNext}
										compState={this.state.renderPOForm ? 1 : null}
										steps={wizards}
										showPrevious={false}
										perm="purch.add_purchaseorder"
									/>
								</CardBody>
							</Card>
						</LoadingOverlay>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withTranslation()(withRouter(NewPO));
