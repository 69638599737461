import React, { Component } from "react";
import NewSupplier from "../components/new_supplier";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewSupplierScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}>
				<NewSupplier />
			</CSSTransitionGroup>
		);
	}
}

export default NewSupplierScene;
