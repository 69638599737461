import React from "react";

const UpdateIcon = (props) => {
	return (
		<i
			hidden={props.hidden}
			onClick={props.onupdate}
			style={{ fontSize: props.iconSize || "1rem", cursor: "pointer" }}
			className={`bi bi-check-lg ${props.onHovercolor || "success"}-hover ${props.cssclass}`}></i>
	);
};
export default UpdateIcon;
