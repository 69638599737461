import Swal from "sweetalert2";

function DeletePopup(props) {
	return Swal.fire({
		icon: "warning",
		text: props.text,
		title: props.title,
		input: props.input,
		inputPlaceholder: props.inputPlaceholder,
		buttonsStyling: false,
		inputValidator: (result) => !result && props.onInvalidMSG,
		showCancelButton: true,
		cancelButtonText: props.cancelbtntxt,
		confirmButtonText: props.confirmbtntxt,
		customClass: {
			icon: "icon-danger",
			confirmButton: "btn btn-danger",
			cancelButton: "btn btn-dark ml-3",
		},
	}).then((result) => {
		if (result.isConfirmed) {
			props.confirmedresult(result);
		}
	});
}
export default DeletePopup;
