import React, {Component} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ListPO from "../components/list_pos";


class ListPOScene extends Component {

    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>

                <ListPO/>

            </CSSTransitionGroup>
        );
    }
}

export default ListPOScene;