import axios from "axios";
import { Pie } from "react-chartjs-2";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

class POGraphs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataLoaded: false,
			poGraphData: null,
			poTypeData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: ["#82ca9d", "#6cc3d5"],
						hoverBackgroundColor: ["#52ad8d", "#17A2B8FF"],
					},
				],
			},
		};
		this.poStatus = [
			{ id: 1, name: "Pending", status: "Pending", color: "warning" },
			{ id: 2, name: "Approved", status: "Approved", color: "info" },
			{ id: 3, name: "Rejected", status: "Rejected", color: "danger" },
		];
	}

	componentDidMount() {
		axios
			.get("po/poGraphData")
			.then((res) => {
				let poTypeData = { ...this.state.poTypeData };
				res.data.poTypeCount.forEach((type) => {
					poTypeData.datasets[0].data.push(type["poTypeCount"]);
					poTypeData.labels.push(type["poType__name"]);
				});
				this.setState({ poGraphData: res.data, poTypeData: poTypeData, dataLoaded: true });
			})
			.catch((error) => {
				console.error(error);
			});
	}

	getStatusColor = (status) => this.poStatus.find((element) => element.status === status).color;

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				{this.state.dataLoaded && (
					<Fragment>
						<Row>
							<Col sm={12} md={12} lg={6} xl={6}>
								<Card className="main-card mb-3">
									<CardBody>
										<CardTitle className="text-capitalize text-dark">{t("posPerSupplier")}</CardTitle>
										<ResponsiveContainer width="100%" aspect={2.5}>
											<BarChart data={this.state.poGraphData.numberOfPOPerSupplier}>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis dataKey="supplier__name" />
												<YAxis dataKey="POs" />
												<Tooltip />
												<Legend />
												<Bar dataKey="POs" barSize={30} fill="#82ca9d" />
											</BarChart>
										</ResponsiveContainer>
									</CardBody>
								</Card>
							</Col>
							<Col sm={12} md={12} lg={6} xl={6}>
								<Card className="main-card mb-3">
									<CardBody>
										<CardTitle className="text-capitalize text-dark">{t("posPerType")}</CardTitle>
										<ResponsiveContainer width="100%" aspect={2.5}>
											<Pie data={this.state.poTypeData} />
										</ResponsiveContainer>
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Row>
							{this.state.poGraphData.poApprovalStatusCount.map((statusData, index) => (
								<Col md="4" key={index}>
									<div className="card mb-3 widget-chart widget-chart-hover">
										<div className="widget-subheading">{t(`totalNumberOf${statusData.status}PO`)}</div>
										<div className="icon-wrapper rounded-circle">
											<div className={`icon-wrapper-bg bg-${this.getStatusColor(statusData.status)}`} />
											<i className={`bi bi-bag text-${this.getStatusColor(statusData.status)}`} />
										</div>
										<div className="widget-numbers">
											<CountUp
												start={0}
												end={statusData.statusCount}
												separator=""
												decimals={0}
												decimal=","
												prefix=""
												useEasing={false}
												suffix=""
												duration="2"
											/>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default withTranslation()(POGraphs);
