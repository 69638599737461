import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CardHeader, Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "../../common/get_permission";
import PRGraph from "../../dashboard/components/pr_graphs";
import POGraphs from "../../dashboard/components/po_graphs";

class Home extends Component {
	render() {
		const { t } = this.props;
		return (
			<>
				<PageTitleAlt2 heading={t("home")} icon="pe-7s-home icon-gradient bg-happy-fisher" />
				<GetPermission perm="purch.view_purchaseorder">
					<div code="perm">
						<CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
							<div className="card-header-title fsize-2 text-capitalize font-weight-normal text-dark">
								{t("purchaseRequests")}
							</div>
						</CardHeader>
						<Row>
							<Col>
								<PRGraph />
							</Col>
						</Row>
						<Row className="divider"> </Row>
					</div>
				</GetPermission>
				<GetPermission perm="purch.view_purchaseorder">
					<div code="perm">
						<CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
							<div className="card-header-title fsize-2 text-capitalize font-weight-normal text-dark">{t("purchaseOrders")}</div>
						</CardHeader>
						<Row>
							<Col>
								<POGraphs />
							</Col>
						</Row>
					</div>
				</GetPermission>
			</>
		);
	}
}

export default withTranslation()(Home);
