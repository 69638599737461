import i18n from "i18n";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";

class PrintTransaction extends Component {
	render() {
		return (
			<Row className="p-3">
				<Col xs="12" sm="12" md="12" lg="12" xl="12" className="my-2">
					<div>
						{i18n.t("from")} {this.props.startDate} {i18n.t("to")} {this.props.endDate}
					</div>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<BootstrapTable striped condensed classes="small" keyField="code" columns={this.props.columns} data={this.props.data} />
				</Col>
			</Row>
		);
	}
}

export default PrintTransaction;
