import { Col, Row } from "reactstrap";
import React, { Component } from "react";

class DataStudioRaw extends Component {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		return (
			<>
				<Row>
					<Col xs="12">
						<iframe
							allowFullScreen
							title="tripDetailCost"
							style={{ border: 0, height: "1000px", width: "100%" }}
							src="https://lookerstudio.google.com/embed/reporting/ac6ff31c-28bd-4a83-9493-25e0d84745d5/page/ar76C"></iframe>
					</Col>
				</Row>
			</>
		);
	}
}

export default DataStudioRaw;
