import React, {Component} from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import GetPermission from "../../common/get_permission";
import ViewPRApprovalCycle from "../components/view_approval_cycle";

class ViewPRApprovalCycleScene extends Component {
    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <GetPermission perm="purch.view_prapprovalscycle">
                    <div code="perm">
                        <ViewPRApprovalCycle />
                    </div>
                </GetPermission>
            </CSSTransitionGroup>
        );
    }
}

export default ViewPRApprovalCycleScene;
