import React, {Component} from "react";
import GetPermission from "../../common/get_permission";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ListPRApprovalCycle from "../components/list_pr_approval_cycles";

class ListPRApprovalScene extends Component {

    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <GetPermission perm="purch.add_prapprovalscycle">
                    <div code="perm">
                        <ListPRApprovalCycle />
                    </div>
                </GetPermission>
            </CSSTransitionGroup>
        );
    }
}

export default ListPRApprovalScene;
