import moment from "moment";
import { getCookie } from "cookies";

function calculateTokenTTL() {
	let currentTime = moment();
	let expiryTime = moment(getCookie("expiry"));
	let ttl = expiryTime.diff(currentTime, "seconds");
	return ttl;
}

export { calculateTokenTTL };
