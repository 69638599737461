import axios from "axios";
import i18n from "i18n";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import DeletePopup from "purch/common/delete_popup";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Row, Col, Card, CardBody, Button, Input, Form, FormFeedback } from "reactstrap";

class Receiving extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			formLoading: false,
		};
	}

	getValue = (e) => {
		let items = [...this.state.items];
		let item = { ...items[e.target.getAttribute("index")] };
		item[e.target.name] = e.target.value;
		item[`invalid_${e.target.name}`] = false;
		items[e.target.getAttribute("index")] = item;
		this.setState({ items: items });
	};

	receive = (e) => {
		this.setState({ formLoading: true });
		let items = [...this.state.items];
		let item = { ...items[e.submitter.getAttribute("index")] };
		if (!item.QTY) {
			this.setState({ formLoading: false });
			return;
		}
		let status = "Pending";
		let quantity = item.quantity - item.QTY;
		if (quantity === 0) status = "Received";
		let data = {
			id: item.id,
			status: status,
			quantity: quantity,
			actionQTY: item.QTY,
			receivedQuantity: Number(item.QTY) + Number(item.receivedQuantity),
		};
		// The following condition is only for Reliance please refactor the code if another client is using the app or if the settings project is being coded.
		if (item.purchaseOrderItem.purchaseRequestItem.purchaseRequest.prType !== "Administrative")
			data.deliveryData = {
				quantity: item.QTY,
				inspectionCompleted: false,
				wh: item.purchaseOrderItem.warehouse,
				displayUoM: item.purchaseOrderItem.uom.id,
				po: item.purchaseOrderItem.purchaseOrder.code,
				catalogItem: item.purchaseOrderItem.catalogItem.id,
				supplier: item.purchaseOrderItem.purchaseOrder.supplier,
				cost: item.purchaseOrderItem.unitPriceAfterDiscount,
			};
		axios
			.patch(`receiving/${item.id}/?serializer=update`, data)
			.then((res) => {
				if (res) {
					item.quantity = res.data.quantity;
					item.receivedQuantity = res.data.receivedQuantity;
					item.QTY = "";
					items[e.submitter.getAttribute("index")] = item;
					this.setState({ items: items, formLoading: false });
					AlertNotification(`Updated`, "success");
				}
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	getRejectionReason = (e) => {
		DeletePopup({
			input: "text",
			inputPlaceholder: i18n.t("rejectReasonAndALternative"),
			onInvalidMSG: i18n.t("pleaseAddArejectionReasonAndRejectedItemAlternative"),
			cancelbtntxt: i18n.t("cancel"),
			confirmbtntxt: i18n.t("reject"),
			title: i18n.t("rejectionReason"),
			confirmedresult: (result) => this.reject(e, result.value),
		});
	};

	reject = (e, rejectionValue) => {
		this.setState({ formLoading: true });
		if (!rejectionValue) return AlertNotification(`Please provide a rejection reason`, `error`);
		let items = [...this.state.items];
		let item = { ...items[e.submitter.getAttribute("index")] };
		if (!item.QTY) {
			this.setState({ formLoading: false });
			return;
		}
		let status = "Pending";
		let quantity = item.quantity - item.QTY;
		if (quantity === 0) status = "Received";
		axios
			.patch(`receiving/${item.id}/?serializer=update`, {
				id: item.id,
				status: status,
				quantity: quantity,
				actionQTY: item.QTY,
				rejectionReason: rejectionValue,
				currentRejectedQuantity: Number(item.QTY),
				rejectedQuantity: Number(item.QTY) + Number(item.rejectedQuantity),
			})
			.then((res) => {
				if (res) {
					item.quantity = res.data.quantity;
					item.rejectedQuantity = res.data.rejectedQuantity;
					item.QTY = "";
					items[e.submitter.getAttribute("index")] = item;
					this.setState({ items: items });
					AlertNotification(`Updated`, "success");
				}
				this.setState({ formLoading: false });
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	archive = (e, item) => {
		this.setState({ formLoading: true });
		axios
			.patch(`receiving/${item.id}/?serializer=archive`, { id: item.id, isArchived: true })
			.then((res) => {
				if (res) {
					this.getReceivings();
				}
				this.setState({ formLoading: false });
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	inValidateField = (e) => {
		e.preventDefault();
		let items = [...this.state.items];
		let item = { ...items[e.target.getAttribute("index")] };
		item[`invalid_${e.target.name}`] = true;
		item[`${e.target.name}_feedback`] = e.target.validationMessage;
		items[e.target.getAttribute("index")] = item;
		this.setState({ items: items });
	};

	getStatusColor = (cell) => {
		if (cell === "Pending") {
			return "text-warning";
		} else if (cell === "Rejected") {
			return "text-danger";
		} else return "text-success";
	};

	getReceivings = () => {
		axios
			.get(`receiving/?status=Pending&isArchived=false`)
			.then((res) => {
				let indexedData = res.data.map((obj, index) => {
					obj.index = index;
					return obj;
				});
				this.setState({ items: indexedData });
			})
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	componentDidMount() {
		this.getReceivings();
	}

	render() {
		const { t } = this.props;
		const headerFormatter = (column) => <div style={{ width: "180px", minWidth: "fit-content" }}>{column.text}</div>;
		const columns = [
			{
				text: t("prNumber"),
				headerFormatter: headerFormatter,
				dataField: "purchaseOrderItem.purchaseRequestItem.purchaseRequest.code",
			},
			{
				text: t("poNumber"),
				headerFormatter: headerFormatter,
				dataField: "purchaseOrderItem.purchaseOrder.code",
			},
			{
				text: t("itemCode"),
				headerFormatter: headerFormatter,
				dataField: "purchaseOrderItem.catalogItem.itemCode",
			},
			{
				text: t("itemName"),
				headerFormatter: headerFormatter,
				dataField: "purchaseOrderItem.catalogItem.name",
			},
			{
				text: t("uom"),
				headerFormatter: headerFormatter,
				dataField: "purchaseOrderItem.uom.abbreviation",
			},
			{
				text: t("quantity"),
				headerFormatter: headerFormatter,
				dataField: "quantity",
			},
			{
				text: t("quantityToReceiveOrReject"),
				headerFormatter: headerFormatter,
				dataField: "rec",
				formatter: (cell, row) => {
					return (
						<Fragment>
							<Input
								min={0}
								name="QTY"
								type="number"
								index={row.index}
								value={row.QTY && row.QTY}
								max={row.quantity}
								onChange={this.getValue}
								invalid={this.state.items[row.index][`invalid_QTY`]}
								disabled={this.state.items[row.index].isArchived}
							/>
							<FormFeedback>{this.state.items[row.index]["QTY_feedback"]}</FormFeedback>
						</Fragment>
					);
				},
			},
			{
				text: t("actions"),
				dataField: "action",
				headerFormatter: (column) => <div style={{ width: "300px", minWidth: "fit-content" }}>{column.text}</div>,
				formatter: (cell, row) => {
					return (
						<Fragment>
							<GetPermission perm="purch.change_receiving">
								<div code="perm">
									<Button color="info" type="submit" name="receive" index={row.index} disabled={Number(row.quantity) === 0}>
										{t("receive")}
									</Button>
									<Button color="danger" className="ml-1" name="reject" index={row.index} disabled={Number(row.quantity) === 0}>
										{t("reject")}
									</Button>
									<Button
										color="dark"
										className="ml-1"
										name="archive"
										onClick={(e) => this.archive(e, row)}
										index={row.index}
										disabled={Number(row.quantity) === 0}
									>
										{t("archive")}
									</Button>
								</div>
							</GetPermission>
						</Fragment>
					);
				},
			},
		];
		return (
			<Row>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<PageTitleAlt2 heading={t("receivePurchasedItems")} icon="pe-7s-cart icon-gradient bg-happy-fisher" />
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<LoadingOverlay
						tag="div"
						active={this.state.formLoading}
						styles={{
							overlay: (base) => ({
								...base,
								background: "#fff",
								opacity: 0.5,
							}),
						}}
						spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
					>
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<Form
									onInvalid={(e) => {
										this.inValidateField(e);
									}}
									onSubmit={(e) => {
										e.preventDefault();
										if (e.nativeEvent.submitter.name === "receive") {
											this.receive(e.nativeEvent);
										} else if (e.nativeEvent.submitter.name === "reject") {
											this.getRejectionReason(e.nativeEvent);
										}
									}}
								>
									<TableWithPaginationSearch
										keyfield="id"
										search
										scrollable
										columns={columns}
										data={this.state.items}
										placeholder={t("searchField")}
										loading={this.state.items.length > 0 ? false : true}
									/>
								</Form>
							</CardBody>
						</Card>
					</LoadingOverlay>
				</Col>
			</Row>
		);
	}
}

export default withRouter(withTranslation()(Receiving));
