import React, { Component, Fragment } from "react";
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import axios from "axios";
import CustomSelect from "../../common/custom_select";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import { AlertNotification } from "../../common/alert_notification";
import getDateFormat from "../../helper/date";
import DeleteIcon from "../../common/delete_icon";
import { withTranslation } from "react-i18next";

class POForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			suppliers: [],
			isFormValid: true,
			paymentTerms: [],
			todayDate: "",
		};
		this.props.handleNextButton(true);
		this.props.makePOTypeDisabled();
	}

	componentDidMount() {
		this.setState({ todayDate: getDateFormat(new Date()) });
		axios
			.get(`supplier`)
			.then((res) => this.setState({ suppliers: res.data }))
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});

		axios
			.get(`paymentterm`)
			.then((res) => this.setState({ paymentTerms: res.data }))
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	}

	inputNumber = (cell, item, itemIndex, fieldName, isRequired) => (
		<FormGroup>
			<Input
				type="number"
				min={0.0}
				presicion={2}
				step={0.01}
				defaultValue={cell}
				name={fieldName}
				index={itemIndex}
				required={isRequired}
				invalid={item[`is${fieldName}InValid_${itemIndex}`]}
				onBlur={(event) => this.props.handleNumericInput(event, itemIndex, fieldName)}
			/>
			<FormFeedback>{item[`${fieldName}Feedback_${itemIndex}`]}</FormFeedback>
		</FormGroup>
	);

	inputQuantity = (cell, item, itemIndex) => (
		<FormGroup>
			<Input
				type="number"
				min={1.0}
				max={item["remainingQuantity"]}
				presicion={2}
				step={0.1}
				defaultValue={cell}
				name="quantity"
				index={itemIndex}
				required
				invalid={item[`isquantityInValid_${itemIndex}`]}
				onChange={(event) => this.props.handleNumericInput(event, itemIndex, "quantity")}
			/>
			<FormFeedback>{item[`quantityFeedback_${itemIndex}`]}</FormFeedback>
		</FormGroup>
	);

	// check if field is null set col to -
	nullChecker = (cell) => (!cell ? "-" : cell);

	render() {
		const { t } = this.props;

		const headerFormatter = (column, colIndex) => <span>{t(column.text)}</span>;

		const columns = [
			{
				text: "purchaseRequest",
				dataField: "purchaseRequest",
				headerFormatter: headerFormatter,
			},
			{
				text: "itemCode",
				dataField: "catalogItem.itemCode",
				headerFormatter: headerFormatter,
			},
			{
				text: "itemName",
				dataField: "catalogItem.name",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "condition",
				dataField: "condition.condition",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "remainingQuantity",
				dataField: "remainingQuantity",
				headerFormatter: headerFormatter,
				formatter: (cell, row) => <div>{Number(cell).toLocaleString() + row.uom.abbreviation}</div>,
			},
			{
				text: "poQuantity",
				dataField: "",
				headerFormatter: headerFormatter,
				formatter: (cell, item, rowIndex) => this.inputQuantity(cell, item, rowIndex),
			},
			{
				text: "unitPrice",
				dataField: "unitPrice",
				headerFormatter: headerFormatter,
				formatter: (cell, item, rowIndex) => this.inputNumber(cell, item, rowIndex, "unitPrice", true),
			},
			{
				text: "discount",
				dataField: "discount",
				headerFormatter: headerFormatter,
				formatter: (cell, item, rowIndex) => this.inputNumber(cell, item, rowIndex, "discount", false),
			},
			{
				text: "unitPriceAfterDiscount",
				dataField: "unitPriceAfterDiscount",
				classes: "text-danger",
				headerFormatter: headerFormatter,
			},
			{
				text: "totalCostBeforeTax",
				dataField: "totalCostBeforeTax",
				classes: "text-danger",
				headerFormatter: headerFormatter,
			},
			{
				text: "withHolding",
				dataField: "withHoldingTax",
				headerFormatter: headerFormatter,
				formatter: (cell, item, rowIndex) => this.inputNumber(cell, item, rowIndex, "withHoldingTax", false),
			},
			{
				text: "note",
				dataField: "note",
				headerFormatter: headerFormatter,
				formatter: (cell, item, rowIndex) => (
					<Input type="textarea" rows={1} defaultValue={item.note} onBlur={(event) => this.props.handleNote(event, rowIndex)} />
				),
			},
		];

		return (
			<Fragment>
				<Form onInvalid={this.props.onInvalidForm} onSubmit={this.props.onSubmit}>
					<Row className="mt-2">
						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label for="itemClass">{t("supplier")}</Label>
								<CustomSelect
									required
									inValid={!this.state.isFormValid}
									onChange={this.props.handleOnSelectSupplier}
									label="Supplier"
									options={this.state.suppliers}
									optionlable="name"
									defaultValue={null}
									name="Supplier"
									disabled={this.props.purchaseOrder.totalCostAfterTax}
								/>
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label>{t("tax")}</Label>
								<Input type="number" disabled={true} defaultValue={this.props.purchaseOrder.tax} />
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label>{t("deliveryFees")}</Label>
								<Input
									type="number"
									name="deliveryFees"
									min="0.0"
									step={0.01}
									onChange={this.props.handleInputChange}
									defaultValue={this.props.purchaseOrder.deliveryFees}
									invalid={this.props.inputValidation.isdeliveryFeesInValid}
								/>
								<FormFeedback>{this.props.inputValidation.deliveryFeesFeedback}</FormFeedback>
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label for="exampleCheckbox">{t("delivery")}</Label>
								<div onChange={this.props.handleInputChange}>
									<CustomInput
										type="radio"
										id="isDeliveredBySupplier"
										name="isDeliveredBySupplier"
										label={t("delivered")}
										value={true}
										required
										invalid={this.props.inputValidation.isisDeliveredBySupplierInValid}
									/>
									<CustomInput
										type="radio"
										id="isDeliveredBySupplier2"
										name="isDeliveredBySupplier"
										label={t("exWorks")}
										value={false}
										invalid={this.props.inputValidation.isisDeliveredBySupplierInValid}
									/>
								</div>
							</FormGroup>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label for="itemClass">{t("paymentTerm")}</Label>
								<CustomSelect
									required
									inValid={!this.state.isFormValid}
									onChange={this.props.handleOnSelectPaymentTerm}
									label="PaymentTerm"
									name="PaymentTerm"
									options={this.state.paymentTerms}
									optionlable="paymentTerm"
									defaultValue={this.props.paymentTerm}
								/>
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label>{t("offerNumber")}</Label>
								<Input
									type="text"
									name="offerNumber"
									onChange={this.props.handleInputChange}
									value={this.props.purchaseOrder.offerNumber}
								/>
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label>{t("offerDate")}</Label>
								<Input
									type="date"
									name="offerDate"
									max={this.state.todayDate}
									value={this.props.purchaseOrder.offerDate || ""}
									onChange={this.props.handleInputChange}
									required={this.props.purchaseOrder.offerNumber}
									disabled={!this.props.purchaseOrder.offerNumber}
									invalid={this.props.inputValidation.isofferDateInValid}
								/>
								<FormFeedback>{this.props.inputValidation.offerDateFeedback}</FormFeedback>
							</FormGroup>
						</Col>

						<Col xs="12" md="4" lg="3">
							<FormGroup>
								<Label>{t("totalCost")}</Label>
								<Input type="number" disabled={true} defaultValue={this.props.purchaseOrder.totalCostAfterTax || ""} />
							</FormGroup>
						</Col>
					</Row>

					{this.props.purchaseOrder.paymentTerm === 9 && (
						<Row>
							<Col>
								<Fragment>
									{this.props.installments.map((installment, index) => {
										let lastIndex = this.props.installments.length - 1;
										return (
											<Row key={index}>
												<Col md={4} lg={4} xl={4}>
													<FormGroup>
														<Label for="itemClass">{t("percentage")}</Label>
														<Input
															type="number"
															name="percentage"
															min={1}
															max={100}
															index={index}
															value={installment.percentage}
															disabled={index === lastIndex && this.props.installments.length > 1}
															onChange={this.props.handleInstallmentChange}
															invalid={this.props.inputValidation[`ispercentageInValid_${index}`]}
															required
														/>
														<FormFeedback>{this.props.inputValidation[`percentageFeedback_${index}`]}</FormFeedback>
													</FormGroup>
												</Col>
												<Col md={4} lg={4} xl={4}>
													<FormGroup>
														<Label>{t("days")}</Label>
														<Input
															type="number"
															index={index}
															name="days"
															onChange={this.props.handleInstallmentChange}
															value={installment.days}
															required
															invalid={this.props.inputValidation[`isdaysInValid_${index}`]}
														/>
														<FormFeedback>{this.props.inputValidation[`daysFeedback_${index}`]}</FormFeedback>
													</FormGroup>
												</Col>
												<Col md={3} lg={3} xl={3} hidden={index !== lastIndex}>
													<Button
														color={"info"}
														className="btn"
														style={{ marginTop: "31px" }}
														onClick={this.props.addNewInstallment}>
														{t("addButton")}
													</Button>
												</Col>
												<Col md={1} lg={1} xl={1}>
													<div style={{ marginTop: "34px" }}>
														<DeleteIcon
															index={index}
															hidden={index === lastIndex}
															iconsize={"1.3rem"}
															ondelete={this.props.removeInstallment}
														/>
													</div>
												</Col>
											</Row>
										);
									})}
								</Fragment>
							</Col>
						</Row>
					)}

					{this.props.purchaseOrder.supplier && (
						<Fragment>
							<Row>
								<Col>
									<TableWithPaginationSearch
										scrollable
										keyfield="id"
										sizePerpage={10}
										columns={columns}
										data={this.props.poItems}
										loading={!this.props.poItems}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button className="float-right" color="info" type="submit" disabled={this.props.disabledSubmit}>
										{t("submit")}
									</Button>
								</Col>
							</Row>
						</Fragment>
					)}
				</Form>
			</Fragment>
		);
	}
}

export default withTranslation()(POForm);
