import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const getUrgencyColor = (priority) => {
	if (priority === "Low") return "bg-dark";
	if (priority === "Medium") return "bg-grow-early";
	if (priority === "High") return "bg-danger";
};

const getStatusColor = (status) => {
	if (status === "Pending") return "bg-warning";
	if (status === "Approved") return "bg-info";
	if (status === "Rejected") return "bg-danger";
};

export const PurchaseRequestDetails = withTranslation()((props) => (
	<Fragment>
		<Row>
			<Col md="6" xl="6">
				<div className={`card mb-3 widget-content ${getUrgencyColor(props.purchaseRequest.urgency.priority)}`}>
					<div className="widget-content-wrapper text-white">
						<div className="widget-content-left">
							<div className="widget-heading">{props.t("urgency")}</div>
						</div>
						<div className="widget-content-right">
							<div className="widget-numbers text-white">{props.purchaseRequest.urgency.urgency}</div>
						</div>
					</div>
				</div>
			</Col>
			<Col md="6" xl="6">
				<div className={`card mb-3 widget-content ${getStatusColor(props.purchaseRequest.status)}`}>
					<div className="widget-content-wrapper text-white">
						<div className="widget-content-left">
							<div className="widget-heading">{props.t("status")}</div>
						</div>
						<div className="widget-content-right">
							<div className="widget-numbers text-white">{props.purchaseRequest.status}</div>
						</div>
					</div>
				</div>
			</Col>
		</Row>
		<Card className="main-card mb-3">
			<CardHeader className="card-header-tab">
				<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
					<i className="header-icon bi bi-layout-text-window-reverse mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
						{" "}
					</i>
					{props.t("purchaseRequestDetails")}
				</div>
			</CardHeader>
			<CardBody>
				<Row className="no-gutters">
					<Col sm="12" md="4" lg="4" className="pr-5">
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{props.t("code")}</div>
												<div className="widget-numbers text-info">{props.purchaseRequest.code}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{props.t("prType")}</div>
												<div className="widget-numbers text-info">
													{props.purchaseRequest.prType.name}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</Col>
					<Col sm="12" md="4" lg="4" className="pr-5">
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{props.t("requester")}</div>
												<div className="widget-numbers text-info">
													{props.purchaseRequest.requestUser.username}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{props.t("requestDepartment")}</div>
												<div className="widget-numbers text-info">
													{props.purchaseRequest.requestDepartment.name}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</Col>
					<Col sm="12" md="4" lg="4">
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{props.t("requestDate")}</div>
												<div className="widget-numbers text-info">
													{props.purchaseRequest.creationDate}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												{props.purchaseRequest.warehouse && (
													<Fragment>
														<div className="widget-heading">{props.t("warehouse")}</div>
														<div className="widget-numbers text-info">
															{props.purchaseRequest.warehouse &&
																props.purchaseRequest.warehouse.name}
														</div>
													</Fragment>
												)}
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	</Fragment>
));
