import Select from "react-select";
import { Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";

class CustomSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedValue: "",
		};
	}

	onSelect = (selectedValue, inputField) => {
		this.setState({ selectedValue });
		this.props.onChange(selectedValue, inputField);
	};

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<Select
					name={this.props.name}
					isMulti={this.props.isMulti}
					options={this.props.options}
					classNamePrefix="select"
					placeholder={t("selectTitle")}
					onChange={this.onSelect}
					isDisabled={this.props.disabled}
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					className={`basic-multi-select ${this.props.cssclass}`}
					value={this.state.selectedValue || this.props.defaultValue}
					getOptionValue={(option) => option.id}
					getOptionLabel={(option) => option[this.props.optionlable]}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary: "#aed3ee",
							neutral5: "#e9ecef",
							neutral40: "#495057",
							neutral20: this.props.inValid && !this.state.selectedValue ? "#DE350B" : "hsl(0, 0%, 80%)",
							neutral30: this.props.inValid && !this.state.selectedValue ? "#d22b07" : "hsl(0, 0%, 60%)",
						},
					})}
				/>
				<Input
					className=""
					tabIndex={-1}
					hidden={true}
					autoComplete="off"
					index={this.props.index}
					required={this.props.required}
					name={this.props.name}
					defaultValue={this.state.selectedValue || this.props.defaultValue}
				/>
			</Fragment>
		);
	}
}
export default withTranslation()(CustomSelect);
