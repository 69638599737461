import cx from "classnames";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import AppMain from "../Layout/AppMain";
import { withRouter } from "react-router-dom";
import { authAxios } from "axios_interceptors";
import ResizeDetector from "react-resize-detector";
import { setUserPermissions } from "reducers/ThemeOptions";
import { AlertNotification } from "purch/common/alert_notification";

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			closedSmallerSidebar: false,
		};
	}

	componentDidMount() {
		authAxios
			.get(`checkperm`)
			.then((res) => {
				if (res) this.props.dispatch(setUserPermissions(res.data.data));
			})
			.catch((err) => {
				AlertNotification("Can not get permission, please contact the IT team.", "error");
				console.error(err.response.data);
			});
	}

	render() {
		let {
			// colorScheme,
			enableFixedHeader,
			enableFixedSidebar,
			enableFixedFooter,
			enableClosedSidebar,
			closedSmallerSidebar,
			enableMobileMenu,
			enablePageTabsAlt,
		} = this.props;

		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<div
							className={cx(
								"app-container",
								{ "fixed-header": enableFixedHeader },
								{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
								{ "fixed-footer": enableFixedFooter },
								{ "closed-sidebar": enableClosedSidebar || width < 1250 },
								{ "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
								{ "sidebar-mobile-open": enableMobileMenu },
								{ "body-tabs-shadow-btn": enablePageTabsAlt }
							)}
						>
							<AppMain />
						</div>
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProp = (state) => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
