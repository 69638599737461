import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, CardFooter } from "reactstrap";

class ItemCard extends React.Component {
	render() {
		return (
			<Card color="dark" key={this.props.index} className="card-shadow-primary card-border text-white mb-3">
				<div className="dropdown-menu-header">
					<div className="dropdown-menu-header-inner bg-dark">
						<div className="menu-header-content">
							<div className="avatar-icon-wrapper mb-3 avatar-icon-xl">
								<div className="avatar-icon">
									<img
										src={this.props.itemImage || "item.jpg"}
										alt={this.props.itemname}
										onError={(e) => {
											e.target.src = "item.jpg";
										}}
									/>
								</div>
							</div>
							<div>
								<h5 className="menu-header-title">{this.props.itemname}</h5>
								<h6 className="menu-header-subtitle">{this.props.itemcode}</h6>
							</div>
						</div>
					</div>
				</div>
				<CardFooter className="text-center d-block">
					<Button
						className="btn-shadow-dark btn-wider"
						onClick={this.props.addfunction}
						index={this.props.index}
						color="info">
						{this.props.t("addButton")}
					</Button>
				</CardFooter>
			</Card>
		);
	}
}

export default withTranslation()(ItemCard);
