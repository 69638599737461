import Swal from "sweetalert2";

function ConfirmPopUP(props) {
	return Swal.fire({
		icon: "alert",
		text: props.text,
		title: props.title,
		buttonsStyling: false,
		showCancelButton: true,
		cancelButtonText: props.cancelbtntxt,
		confirmButtonText: props.confirmbtntxt,
		customClass: {
			icon: "icon-info",
			confirmButton: "btn btn-info",
			cancelButton: "btn btn-dark ml-3",
		},
	}).then((result) => {
		if (result.isConfirmed) {
			props.confirmedResult(result.value);
		}
	});
}
export default ConfirmPopUP;
