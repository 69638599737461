import { clearCookie } from "cookies";

export const redirectToAppLog = () => {
	const prodURL = process.env.REACT_APP_ACC_FE_URL;
	const localURL = "http://localhost:3000";
	// clear localStorage before reload
	localStorage.clear();
	clearCookie();
	// check if env is production so redirect to erp prod else redirect to localhost
	window.location.href = process.env.NODE_ENV === "production" ? prodURL : localURL;
};
