import axios from "axios";
import { getCookie } from "cookies";
import React, { Fragment } from "react";
import ViewIcon from "purch/common/view_icon";
import { withRouter } from "react-router-dom";
import { authAxios } from "axios_interceptors";
import { withTranslation } from "react-i18next";
import DeleteIcon from "purch/common/delete_icon";
import DeletePopup from "purch/common/delete_popup";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { getCurrentMonthDate } from "../../helper/date";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Row, Col, Card, CardBody, Button, Input, Label, FormGroup } from "reactstrap";

class ListPR extends React.Component {
	constructor(props) {
		super(props);
		this.prStatus = [
			{ id: 1, name: "All Status", status: "" },
			{ id: 2, name: "Pending", status: "Pending", color: "text-warning" },
			{ id: 3, name: "Approved", status: "Approved", color: "text-info" },
			{ id: 4, name: "Rejected", status: "Rejected", color: "text-danger" },
		];

		this.state = {
			prs: [],
			loading: true,
			prToDelete: "",
			userDepartments: "",
			dates: { startDate: "", endDate: "", todayDate: "" },
		};
	}

	getPurchaseRequest = (prStatus = "") => {
		let startDate = this.state.dates.startDate + " 00:00";
		let endDate = this.state.dates.endDate + " 23:59";
		let dateRange = [startDate, endDate];
		axios
			.get(
				`purchaseRequest/?creationDate__range=${dateRange}&serializer=list&status=${prStatus}&requestDepartment__id__in=${this.state.userDepartments}`
			)
			.then((res) => this.setState({ prs: res.data || [], loading: false }))
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	deletePurchaseRequestAPi = (prIndex) => {
		axios
			.delete(`purchaseRequest/${this.state.prToDelete}`)
			.then(() => {
				this.getPurchaseRequest();
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	handleSelectDate = (e) => {
		let newDate = this.state.dates;
		newDate[e.target.name] = e.target.value;
		this.setState({ dates: newDate });
	};

	onSelectFilter = (event) => {
		const filterIndex = event.target.getAttribute("index");
		this.getPurchaseRequest(this.prStatus[filterIndex].status);
	};

	componentDidMount() {
		authAxios
			.get(`user/${getCookie("userId")}/getUserDepartment/`)
			.then((res) => {
				let departments = "";

				if (res.data.departments.length === 0) return;

				res.data.departments.forEach((d) => {
					if (d.name !== "Purchasing" && d.name !== "Managers") departments += `,${d.id}`;
				});

				this.setState({ userDepartments: departments });
				const dates = getCurrentMonthDate();
				// Get purchase request after dates saved in state
				this.setState({ dates }, function() {
					this.getPurchaseRequest();
				});
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	}

	getStatusColor = (status) => this.prStatus.find((element) => element.status === status).color;

	actionButtons = (cell, item, rowIndex) => (
		<Fragment>
			<div className="d-flex justify-content-center">
				<div>
					<GetPermission perm="purch.view_purchaserequest">
						<div code="perm">
							<ViewIcon index={rowIndex} to={{ pathname: `/viewpr/?id=${item.id}` }} target="_blank" />
						</div>
					</GetPermission>
				</div>
				<GetPermission perm="purch.delete_purchaserequest">
					<div code="perm">
						<DeleteIcon
							index={rowIndex}
							hidden={item.poIssued}
							cssclass="ml-1"
							ondelete={() => {
								this.setState({ prToDelete: item.id });
								DeletePopup({
									title: "Are you sure ?",
									cancelbtntxt: "No",
									confirmbtntxt: "Yes",
									text: `This will delete ${item.code}`,
									confirmedresult: () => this.deletePurchaseRequestAPi(rowIndex),
								});
							}}
						/>
					</div>
				</GetPermission>
			</div>
		</Fragment>
	);
	// check if field is null set col to -
	nullChecker = (cell) => (!cell ? "-" : cell);

	render() {
		const { t } = this.props;
		const prStatus = [
			{ id: 1, name: t("allStatus"), status: "" },
			{ id: 2, name: t("pending"), status: "Pending", color: "text-warning" },
			{ id: 3, name: t("approved"), status: "Approved", color: "text-info" },
			{ id: 4, name: t("rejected"), status: "Rejected", color: "text-danger" },
		];
		const columns = [
			{
				text: "code",
				dataField: "code",
				headerFormatter: () => {
					return t("prNumber");
				},
			},
			{
				text: "date",
				dataField: "creationDate",
				headerFormatter: () => {
					return t("requestDate");
				},
			},
			{
				text: "status",
				dataField: "status",
				classes: (cell) => this.getStatusColor(cell),
				headerFormatter: () => {
					return t("status");
				},
			},
			{
				text: "requestUser",
				dataField: "requestUser",
				formatter: this.nullChecker,
				headerFormatter: () => {
					return t("requester");
				},
			},
			{
				text: "requestDepartment",
				dataField: "requestDepartment",
				formatter: this.nullChecker,
				headerFormatter: () => {
					return t("requestDepartment");
				},
			},
			{
				text: "prType",
				dataField: "prType",
				formatter: this.nullChecker,
				headerFormatter: () => {
					return t("prType");
				},
			},
			{
				text: "date",
				dataField: "lastApprovalDate",
				headerFormatter: () => {
					return t("approvalDate");
				},
			},
			{
				text: "actions",
				dataField: "actions",
				formatter: this.actionButtons,
				headerFormatter: () => {
					return t("actions");
				},
			},
		];
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("purchaseRequests")} icon="pe-7s-cart icon-gradient bg-happy-fisher" />
				<Row>
					<Col xs="12" sm="4" md="3" lg="3" xl="3">
						<FormGroup>
							<Label>{t("startDate")}</Label>
							<Input
								type="date"
								name="startDate"
								onChange={this.handleSelectDate}
								max={this.state.dates.endDate}
								value={this.state.dates.startDate}
							/>
						</FormGroup>
					</Col>
					<Col xs="12" sm="4" md="3" lg="3" xl="3">
						<FormGroup>
							<Label>{t("endDate")}</Label>
							<Input
								type="date"
								name="endDate"
								onChange={this.handleSelectDate}
								min={this.state.dates.startDate}
								max={this.state.dates.todayDate}
								value={this.state.dates.endDate}
							/>
						</FormGroup>
					</Col>
					<Col xs="12" sm="4" md="2" lg="2" xl="2">
						<GetPermission perm="purch.view_purchaserequest">
							<div code="perm">
								<Button color="info" style={{ marginTop: "30px" }} onClick={() => this.getPurchaseRequest()}>
									{t("submit")}
								</Button>
							</div>
						</GetPermission>
					</Col>
				</Row>

				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									search
									keyfield="id"
									columns={columns}
									data={this.state.prs}
									filtersOptions={prStatus}
									noDataMessage={t("noData")}
									loading={this.state.loading}
									placeholder={t("searchField")}
									filterTitle={prStatus[0].name}
									onSelectFilter={this.onSelectFilter}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withTranslation()(withRouter(ListPR));
