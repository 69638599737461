import moment from "moment";

const getDateFormat = (date) => {
    date = new Date(date);
    date = new moment(date);
    return date.format("YYYY-MM-DD");
}

export const getCurrentMonthDate = () => {
    let date = new Date();
    let startMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let startDate = getDateFormat(startMonthDate);
    let endDate = getDateFormat(date);
    let todayDate = getDateFormat(new Date());
    return { startDate: startDate, endDate: endDate, todayDate: todayDate };
};

export default getDateFormat;
