import React from "react";
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export const PurchaseOrderDetails = (props) => {
	const { t } = useTranslation();
	return (
		<Card className="main-card mb-3">
			<CardHeader className="card-header-tab">
				<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
					<i className="header-icon bi bi-layout-text-window-reverse mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
						{" "}
					</i>
					{t("purchaseOrdersDetails")}
				</div>
			</CardHeader>
			<CardBody>
				<Row className="no-gutters">
					<Col sm="12" md="6" lg="6" className="pr-5">
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("code")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.code}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("supplier")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.supplier.name}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>

							<ListGroupItem hidden={!props.podata.offerNumber}>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("offerNumber")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.offerNumber}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("costBeforeTax")}</div>
												<div className="widget-numbers-sm text-info">
													{Number(props.podata.totalCostBeforeTax).toLocaleString("en")}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("taxValue")}</div>
												<div className="widget-numbers-sm text-info">{Number(props.podata.taxValue).toLocaleString("en")}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("paymentTerm")}</div>
												<div className="widget-numbers-sm text-info">{props.paymentTerm}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem hidden={!props.podata.poType}>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("poType")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.poType}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem> </ListGroupItem>
						</ListGroup>
					</Col>
					<Col sm="12" md="6" lg="6" className="pr-5">
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("poData")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.creationDate}</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem className="pt-3">
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("poCreator")}</div>
												<div className="widget-numbers-sm text-info">{props.podata.issuingUser} </div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("delivery")}</div>
												<div className="widget-numbers-sm text-info">
													{props.podata.isDeliveredBySupplier === true ? "Delivered" : "EX-Works"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem hidden={!props.podata.offerDate}>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("offerDate")}</div>
												<div className="widget-numbers-sm text-info">
													{props.podata.offerDate ? props.podata.offerDate.slice(0, 10) : ""}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem hidden={!props.podata.deliveryFees}>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("deliveryFees")}</div>
												<div className="widget-numbers-sm text-info">
													{Number(props.podata.deliveryFees).toLocaleString("en")}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("withHoldingTaxValue")}</div>
												<div className="widget-numbers-sm text-info">
													{Number(props.podata.withHoldingTaxValue).toLocaleString("en")}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-outer">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{t("costAfterTax")}</div>
												<div className="widget-numbers-sm text-danger">
													{Number(props.podata.totalCostAfterTax).toLocaleString("en")}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListGroupItem>

							<ListGroupItem> </ListGroupItem>
						</ListGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
