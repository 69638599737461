import React from "react";
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, AreaChart, Area } from "recharts";

/* A generic component that takes data from a parent and displays it as a bar chart graph with many colors. Color for each column, there is one more step toward making this component 100% generic passing the axios url as prop instead of having it hardcoded */

class ChartArea extends React.Component {
	render() {
		return (
			<ResponsiveContainer width={this.props.width} aspect={this.props.aspect}>
				<AreaChart data={this.props.data}>
					<CartesianGrid />
					<XAxis dataKey={this.props.xAxisDataKey} />
					<YAxis />
					<Tooltip />
					<Area type="monotone" dataKey={this.props.areaDataKey} stroke="#17becf" fill="#17becf" />
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}

export default ChartArea;
