import React, { Component } from "react";
import ViewSupplier from "../components/view_supplier";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ViewSupplierScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<ViewSupplier />
			</CSSTransitionGroup>
		);
	}
}

export default ViewSupplierScene;
