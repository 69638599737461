import React from "react";
import { Button } from "reactstrap";
import GetPermission from "./get_permission";
import { withTranslation } from "react-i18next";

const getNavStates = (indx, length) => {
	let styles = [];
	for (let i = 0; i < length; i++) {
		if (i < indx) {
			styles.push("done");
		} else if (i === indx) {
			styles.push("doing");
		} else {
			styles.push("todo");
		}
	}
	return { current: indx, styles: styles };
};

const checkNavState = (currentStep, stepsLength) => {
	if (currentStep > 0 && currentStep < stepsLength - 1) {
		return {
			showPreviousBtn: true,
			showNextBtn: true,
		};
	} else if (currentStep === 0) {
		return {
			showPreviousBtn: false,
			showNextBtn: true,
		};
	} else {
		return {
			showPreviousBtn: true,
			showNextBtn: false,
		};
	}
};

class Wizard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPreviousBtn: false,
			showNextBtn: true,
			compState: 0,
			navState: getNavStates(0, this.props.steps.length),
		};
	}

	setNavState = (next) => {
		this.setState({
			navState: getNavStates(next, this.props.steps.length),
		});
		if (next < this.props.steps.length) {
			this.setState({ compState: next });
		}
		this.setState(checkNavState(next, this.props.steps.length));
	};

	handleOnClick = (evt) => {
		if (evt.currentTarget.value === this.props.steps.length - 1 && this.state.compState === this.props.steps.length - 1) {
			this.setNavState(this.props.steps.length);
		} else {
			this.setNavState(evt.currentTarget.value);
		}
	};

	next = () => {
		this.setNavState(this.state.compState + 1);
	};

	previous = () => {
		if (this.state.compState > 0) {
			this.setNavState(this.state.compState - 1);
		}
	};

	getClassName = (className, i) => {
		if (this.props.compState && i < this.props.compState) {
			return className + "-done";
		} else {
			return className + "-" + this.state.navState.styles[i];
		}
	};

	renderSteps = () => {
		return this.props.steps.map((s, i) => (
			<li className={this.getClassName("form-wizard-step", i)} onClick={this.props.stepClickable ? this.handleOnClick : null} key={i} value={i}>
				<em>{i + 1}</em>
				<span>{this.props.steps[i].name}</span>
			</li>
		));
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.compState !== this.props.compState) {
			this.setState(checkNavState(this.props.compState, this.props.steps.length));
		}
	}

	render() {
		const { t } = this.props;
		return (
			<div>
				<ol className="forms-wizard">{this.renderSteps()}</ol>
				{this.props.steps[this.props.compState || this.state.compState].component}
				<div className="divider" />
				<div className="clearfix">
					<div style={this.props.showNavigation ? {} : { display: "none" }}>
						<Button
							color="secondary"
							className="btn-shadow float-left btn-wide btn-pill"
							outline
							style={this.state.showPreviousBtn && this.props.showPrevious ? {} : { display: "none" }}
							onClick={this.previous}
						>
							Previous
						</Button>
						<GetPermission perm={this.props.perm}>
							<Button
								code="perm"
								color="dark"
								className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
								style={this.state.showNextBtn ? {} : { display: "none" }}
								disabled={this.props.disableNext}
								onClick={this.next}
							>
								{t("next")}
							</Button>
						</GetPermission>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Wizard);

Wizard.defaultProps = {
	showNavigation: true,
};
