export const setCookie = (name, value, expires) => {
	// The following line set cookie with expiry date if there is expires date ( UTC local String format )
	// otherwise it will set the cookie as session cookie expire after closing the browser
	let expiryDate = new Date(expires);
	if (expires) {
		expiryDate.toUTCString();
	}
	document.cookie = `${name}=${value}${expires ? `; expires=${expiryDate}; path=/` : ``}`;
};

export const getCookie = (name) => {
	let cookies = document.cookie.split(";");
	for (const cookie of cookies) {
		let cookieArr = cookie.split("=");
		if (cookieArr[0].trim() === name) {
			return cookieArr[1];
		}
	}
	return undefined;
};

export const removeCookie = (name) => {
	document.cookie = `${name}=; expires=${new Date(1990, 1, 1).toUTCString()}`;
};

export const clearCookie = () => {
	let cookies = document.cookie.split(";");
	cookies.forEach((cookie) => {
		let cookieArr = cookie.split("=");
		document.cookie = `${cookieArr[0].trim()}=; expires=${new Date(1990, 1, 1).toUTCString()}`;
	});
};
