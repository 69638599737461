import cx from "classnames";
import HeaderLogo from "../AppLogo";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { TimerBeep } from "purch/common/sound";
import { withTranslation } from "react-i18next";
import LanguageNav from "./Components/LanguageNav";
import { redirectToAppLog } from "../../redirect_to_applog";
import { calculateTokenTTL } from "../../purch/helper/helper";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			mainOffset: window.pageYOffset,
			visible: true,
		};
	}

	// Adds an event listener when the component is mount.
	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	// Remove the event listener when the component is unmount.
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	// Hide or show the menu.
	handleScroll = () => {
		const { mainOffset } = this.state;
		const currentOffset = window.pageYOffset;

		// if window is not desktop remove header when scroll down and show it when scroll up
		if (window.innerWidth <= 760) {
			const visible = mainOffset > currentOffset;
			this.setState({
				mainOffset: currentOffset,
				visible,
			});
		}
	};

	render() {
		const { t } = this.props;
		let ttl = calculateTokenTTL();
		let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = this.props;
		return (
			<Fragment>
				<CSSTransitionGroup
					component="div"
					className={cx("app-header", headerBackgroundColor, { "header-shadow": enableHeaderShadow })}
					transitionName="HeaderAnimation"
					transitionAppear={true}
					transitionAppearTimeout={1500}
					transitionEnter={false}
					transitionLeave={false}
					hidden={!this.state.visible}>
					<HeaderLogo />
					<div className={cx("app-header__content", { "header-mobile-open": enableMobileMenuSmall })}>
						<h4 style={{ color: "white" }}>{t("purchasing")}</h4>
					</div>
					<div className="mr-2">
						<CountdownCircleTimer
							isPlaying
							size={35}
							duration={ttl}
							strokeWidth={3}
							updateInterval={60}
							trailColor="#dc3545"
							colorsTime={[ttl, 10800, 3600, 0]}
							colors={["#28a745", "#ffc107", "#dc3545", "#dc3545"]}>
							{({ remainingTime }) => (
								<div role="timer" aria-live="assertive" className="text-light">
									{Math.floor(remainingTime / 3600)}
								</div>
							)}
						</CountdownCircleTimer>
					</div>
					<div className="mr-2">
						<CountdownCircleTimer
							isPlaying
							size={35}
							duration={ttl}
							strokeWidth={3}
							updateInterval={1}
							trailColor="#dc3545"
							colorsTime={[ttl, 900, 300, 0]}
							onUpdate={(remaining) => {
								if (remaining === 360) {
									TimerBeep();
								}
							}}
							colors={["#28a745", "#ffc107", "#dc3545", "#dc3545"]}>
							{({ remainingTime }) => (
								<div role="timer" aria-live="assertive" className="text-light">
									{Math.floor((remainingTime % 3600) / 60)}
								</div>
							)}
						</CountdownCircleTimer>
					</div>
					<LanguageNav />
					<h4 className="text-light">
						<i color="light" className="mr-2 mt-2 pe-7s-keypad link" onClick={redirectToAppLog}></i>
					</h4>
				</CSSTransitionGroup>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
