import axios from "axios";
import AddItem from "./add_items";
import Approval from "./approvals";
import { getCookie } from "cookies";
import { Loader } from "react-loaders";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchItem from "purch/common/search_item";
import UpdateIcon from "purch/common/update_icon";
import DeleteIcon from "purch/common/delete_icon";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import { inventoryAxios } from "axios_interceptors";
import ReplaceIcon from "purch/common/replace_icon";
import CustomSelect from "purch/common/custom_select";
import { CancelIcon } from "purch/common/cancel_icon";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { PurchaseRequestDetails } from "./purchase_request_details";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Card, CardBody, CardHeader, Col, Row, Input, CardFooter, Button, Form } from "reactstrap";

class ViewPurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uoms: {},
      items: [],
      poItems: {},
      prItems: [],
      itemLists: [],
      editable: false,
      itemConditions: [],
      hideItemList: true,
      userDepartments: [],
      formLoading: false,
      enableCreatePO: false,
      purchaseRequest: null,
      approvalLoading: false,
      isPurchDepartment: false,
      isRequestDepartment: false,
    };
  }

  getSearchResult = (result) => {
    this.setState({ items: result.data });
  };

  getPRItemValues = (e) => {
    let selected = [...this.state.prItems];
    let itemObj = { ...selected[e.target.getAttribute("index")] };
    itemObj[e.target.name] = e.target.value;
    itemObj.inValid = "";
    selected[e.target.getAttribute("index")] = itemObj;
    this.setState({ prItems: selected });
  };

  getPRItemSelectedValues = (data, field, index) => {
    let prItems = [...this.state.prItems];
    prItems[index][field.name] = data;
    this.setState({ prItems: prItems });
  };

  validateUpdatedItems = (e) => {
    e.preventDefault();
    let prItems = [...this.state.prItems];
    let updatedItem = { ...prItems[e.target.getAttribute("index")] };
    updatedItem.inValid = "is-invalid";
    prItems[e.target.getAttribute("index")] = updatedItem;
    this.setState({ prItems: prItems });
  };

  updatePRItems = (e) => {
    e.preventDefault();
    this.setState({ formLoading: true });
    axios
      .put(`purchaseRequestItem/bulkupdate/`, this.state.prItems)
      .then((res) => {
        if (res) AlertNotification("Updated Successfully", "success");
        this.setState({ formLoading: false });
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  deletePRItem = (e) => {
    this.setState({ formLoading: true });
    if (!this.state.editable && this.state.purchaseRequest.status !== "Pending") return;
    let arr = this.state.prItems.map((item) => {
        return item;
      }),
      index = e.target.getAttribute("index");
    axios
      .delete(`purchaseRequestItem/${arr[index].id}/`)
      .then((res) => {
        if (res) {
          arr.splice(index, 1);
          this.setState({ prItems: arr });
        }
        this.setState({ formLoading: false });
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  setApprovalAndPR = (index, approved, status) => {
    // If all approvals are approved or one approval is rejected fire this method.
    let pr = this.state.purchaseRequest;
    let approvals = pr.prApproval;
    this.setState({ approvalLoading: true });
    axios
      .patch(`prApproval/${approvals[index].id}/`, { approved: approved })
      .then((res) => {
        if (res) {
          approvals[index] = res.data;
          pr.status = status;
          this.setState({ purchaseRequest: pr, editable: false, approvalLoading: false });
        }
      })
      .catch((err) => {
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  setApproval = (index) => {
    // If any approval is approved except the last one fire this method.
    let pr = this.state.purchaseRequest;
    let approvals = pr.prApproval;
    this.setState({ approvalLoading: true });
    axios
      .patch(`prApproval/${approvals[index].id}/`, { approved: true })
      .then((res) => {
        if (res) {
          approvals[index] = res.data;
          this.setState({ purchaseRequest: pr, approvalLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ approvalLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  setApprovals = (evt) => {
    let index = evt.target.getAttribute("index");
    let approved = evt.target.getAttribute("approved");
    if (!approved) {
      this.setApprovalAndPR(index, false, "Rejected");
    } else if (approved && Number(index) + 1 === this.state.purchaseRequest.prApproval.length) {
      this.setApprovalAndPR(index, true, "Approved");
    } else this.setApproval(index);
  };

  getPurchaseRequest = (prID) => {
    axios
      .get(`purchaseRequest/${prID}/?serializer=get`)
      .then((res) => {
        res.data.prApproval.sort(function(a, b) {
          return a.sortOrder - b.sortOrder;
        });
        let editable = false;
        let allApprovalUsers = [];
        res.data.prApproval.forEach((approval) => {
          allApprovalUsers.push(...approval.prApprovalEntity.approvalUser);
        });
        if (allApprovalUsers.includes(Number(getCookie("userId")))) editable = true;
        this.setState(
          {
            items: [],
            editable: editable,
            hideItemList: true,
            prItems: res.data.prItem,
            purchaseRequest: res.data,
          },
          () => {
            if (
              this.state.userDepartments.filter(
                (department) => department.name === this.state.purchaseRequest.requestDepartment.name
              ).length !== 0
            ) {
              this.setState({
                isRequestDepartment: true,
              });
            }
            let enableCreatePO = false;
            this.state.prItems.forEach((item) => {
              if (!item.poNumber || item.remainingQuantity !== 0) {
                enableCreatePO = true;
                return;
              }
            });
            this.setState({ enableCreatePO: enableCreatePO });
          }
        );
      })
      .catch((err) => {
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  getItems = (e) => {
    inventoryAxios.get(`itemlist/${e.id}/?serializer=pr`).then((res) => {
      this.setState({ items: res.data.items });
    });
  };

  getReplacementItems = (listID, index) => {
    inventoryAxios.get(`itemlist/${listID}/?serializer=pr`).then((res) => {
      let items = this.state.prItems.map((i) => {
        return i;
      });
      let item = JSON.parse(JSON.stringify(items[index]));
      item.replacements = res.data.items;
      items[index] = item;
      this.setState({ prItems: items });
    });
  };

  confirmReplacement = (index) => {
    this.setState({ formLoading: true });
    let items = this.state.prItems.map((i) => {
      return i;
    });
    let item = JSON.parse(JSON.stringify(items[index]));
    axios
      .patch(`purchaseRequestItem/${item.id}/?serializer=update`, {
        catalogItem: item.suggestedReplacement.id,
        suggestedReplacement: null,
      })
      .then((res) => {
        if (res) {
          item.catalogItem = item.suggestedReplacement;
          item.suggestedReplacement = null;
          items[index] = item;
          this.setState({ prItems: items });
          AlertNotification("Item replacement confirmed", "success");
        }
        this.setState({ formLoading: false });
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  confirmQuantityUpdate = (rowIndex, item) => {
    this.setState({ formLoading: true });
    axios
      .patch(`purchaseRequestItem/${item.id}/?serializer=update`, {
        originalQTY: item.quantity,
      })
      .then((res) => {
        if (res) {
          let items = [...this.state.prItems];
          items[rowIndex]["originalQTY"] = item.quantity;
          this.setState({ prItems: [] }, () => {
            this.setState({ prItems: items });
          });
          AlertNotification("Item Quantity update confirmed", "success");
        }
        this.setState({ formLoading: false });
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  cancelQuantityUpdate = (rowIndex, item) => {
    this.setState({ formLoading: true });
    axios
      .patch(`purchaseRequestItem/${item.id}/?serializer=update`, {
        quantity: item.originalQTY,
      })
      .then((res) => {
        if (res) {
          let items = [...this.state.prItems];
          items[rowIndex]["quantity"] = item.originalQTY;
          this.setState({ prItems: items });
          AlertNotification("Item Quantity update rejected", "success");
        }
        this.setState({ formLoading: false });
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  getInitialData = () => {
    axios.get(`profile/?user=${getCookie("userId")}`).then((res) => {
      if (res.data[0]["departments"].filter((department) => department.name === "Purchasing").length !== 0) {
        this.setState({
          isPurchDepartment: true,
          userDepartments: res.data[0]["departments"],
        });
      }
    });

    axios.get(`prItemCondition`).then((res) => {
      this.setState({ itemConditions: res.data });
    });
    inventoryAxios.get(`uom/UoMsByDimension`).then((res) => {
      this.setState({ uoms: res.data });
    });
    inventoryAxios.get(`itemlist/?serializer=id`).then((res) => {
      this.setState({ itemLists: res.data });
    });
  };

  createPO = (e) => {
    this.props.history.push({
      pathname: "/newpo",
      poItems: Object.values(this.state.poItems),
      prType: this.state.purchaseRequest.prType,
    });
  };

  componentDidMount() {
    this.getInitialData();
    let prID = this.props.prID;
    if (!prID) prID = new URLSearchParams(this.props.location.search).get("id");
    this.getPurchaseRequest(prID);
  }

  selectPOItem = (e, prItem) => {
    let poItems = { ...this.state.poItems };
    if (e.target.checked) {
      prItem["purchaseRequestItem"] = prItem.id;
      prItem["purchaseRequest"] = this.state.purchaseRequest.code;
      prItem["purchaseRequestId"] = this.state.purchaseRequest.id;
      prItem["note"] = "";
      prItem["withHoldingTax"] = null;
      prItem["discount"] = 0;
      prItem["unitPriceAfterDiscount"] = 0;
      prItem["totalCostBeforeTax"] = 0;
      prItem["totalCostAfterTax"] = 0;
      poItems[prItem.id] = prItem;
    } else {
      delete poItems[prItem.id];
    }
    this.setState({ poItems: poItems });
  };

  render() {
    const { t } = this.props;
    const headerFormatter = (column) => <div style={{ width: "150px", minWidth: "fit-content" }}>{column.text}</div>;
    const itemsColumns = [
      {
        sort: true,
        align: "center",
        hidden:
          (this.state.purchaseRequest && this.state.purchaseRequest.status !== "Approved") ||
          !this.state.enableCreatePO,
        headerAlign: "center",
        dataField: "selects",
        text: t("select"),
        formatter: (cell, prItem, index) => (
          <Input
            style={{ position: "relative" }}
            type="checkbox"
            disabled={(prItem.poNumber && prItem["remainingQuantity"] === 0) || prItem.originalQTY !== prItem.quantity}
            onChange={(e) => this.selectPOItem(e, prItem)}
          />
        ),
      },
      {
        dataField: "catalogItem.itemCode",
        text: t("itemCode"),
        headerFormatter: headerFormatter,
      },
      {
        dataField: "catalogItem.name",
        text: t("itemName"),
        headerFormatter: headerFormatter,
      },
      {
        dataField: "poNumber",
        text: t("purchaseOrder"),
        headerFormatter: headerFormatter,
      },
      {
        text: t("replacement"),
        dataField: "suggestedReplacement",
        headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) => `${cell ? cell.name : ""}`,
        formatter: (cell, row, rowIndex) => {
          return (
            <CustomSelect
              name="suggestedReplacement"
              defaultValue={cell}
              optionlable="name"
              options={this.state.prItems[rowIndex].replacements || []}
              onChange={(event, field) => {
                this.getPRItemSelectedValues(event, field, rowIndex);
              }}
              disabled={
                row.poNumber
                  ? true
                  : !(
                      this.state.editable ||
                      this.state.isPurchDepartment ||
                      (this.state.purchaseRequest.status === "Pending" &&
                        this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")))
                    )
              }
            />
          );
        },
      },
      {
        dataField: "quantity",
        text: t("quantity"),
        headerFormatter: headerFormatter,
        style: { width: "350px", height: "fit-content" },
        formatter: (cell, row, rowIndex) => {
          return (
            <div className="d-flex">
              <Input
                min={1}
                required
                type="number"
                name="quantity"
                index={rowIndex}
                defaultValue={cell}
                onBlur={this.getPRItemValues}
                className={`text-center mx-1 ${this.state.prItems[rowIndex].inValid}`}
                disabled={
                  row.poNumber
                    ? true
                    : !(
                        this.state.editable ||
                        this.state.isPurchDepartment ||
                        (this.state.purchaseRequest.status === "Pending" &&
                          this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")))
                      )
                }
              />
              {row.originalQTY !== row.quantity &&
                ((this.state.purchaseRequest.status === "Pending" &&
                  this.state.purchaseRequest.requestUser.id === Number(getCookie("userId"))) ||
                  this.state.isRequestDepartment ||
                  this.state.editable) && (
                  <GetPermission perm="purch.change_purchaserequestitem">
                    <div code="perm" className="d-flex justify-content-between py-2" style={{ height: "fit-content" }}>
                      <CancelIcon
                        onHovercolor="danger"
                        iconClassName="mx-1"
                        noIconWrapper
                        hide={row.originalQTY === row.quantity}
                        onCancel={() => this.cancelQuantityUpdate(rowIndex, row)}
                      />
                      <UpdateIcon
                        cssclass="mx-1"
                        hidden={row.originalQTY === row.quantity}
                        onupdate={() => {
                          this.confirmQuantityUpdate(rowIndex, row);
                        }}
                      />
                    </div>
                  </GetPermission>
                )}
            </div>
          );
        },
      },
      {
        text: t("uom"),
        dataField: "uom",
        headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) => `${cell.fullName}`,
        formatter: (cell, row, rowIndex) => {
          return (
            <CustomSelect
              name="uom"
              defaultValue={cell}
              optionlable="fullName"
              options={this.state.uoms[row.uom.dimension]}
              onChange={(e, r) => {
                this.getPRItemSelectedValues(e, r, rowIndex);
              }}
              disabled={
                row.poNumber
                  ? true
                  : !(
                      this.state.editable ||
                      this.state.isPurchDepartment ||
                      (this.state.purchaseRequest.status === "Pending" &&
                        this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")))
                    )
              }
            />
          );
        },
      },
      {
        text: t("condition"),
        dataField: "condition",
        headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) => `${cell.condition}`,
        formatter: (cell, row, rowIndex) => {
          return (
            <CustomSelect
              name="condition"
              defaultValue={cell}
              optionlable="condition"
              options={this.state.itemConditions}
              onChange={(e, r) => {
                this.getPRItemSelectedValues(e, r, rowIndex);
              }}
              disabled={
                row.poNumber
                  ? true
                  : !(
                      this.state.editable ||
                      this.state.isPurchDepartment ||
                      (this.state.purchaseRequest.status === "Pending" &&
                        this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")))
                    )
              }
            />
          );
        },
      },
      {
        dataField: "note",
        text: t("note"),
        headerFormatter: headerFormatter,
        style: { width: "180px" },
        formatter: (cell, row, rowIndex) => {
          return (
            <Input
              name="note"
              type="textarea"
              rows={1}
              index={rowIndex}
              defaultValue={cell ? cell : ""}
              onBlur={this.getPRItemValues}
              disabled={
                row.poNumber
                  ? true
                  : !(
                      this.state.editable ||
                      this.state.isPurchDepartment ||
                      (this.state.purchaseRequest.status === "Pending" &&
                        this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")))
                    )
              }
            />
          );
        },
      },
      {
        dataField: "action",
        text: t("actions"),
        headerFormatter: headerFormatter,
        formatter: (cell, row, rowIndex) => {
          return (
            <div className="d-flex justify-content-center">
              <GetPermission perm="purch.change_purchaserequestitem">
                <div code="perm">
                  <ReplaceIcon
                    iconsize="1.1rem"
                    cssclass="mr-2"
                    onclick={() => {
                      this.getReplacementItems(row.catalogItem.group, rowIndex);
                    }}
                  />
                </div>
              </GetPermission>
              <div>
                <UpdateIcon
                  hidden={
                    !(
                      row.suggestedReplacement &&
                      ((this.state.purchaseRequest.status === "Pending" &&
                        this.state.purchaseRequest.requestUser.id === Number(getCookie("userId"))) ||
                        this.state.isRequestDepartment ||
                        this.state.editable)
                    )
                  }
                  onupdate={() => {
                    this.confirmReplacement(rowIndex);
                  }}
                  cssclass="mr-2"
                />
              </div>
              <GetPermission perm="purch.delete_purchaserequestitem">
                <div code="perm">
                  <DeleteIcon
                    index={rowIndex}
                    ondelete={this.deletePRItem}
                    hidden={
                      row.poNumber
                        ? true
                        : !(
                            this.state.editable ||
                            (this.state.purchaseRequest.status === "Pending" &&
                              (this.state.isPurchDepartment ||
                                this.state.purchaseRequest.requestUser.id === Number(getCookie("userId"))))
                          )
                    }
                  />
                </div>
              </GetPermission>
            </div>
          );
        },
      },
    ];

    return (
      <Fragment>
        <PageTitleAlt2 heading={t("purchaseRequest")} icon="pe-7s-cart icon-gradient bg-happy-fisher" />
        {this.state.purchaseRequest && (
          <Fragment>
            <Row>
              <Col>
                <PurchaseRequestDetails purchaseRequest={this.state.purchaseRequest} />
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Card className="main-card mb-3">
                  <div className="grid-menu grid-menu-2col">
                    <Row className="no-gutters">
                      <Col sm="6">
                        <div className="widget-chart widget-chart-hover">
                          <div className="widget-subheading mb-2">{t("totalNumberOfItems")}</div>
                          <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-dark" />
                            <i className="bi bi-cart text-dark" />
                          </div>
                          <div className="widget-numbers">{this.state.purchaseRequest.prItemsCount.allItems}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="widget-chart widget-chart-hover">
                          <div className="widget-subheading mb-2">{t("totalNumberOfPendingItems")}</div>
                          <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-warning" />
                            <i className="bi bi-cart-dash text-warning" />
                          </div>
                          <div className="widget-numbers">{this.state.purchaseRequest.prItemsCount.pendingItems}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="widget-chart widget-chart-hover br-br">
                          <div className="widget-subheading mb-2">{t("totalNumberOfPoInitiatedItems")}</div>
                          <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-info" />
                            <i className="bi bi-cart-plus text-info" />
                          </div>
                          <div className="widget-numbers">
                            {this.state.purchaseRequest.prItemsCount.poInitiatedItems}
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="widget-chart widget-chart-hover">
                          <div className="widget-subheading mb-2">{t("totalNumberOfReceivedItems")}</div>
                          <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-success" />
                            <i className="bi bi-cart-check text-success" />
                          </div>
                          <div className="widget-numbers">{this.state.purchaseRequest.prItemsCount.receivedItems}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Approval
                  setapprovals={this.setApprovals}
                  approvalLoading={this.state.approvalLoading}
                  prApprovals={this.state.purchaseRequest.prApproval}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardHeader className="card-header-tab">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i className="header-icon bi bi-layers mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
                        {" "}
                      </i>
                      {t("items")}
                    </div>
                  </CardHeader>
                  <LoadingOverlay
                    tag="div"
                    active={this.state.formLoading}
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                      }),
                    }}
                    spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
                  >
                    <Form onInvalid={this.validateUpdatedItems} onSubmit={this.updatePRItems}>
                      <CardBody>
                        <TableWithPaginationSearch
                          scrollable
                          search
                          placeholder={t("searchField")}
                          data={this.state.prItems}
                          columns={itemsColumns}
                          rowStyle={{ height: "35px", maxHeight: "fit-content" }}
                        />
                      </CardBody>
                      <CardFooter className="d-flex justify-content-end">
                        {this.state.purchaseRequest.status !== "Pending" && this.state.enableCreatePO && (
                          <GetPermission perm="purch.add_purchaseorder">
                            <Button
                              code="perm"
                              color="dark"
                              className="mr-2"
                              disabled={Object.values(this.state.poItems).length === 0}
                              onClick={this.createPO}
                            >
                              {t("createPO")}
                            </Button>
                          </GetPermission>
                        )}
                        <GetPermission perm="purch.change_purchaserequest">
                          <Button
                            code="perm"
                            color="info"
                            className="mr-2"
                            onClick={() => {
                              this.setState({ hideItemList: false });
                            }}
                            disabled={!this.state.editable || this.state.purchaseRequest.status !== "Pending"}
                          >
                            {t("addItem")}
                          </Button>
                          <Button
                            code="perm"
                            color="info"
                            type="submit"
                            disabled={
                              !(
                                this.state.editable ||
                                this.state.isPurchDepartment ||
                                (this.state.purchaseRequest.status === "Pending" &&
                                  (this.state.purchaseRequest.requestUser.id === Number(getCookie("userId")) ||
                                    this.state.isRequestDepartment))
                              )
                            }
                          >
                            {t("submit")}
                          </Button>
                        </GetPermission>
                      </CardFooter>
                    </Form>
                  </LoadingOverlay>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2" hidden={this.state.hideItemList}>
              <Col md="6">
                <SearchItem callback={this.getSearchResult} />
              </Col>
              <Col md="6">
                <CustomSelect
                  cssclass="mr-2"
                  optionlable="name"
                  onChange={this.getItems}
                  options={this.state.itemLists}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12">
                <AddItem pr={this.props.prID} items={this.state.items} refresh={this.getPurchaseRequest} />
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(ViewPurchaseRequest));
