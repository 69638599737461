import cx from "classnames";
import {connect} from "react-redux";
import Nav from "../AppNav/VerticalNavWrapper";
import React, {Component, Fragment} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {setEnableMobileMenu} from "../../reducers/ThemeOptions";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class AppSidebar extends Component {
	state = {};

	toggleMobileSidebar = () => {
		let {enableMobileMenu, setEnableMobileMenu} = this.props;
		setEnableMobileMenu(!enableMobileMenu);
	};

	render() {
		let {backgroundColor, enableBackgroundImage, enableSidebarShadow, backgroundImage, backgroundImageOpacity} = this.props;

		return (
			<Fragment>
				<div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
				<CSSTransitionGroup
					component="div"
					className={cx("app-sidebar", backgroundColor, {"sidebar-shadow": enableSidebarShadow})}
					transitionName="SidebarAnimation"
					transitionAppear={true}
					transitionAppearTimeout={1500}
					transitionEnter={false}
					transitionLeave={false}>
					<PerfectScrollbar>
						<div className="app-sidebar__inner">
							<Nav />
						</div>
					</PerfectScrollbar>
					<div
						className={cx("app-sidebar-bg", backgroundImageOpacity)}
						style={{
							backgroundImage: enableBackgroundImage ? "url(" + backgroundImage + ")" : null,
						}}></div>
				</CSSTransitionGroup>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
	enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	backgroundColor: state.ThemeOptions.backgroundColor,
	backgroundImage: state.ThemeOptions.backgroundImage,
	backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
