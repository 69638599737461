import axios from "axios";
import React from "react";
import { ResponsiveContainer } from "recharts";
import { withTranslation } from "react-i18next";
import ChartArea from "purch/common/area_chart";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class PRGraph extends React.Component {
	constructor(props) {
		super(props);
		this.statusColor = [
			{ id: 2, status: "Pending", backgroundColor: "#ffc107", hoverBackgroundColor: "#fd7e14" },
			{ id: 3, status: "Approved", backgroundColor: "#82ca9d", hoverBackgroundColor: "#52ad8d" },
		];
		this.state = {
			dataLoaded: false,
			statusData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						hoverBackgroundColor: [],
					},
				],
			},
			departmentData: {
				labels: [],
				datasets: [
					{
						data: [7, 12],
						borderWidth: 1,
						borderCapStyle: "round",
						label: "PRs",
						borderColor: "rgb(130, 202, 157)",
						backgroundColor: "rgb(130, 202, 157)",
						hoverBorderColor: "rgb(82, 173, 141)",
						hoverBackgroundColor: "rgb(82, 173, 141)",
					},
				],
			},
			prTypeData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: ["#82ca9d", "#6cc3d5"],
						hoverBackgroundColor: ["#52ad8d", "#17A2B8FF"],
					},
				],
			},
			urgency: [],
		};
	}

	componentDidMount() {
		axios
			.get(`purchaseRequest/prstats`)
			.then((res) => {
				let statusData = { ...this.state.statusData };
				let prTypeData = { ...this.state.prTypeData };
				let departmentData = { ...this.state.departmentData };
				res.data.status.forEach((status) => {
					statusData.datasets[0].data.push(status.count);
					statusData.datasets[0].backgroundColor.push(this.getStatusColor(status.status, "backgroundColor"));
					statusData.datasets[0].hoverBackgroundColor.push(this.getStatusColor(status.status, "hoverBackgroundColor"));
					statusData.labels.push(status.status);
				});
				res.data.department.forEach((department) => {
					departmentData.datasets[0].data.push(department.count);
					departmentData.labels.push(department.requestDepartment__name);
				});
				res.data.prType.forEach((type) => {
					prTypeData.datasets[0].data.push(type.count);
					prTypeData.labels.push(type.prType__name);
				});
				this.setState({
					dataLoaded: true,
					statusData: statusData,
					prTypeData: prTypeData,
					departmentData: departmentData,
					urgency: this.state.urgency.concat(res.data.urgency),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getStatusColor = (status, attributeName) => this.statusColor.find((element) => element.status === status)[attributeName];

	render() {
		const { t } = this.props;
		return (
			this.state.dataLoaded && (
				<Row className="d-flex justify-content-between">
					<Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-5">
						<Card>
							<CardBody>
								<CardTitle className="text-capitalize text-dark">{t("prsByDepartments")}</CardTitle>
								<ResponsiveContainer width="100%" aspect={2.5}>
									<Bar data={this.state.departmentData} options={{ cutoutPercentage: 100 }} />
								</ResponsiveContainer>
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-5">
						<Card>
							<CardBody>
								<CardTitle className="text-capitalize text-dark">{t("prsByUrgency")}</CardTitle>
								<ResponsiveContainer width="100%" aspect={2.5}>
									<ChartArea
										width="100%"
										aspect={2.5}
										areaDataKey="count"
										data={this.state.urgency}
										xAxisDataKey="urgency__urgency"
									/>
								</ResponsiveContainer>
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-1">
						<Card>
							<CardBody>
								<CardTitle className="text-capitalize text-dark">{t("prsByStatus")}</CardTitle>
								<ResponsiveContainer width="100%" aspect={2.5}>
									<Doughnut data={this.state.statusData} />
								</ResponsiveContainer>
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-1">
						<Card>
							<CardBody>
								<CardTitle className="text-capitalize text-dark">{t("prsByType")}</CardTitle>
								<ResponsiveContainer width="100%" aspect={2.5}>
									<Pie data={this.state.prTypeData} />
								</ResponsiveContainer>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)
		);
	}
}
export default withTranslation()(PRGraph);
