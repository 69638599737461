import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ViewIcon from "purch/common/view_icon";
import DeleteIcon from "purch/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import DeletePopup from "purch/common/delete_popup";
import { Card, CardBody, Col, Row } from "reactstrap";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "purch/common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";

class ListSupplier extends Component {
	constructor() {
		super();
		this.state = { loading: false, suppliers: [] };
	}

	getSuppliers = () => {
		this.setState({ loading: true });
		axios
			.get(`supplier/?serializer=list`)
			.then((res) => {
				this.setState({ suppliers: res.data, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	confirmDeleteSupplier = (supplier) => {
		DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Supplier ${supplier.code}`,
			confirmedresult: () => this.deleteSupplier(supplier.id),
		});
	};

	deleteSupplier = (id) => {
		this.setState({ loading: true });
		axios
			.delete(`supplier/${id}`)
			.then((res) => {
				let suppliers = this.state.suppliers.filter((supplier) => supplier.id !== id);
				this.setState({ suppliers: suppliers, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getSuppliers();
	}

	render() {
		const t = i18n.t;
		const columns = [
			{
				sort: true,
				text: t("code"),
				dataField: "code",
			},
			{
				sort: true,
				text: t("name"),
				dataField: "name",
			},
			{
				sort: true,
				text: t("paymentTerm"),
				dataField: "paymentTerm",
			},
			{
				text: t("actions"),
				dataField: "actions",
				formatter: (cell, supplier) => (
					<>
						<GetPermission perm="purch.view_supplier">
							<ViewIcon code="perm" target="_blank" to={{ pathname: `/view-supplier/?id=${supplier.id}` }} />
						</GetPermission>
						<GetPermission perm="purch.delete_supplier">
							<div code="perm">
								<DeleteIcon ondelete={() => this.confirmDeleteSupplier(supplier)} hidden={!supplier.deletable} />
							</div>
						</GetPermission>
					</>
				),
			},
		];

		return (
			<>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<PageTitleAlt2 heading={t("listSuppliers")} icon="bi bi-building icon-gradient bg-happy-fisher" />
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<LoadingOverlay
							tag="div"
							active={this.state.loading}
							styles={{
								overlay: (base) => ({
									...base,
									background: "#fff",
									opacity: 0.5,
								}),
							}}
							spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
						>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<Card className="main-card mb-3" key="0">
									<CardBody>
										<TableWithPaginationSearch
											scrollable
											search
											keyfield="id"
											columns={columns}
											data={this.state.suppliers}
											loading={this.state.loading}
										/>
									</CardBody>
								</Card>
							</Col>
						</LoadingOverlay>
					</Col>
				</Row>
			</>
		);
	}
}

export default withRouter(ListSupplier);
