import React from "react";
import i18n from "i18next";
import Beep from "../common/sound";
import { toast } from "react-toastify";
import { IoIosAlert, IoIosCheckmarkCircle, IoIosWarning } from "react-icons/all";

export const AlertNotification = (message, type, errorCode = null) => {
	if (type === "error") message = errorAlert(message, errorCode);
	else if (type === "success") message = successAlert(message);
	else if (type === "warning") message = warningAlert(message);
	/*Dismiss all other alerts*/
	toast.dismiss();
	if (type === "error") Beep();

	return toast[type](message, {
		position: "bottom-right",
		autoClose: 4000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

const successAlert = (message) => (
	<div>
		<IoIosCheckmarkCircle fontSize="35px" color="white" />
		<span className="font-weight-bold"> {" " + message} </span>
	</div>
);

const errorAlert = (message, errorCode) => {
	if (errorCode === 401) message = i18n.t("unauthorizedMessage");
	else if (errorCode === 403) message = i18n.t("permissionMessage");
	else if (message === null) message = i18n.t("errorMessage");
	return (
		<div>
			<IoIosAlert fontSize="35px" color="white" />
			<span className=""> {" " + message} </span>
		</div>
	);
};

const warningAlert = (message) => (
	<div>
		<IoIosWarning fontSize="35px" color="white" />
		<span className="font-weight-bold"> {" " + message} </span>
	</div>
);
