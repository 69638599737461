import axios from "axios";
import ItemCard from "./item_card";
import { getCookie } from "cookies";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { authAxios } from "axios_interceptors";
import { withTranslation } from "react-i18next";
import Search from "purch/common/generic_search";
import SearchItem from "purch/common/search_item";
import DeleteIcon from "purch/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import { inventoryAxios } from "axios_interceptors";
import CustomSelect from "purch/common/custom_select";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "purch/common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Form, Label, Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Input, FormGroup } from "reactstrap";

class NewPurchaseRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			hide: true,
			prTypes: [],
			urgencies: [],
			itemLists: [],
			warehouses: [],
			selectedItems: [],
			itemConditions: [],
			formLoading: false,
			userDepartments: [],
			pr: { code: "temp" },
		};
		this.itemsColumns = [
			{
				dataField: "itemCode",
				text: "Item Code",
			},
			{
				dataField: "name",
				text: "Item Name",
			},
			{
				dataField: "quantity",
				text: "Quantity",
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							required
							type="number"
							name="quantity"
							index={rowIndex}
							defaultValue={cell}
							className={`text-center`}
							onBlur={this.getPRItemValues}
							invalid={this.state.selectedItems[rowIndex]["invalidQuantity"]}
						/>
					);
				},
			},
			{
				text: "UoM",
				dataField: "uom",
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							name="uom"
							index={rowIndex}
							optionlable="fullName"
							options={row.UoMsOfDimension}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["invalidUom"]}
							defaultValue={this.state.selectedItems[rowIndex]["uomObj"]}
						/>
					);
				},
			},
			{
				text: "Condition",
				dataField: "condition",
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							index={rowIndex}
							name="condition"
							optionlable="condition"
							options={this.state.itemConditions}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["invalidCondition"]}
							defaultValue={this.state.selectedItems[rowIndex]["conditionObj"]}
						/>
					);
				},
			},
			{
				dataField: "note",
				text: "Note",
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							name="note"
							type="text"
							index={rowIndex}
							defaultValue={cell}
							className="text-center"
							onBlur={this.getPRItemValues}
						/>
					);
				},
			},
			{
				dataField: "action",
				text: "Actions",
				formatter: (cell, row, rowIndex) => {
					return <DeleteIcon index={rowIndex} ondelete={this.removeItem} />;
				},
			},
		];
	}

	getSearchResult = (result) => {
		this.setState({ items: result.data, hide: false });
	};

	addItem = (e) => {
		let index = e.target.getAttribute("index");
		let items = this.state.items;
		items[index]["catalogItem"] = items[index].id;
		items[index]["originalItem"] = items[index].id;
		this.setState({ selectedItems: this.state.selectedItems.concat(items[index]) });
		items.splice(index, 1);
		this.setState({ items: items });
	};

	removeItem = (e) => {
		let selected = this.state.selectedItems,
			items = this.state.items;
		items.unshift(selected[e.target.getAttribute("index")]);
		selected.splice(e.target.getAttribute("index"), 1);
		this.setState({ items: items, selectedItems: selected });
	};

	getPRItemValues = (e) => {
		let selected = [...this.state.selectedItems];
		let newObj = { ...selected[e.target.getAttribute("index")] };
		newObj[e.target.name] = e.target.value;
		newObj[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		selected[e.target.getAttribute("index")] = newObj;
		this.setState({ selectedItems: selected });
	};

	getPRItemSelectedValues = (e, r, index) => {
		let selected = [...this.state.selectedItems];
		selected[index][r.name] = e.id;
		selected[index][`${r.name}Obj`] = e;
		this.setState({ selectedItems: selected });
	};

	getSelectedValue = (e, r) => {
		let updatedPR = this.state.pr;
		updatedPR[r.name] = e.id;
		updatedPR[`selected${r.name}`] = e.name;
		if (r.name === "prType") {
			this.getItemLists(e.name);
		}
		this.setState({ pr: updatedPR });
	};

	getItems = (e) => {
		let repair_tire = new URLSearchParams(this.props.location.search).get("repair-tire");
		let extra_params = repair_tire ? "&repair_tire=true" : "";
		inventoryAxios.get(`itemlist/${e.id}/?serializer=pr${extra_params}`).then((res) => {
			if (this.state.items.length === 0) this.setState({ hide: !this.state.hide });
			this.setState({ items: res.data.items });
		});
	};

	validate = (e) => {
		e.preventDefault();
		if (
			e.target.name === "prType" ||
			e.target.name === "requestDepartment" ||
			e.target.name === "urgency" ||
			e.target.name === "warehouse"
		)
			return this.validatePR(e);
		let arr = [...this.state.selectedItems];
		let itemObj = { ...arr[e.target.getAttribute("index")] };
		itemObj[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = true;
		arr[e.target.getAttribute("index")] = itemObj;
		this.setState({ selectedItems: arr });
	};

	validatePR = (e) => {
		let pr = this.state.pr;
		pr[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = true;
		this.setState({ pr: pr });
	};

	submitPR = (e) => {
		e.preventDefault();
		let repair_tire = new URLSearchParams(this.props.location.search).get("repair-tire");
		let pr = this.state.pr;
		let prItems = [...this.state.selectedItems];
		pr["prItems"] = prItems.map((item) => {
			if (!item["uom"]) item["uom"] = item["defaultUnit"].id;
			return { ...item };
		});
		if (repair_tire) {
			pr["repair_tire"] = repair_tire;
		}
		this.setState({ formLoading: true });
		axios
			.post(`purchaseRequest/`, pr)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) this.props.history.push({ pathname: "/viewpr/", prID: res.data.id });
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getInitialData = () => {
		authAxios.get(`user/${getCookie("userId")}/getUserDepartment/`).then((res) => {
			this.setState({ userDepartments: res.data.departments });
		});
		axios.get(`purchaseType`).then((res) => {
			this.setState({ prTypes: res.data });
		});
		axios.get(`urgency`).then((res) => {
			this.setState({ urgencies: res.data });
		});
		axios.get(`prItemCondition`).then((res) => {
			this.setState({ itemConditions: res.data });
		});
		inventoryAxios.get(`warehouse/?serializer=id`).then((res) => {
			this.setState({ warehouses: res.data });
		});
	};

	getItemLists = (prType) => {
		let itemClass = ``;
		if (prType === "Administrative") itemClass = `itemClass__name=Administrative&`;
		else if (prType !== "Administrative" && prType !== "Repair") itemClass = `itemClass__name__ne=Administrative&`;
		inventoryAxios.get(`itemlist/?${itemClass}serializer=id`).then((res) => {
			this.setState({ itemLists: res.data });
		});
	};

	componentDidMount() {
		this.getInitialData();
	}

	render() {
		const { t } = this.props;
		const headerFormatter = (column) => <div style={{ width: "150px", minWidth: "fit-content" }}>{column.text}</div>;
		const itemsColumns = [
			{
				text: t("itemCode"),
				dataField: "itemCode",
				headerFormatter: headerFormatter,
			},
			{
				text: t("itemName"),
				dataField: "name",
				headerFormatter: headerFormatter,
			},
			{
				text: t("quantity"),
				dataField: "quantity",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							required
							type="number"
							name="quantity"
							index={rowIndex}
							defaultValue={cell}
							className={`text-center`}
							onBlur={this.getPRItemValues}
							invalid={this.state.selectedItems[rowIndex]["invalidQuantity"]}
						/>
					);
				},
			},
			{
				text: t("uom"),
				dataField: "uom",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							name="uom"
							index={rowIndex}
							optionlable="fullName"
							options={row.UoMsOfDimension}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["invalidUom"]}
							defaultValue={this.state.selectedItems[rowIndex]["uomObj"] || (row["defaultUnit"] && row["defaultUnit"])}
						/>
					);
				},
			},
			{
				text: t("condition"),
				dataField: "condition",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							index={rowIndex}
							name="condition"
							optionlable="condition"
							options={this.state.itemConditions}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["invalidCondition"]}
							defaultValue={this.state.selectedItems[rowIndex]["conditionObj"]}
						/>
					);
				},
			},
			{
				text: t("note"),
				dataField: "note",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							name="note"
							type="text"
							index={rowIndex}
							defaultValue={cell}
							className="text-center"
							onBlur={this.getPRItemValues}
						/>
					);
				},
			},
			{
				text: t("actions"),
				dataField: "action",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return <DeleteIcon index={rowIndex} ondelete={this.removeItem} />;
				},
			},
		];
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("createPurchaseRequest")} icon="bi bi-cart-plus icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					active={this.state.formLoading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Form onInvalid={this.validate} onSubmit={this.submitPR}>
						<Row>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<Card className="main-card mb-3" key="0">
									<CardHeader className="card-header-tab">
										<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
											{t("purchaseRequestDetails")}
										</div>
									</CardHeader>
									<CardBody>
										<Row className="d-flex justify-content-between">
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<Label>{t("prType")}</Label>
												<CustomSelect
													required
													name="prType"
													optionlable="name"
													options={this.state.prTypes}
													onChange={this.getSelectedValue}
													inValid={this.state.pr[`invalidPrType`]}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<FormGroup>
													<Label for="warehouse">{t("warehouse")}</Label>
													<CustomSelect
														name="warehouse"
														optionlable="name"
														options={this.state.warehouses}
														onChange={this.getSelectedValue}
														inValid={this.state.pr[`invalidWarehouse`]}
														required={this.state.pr.selectedprType !== "Administrative"}
														disabled={this.state.pr.selectedprType === "Administrative"}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3">
												<Label>{t("department")}</Label>
												<CustomSelect
													required
													optionlable="name"
													name="requestDepartment"
													onChange={this.getSelectedValue}
													options={this.state.userDepartments}
													inValid={this.state.pr[`invalidRequestDepartment`]}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="2" xl="2">
												<Label>{t("urgency")}</Label>
												<CustomSelect
													required
													name="urgency"
													optionlable="urgency"
													options={this.state.urgencies}
													onChange={this.getSelectedValue}
													inValid={this.state.pr[`invalidUrgency`]}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3">
												<Label>{t("itemList")}</Label>
												<CustomSelect
													optionlable="name"
													onChange={this.getItems}
													options={this.state.itemLists}
													disabled={!(this.state.pr.prType && this.state.pr.urgency && this.state.pr.requestDepartment)}
												/>
											</Col>
											<Col xs="12" sm="12" md="6" lg="3" xl="3" className="mt-1">
												<SearchItem
													callback={this.getSearchResult}
													params={new URLSearchParams(this.props.location.search).get("repair-tire") ? "&repair_tire=true" : ""}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.state.selectedItems.length > 0 ? false : true}>
								<Card key="1" className="mb-3">
									<CardHeader className="card-header-tab">
										<div className="card-header-title font-size-lg text-capitalize font-weight-normal">{t("selectedItems")}</div>
									</CardHeader>
									<TableWithPaginationSearch scrollable columns={itemsColumns} data={this.state.selectedItems} />
									<CardFooter className="d-flex justify-content-end">
										<GetPermission perm="purch.add_purchaserequest">
											<Button color="info" type="submit">
												{t("submit")}
											</Button>
										</GetPermission>
									</CardFooter>
								</Card>
							</Col>
						</Row>
						<div hidden={this.state.hide}>
							<Row className="d-flex justify-content-evenly">
								<Search searchkey="itemcode">
									{this.state.items.map((item, index) => {
										return (
											<Col xs="12" sm="12" md="12" lg="3" xl="3" key={index} index={index} itemcode={item.itemCode}>
												<ItemCard
													addfunction={this.addItem}
													itemcode={item.itemCode}
													itemname={item.name}
													itemImage={item.image}
													index={index}
													key={index}
												/>
											</Col>
										);
									})}
								</Search>
							</Row>
						</div>
					</Form>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withTranslation()(withRouter(NewPurchaseRequest));
