import Loader from "react-loader-advanced";
import React, {Component, Fragment} from "react";
import {Loader as LoaderAnim} from "react-loaders";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

const contentBoxStyle = {
	padding: 232,
	position: "relative",
	backgroundColor: "white",
};

class LoginIndicator extends Component {
	render() {
		const spinner1 = <LoaderAnim color="#ffffff" type="ball-pulse" />;

		return (
			<Fragment>
				<CSSTransitionGroup
					component="div"
					transitionAppear={true}
					transitionEnter={false}
					transitionLeave={false}
					transitionAppearTimeout={0}
					transitionName="TabsAnimation">
					<div>
						<Loader message={spinner1} show={true}>
							<div style={contentBoxStyle}>
								<div style={contentBoxStyle}></div>
							</div>
						</Loader>
					</div>
				</CSSTransitionGroup>
			</Fragment>
		);
	}
}

export default LoginIndicator;
