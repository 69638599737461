import i18n from "../../../i18n";
import React, { useContext } from "react";
import LocaleContext from "../../../LocaleContext";
import { supportUrl } from "../../../config/endpointConfig";
import { Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";

function LanguageNav() {
	const { locale } = useContext(LocaleContext);

	function changeLocale(l) {
		if (locale !== l) {
			i18n.changeLanguage(l);
		}
	}

	return (
		<Navbar bg="light" expand="lg">
			<Container>
				<Nav className={locale === "en" ? "ms-auto" : "me-auto"}>
					<NavItem title="Issue Ticket">
						<NavLink href={supportUrl()} className="text-light px-0">
							<i className={`bi bi-question-circle-fill info-hover `} style={{ fontSize: "1.1rem", cursor: "pointer" }}></i>
						</NavLink>
					</NavItem>
					<NavItem title="Language">
						<NavLink href="#" onClick={() => changeLocale(locale === "en" ? "ar" : "en")} className="text-light">
							{locale === "en" ? "العربية" : "English"}
						</NavLink>
					</NavItem>
				</Nav>
			</Container>
		</Navbar>
	);
}

export default LanguageNav;
