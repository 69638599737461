import {withRouter} from "react-router-dom";
import React, {Component, Fragment} from "react";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {withTranslation} from "react-i18next";

class ViewPRApprovalCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prCycle: {
                id: 1,
                name: "Cycle 1",
                costLimit: 1000,
                urgency: "Low",
                prType: "Technical",
                department: "Software",
                entities: [{
                    title: "Software",
                    sortOrder: 1,
                    approvalUser: "Noura",
                    approvalDepartment: ""
                }, {
                    title: "IT",
                    sortOrder: 2,
                    approvalUser: "Ahmed",
                    approvalDepartment: ""
                }, {
                    title: "DevOps",
                    sortOrder: 3,
                    approvalUser: "Mariam",
                    approvalDepartment: ""
                }, {
                    title: "HR Manager",
                    sortOrder: 4,
                    approvalUser: "",
                    approvalDepartment: "HR"
                }]
            },
            formLoading: false,
        };

    }

    getUrgencyColor = (priority) => {
        if (priority === "Low") return "bg-dark";
        if (priority === "Medium") return "bg-grow-early";
        if (priority === "High") return "bg-danger";
    };

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Row>
                    <Col>
                        <PageTitleAlt2 heading={t("prApprovalCycle")}
                                       icon="bi bi-patch-check icon-gradient bg-happy-fisher"/>
                    </Col>
                </Row>
                <Row>
                    <Col md="7" xl="7">
                        <div className={`card mb-3 widget-content ${this.getUrgencyColor(this.state.prCycle.urgency)}`}>
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">{t("urgency")}</div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">{this.state.prCycle.urgency}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="7" lg="7" xl="7">
                        <Card className="main-card mb-3">
                            <CardHeader className="card-header-tab">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
                                        {" "}
                                    </i>
                                    {t("prApprovalCycle")}
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row className="no-gutters">
                                    <Col sm="12" md="6" lg="6" className="pr-5">
                                        <ListGroup flush>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{t("name")}</div>
                                                                <div className="widget-numbers-sm text-info">{this.state.prCycle.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{t("costLimit")}</div>
                                                                <div className="widget-numbers-sm text-info">
                                                                    {Number(this.state.prCycle.costLimit).toLocaleString("en")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem> </ListGroupItem>
                                        </ListGroup>
                                    </Col>
                                    <Col sm="12" md="6" lg="6" className="pr-5">
                                        <ListGroup flush>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{t("department")}</div>
                                                                <div
                                                                    className="widget-numbers-sm text-info">{this.state.prCycle.department}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-outer">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{t("prType")}</div>
                                                                <div className="widget-numbers-sm text-info">{this.state.prCycle.prType}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem> </ListGroupItem>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs="12" sm="12" md="5" lg="5" xl="5">
                        <Card className="main-card mb-3">
                            <CardHeader className="card-header-tab">
                                <i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher"> </i>
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">{t("approvalEntities")}</div>
                            </CardHeader>
                            <ListGroup className="todo-list-wrapper" flush>
                                {this.state.prCycle.entities.map((entity, index) => {
                                    return (
                                        <ListGroupItem key={index}>
                                            <div className={`todo-indicator bg-info`} />
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left mr-2"></div>
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {entity.title}
                                                        </div>

                                                        <div className="widget-subheading">
                                                            <i>{entity.approvalUser ? entity.approvalUser : "....."}</i>
                                                        </div>
                                                        <div className="widget-subheading">
                                                            <i>{entity.approvalDepartment ? entity.approvalDepartment : "....."}</i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withTranslation()(withRouter(ViewPRApprovalCycle));
