import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import { Col, CustomInput, Row } from "reactstrap";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class PRItems extends Component {
	nullChecker = (cell) => (!cell ? "-" : cell);
	checkBox = (cell, item, rowIndex) => (
		<CustomInput
			id={item.id}
			type="checkbox"
			data-id={rowIndex}
			className="text-center"
			onChange={(event) => this.props.selectPRItem(event, item, rowIndex)}
		/>
	);
	render() {
		const { t } = this.props;
		const headerFormatter = (column, colIndex) => <div style={{ width: "150px", minWidth: "fit-content" }}>{t(column.text)}</div>;
		const columns = [
			{
				text: "purchaseRequest",
				dataField: "purchaseRequest",
				headerFormatter: headerFormatter,
			},
			{
				text: "requestDate",
				dataField: "creationDate",
				headerFormatter: headerFormatter,
			},
			{
				text: "urgency",
				dataField: "urgency",
				headerFormatter: headerFormatter,
			},
			{
				text: "itemCode",
				dataField: "catalogItem.itemCode",
				headerFormatter: headerFormatter,
			},
			{
				text: "itemName",
				dataField: "catalogItem.name",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "quantity",
				dataField: "quantity",
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
				headerFormatter: headerFormatter,
			},
			{
				text: "remainingQuantity",
				dataField: "remainingQuantity",
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
				headerFormatter: headerFormatter,
			},
			{
				text: "uom",
				dataField: "uom.abbreviation",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "condition",
				dataField: "condition.condition",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "status",
				dataField: "status",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "note",
				dataField: "note",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
		];
		return (
			<Fragment>
				<Row className="mt-4">
					<Col>
						<TableWithPaginationSearch
							search
							scrollable
							keyfield="id"
							columns={columns}
							data={this.props.prItems}
							loading={!this.props.prItems}
							selectRow={this.props.selectRow}
						/>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withTranslation()(PRItems);
