import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import SupplierForm from "./supplier_form";
import SupplierCard from "./supplier_card";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import DeletePopup from "purch/common/delete_popup";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import { AlertNotification } from "purch/common/alert_notification";

class ViewSupplier extends Component {
	constructor() {
		super();
		this.state = { loading: false, supplier: {}, updatedSupplier: {}, editView: false, taxes: [], paymentTerms: [] };
	}

	getSupplier = (id) => {
		this.setState({ loading: true });
		axios
			.get(`supplier/${id}/?serializer=get`)
			.then((res) => {
				if (res.data) {
					res.data["taxObj"] = {
						id: res.data.tax__id,
						VAT: res.data.tax__vat,
						withHolding: res.data.tax__withHolding,
					};
					res.data["paymentTermObj"] = {
						id: res.data.paymentTerm__id,
						paymentTerm: res.data.paymentTerm,
					};

					this.setState({ supplier: res.data }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.repsponse);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	confirmDeleteSupplier = (supplier) => {
		DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Supplier ${supplier.code}`,
			confirmedresult: () => this.deleteSupplier(supplier.id),
		});
	};

	deleteSupplier = (id) => {
		this.setState({ loading: true });
		axios
			.delete(`supplier/${id}`)
			.then((res) => {
				this.setState({ loading: false });
				this.props.history.push({ pathname: "/list-supplier" });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	getPaymentTerms = () => {
		this.setState({ loading: true });
		axios
			.get(`paymentterm/`)
			.then((res) => {
				this.setState({ paymentTerms: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	getTaxes = () => {
		this.setState({ loading: true });
		axios
			.get(`tax/`)
			.then((res) => {
				this.setState({ taxes: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	editSupplier = () => {
		this.setState(
			(prevState) => {
				return { editView: !prevState.editView };
			},
			() => {
				if (this.state.editView) {
					let supplier = { ...this.state.supplier };
					supplier["taxObj"] = {
						id: supplier.tax__id,
						VAT: supplier.tax__vat,
						withHolding: supplier.tax__withHolding,
					};
					supplier["paymentTermObj"] = {
						id: supplier.paymentTerm__id,
						paymentTerm: supplier.paymentTerm,
					};
					this.setState({ supplier: supplier }, () => {
						this.getPaymentTerms();
						this.getTaxes();
					});
				}
			}
		);
	};

	getUpdateValue = (e) => {
		let target = e.target.name;
		let value = e.target.value;
		let supplier = this.state.supplier,
			updatedSupplier = this.state.updatedSupplier;
		if (e.target.type === "checkbox") {
			let checked = e.target.checked;
			value = checked;
		}
		supplier[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		supplier[target] = value;
		updatedSupplier[target] = value;
		this.setState({ supplier: supplier, updatedSupplier: updatedSupplier });
	};

	getUpdatedTaxValue = (tax) => {
		let supplier = this.state.supplier,
			updatedSupplier = this.state.updatedSupplier;
		supplier["tax"] = tax.id;
		supplier["tax__vat"] = tax.VAT;
		supplier["tax__withHolding"] = tax.withHolding;
		supplier["taxObj"] = tax;
		supplier["invalidTax"] = false;
		updatedSupplier["tax"] = tax.id;
		this.setState({ supplier: supplier, updatedSupplier: updatedSupplier });
	};

	getUpdatedPaymentTermValue = (paymentTerm) => {
		let supplier = this.state.supplier,
			updatedSupplier = this.state.updatedSupplier;
		supplier["paymentTerm"] = paymentTerm.paymentTerm;
		supplier["paymentTermObj"] = paymentTerm;
		supplier["invalidPaymentTerm"] = false;
		updatedSupplier["paymentTerm"] = paymentTerm.id;
		this.setState({ supplier: supplier, updatedSupplier: updatedSupplier });
	};

	validateUpdate = (e) => {
		let supplier = this.state.supplier;
		supplier[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = true;
		this.setState({ newSupplier: supplier });
	};

	update = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.patch(`supplier/${this.state.supplier.id}/?serializer=create`, this.state.updatedSupplier)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("updated"), "success");
					this.setState({ editView: false, loading: false });
				}
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		let supplierID = new URLSearchParams(this.props.location.search).get("id");
		this.getSupplier(supplierID);
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<PageTitleAlt2 heading={t("viewSupplier")} icon="bi bi-building icon-gradient bg-happy-fisher" />
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<LoadingOverlay
							tag="div"
							active={this.state.loading}
							styles={{
								overlay: (base) => ({
									...base,
									background: "#fff",
									opacity: 0.5,
								}),
							}}
							spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
						>
							{this.state.editView ? (
								<SupplierForm
									validate={this.validateUpdate}
									submit={this.update}
									getValue={this.getUpdateValue}
									supplier={this.state.supplier}
									perm="purch.change_supplier"
									taxes={this.state.taxes}
									getSelectTax={this.getUpdatedTaxValue}
									paymentTerms={this.state.paymentTerms}
									getSelectPaymentTerm={this.getUpdatedPaymentTermValue}
									submitBtnName="update"
									cancelBtn
									cancel={this.editSupplier}
								/>
							) : (
								<SupplierCard editSupplier={this.editSupplier} supplier={this.state.supplier} confirmDeleteSupplier={this.confirmDeleteSupplier} />
							)}
						</LoadingOverlay>
					</Col>
				</Row>
			</>
		);
	}
}

export default withRouter(ViewSupplier);
