import React from "react";

export const CancelIcon = (props) => {
	return (
		<>
			{props.noIconWrapper ? (
				<i
					hidden={props.hide}
					className={`bi bi-x-lg ${props.onHovercolor || "info"}-hover ${props.iconClassName || ""}`}
					style={{ fontSize: props.iconSize || "1rem", cursor: "pointer" }}
					onClick={props.onCancel}></i>
			) : (
				<div className={`icon-wrapper text-dark ${props.parentClassName || ""}`} hidden={props.hide}>
					<i
						className={`bi bi-x-lg info-hover ${props.iconClassName || ""}`}
						style={{ fontSize: props.iconSize || "1rem" }}
						onClick={props.onCancel}></i>
				</div>
			)}
		</>
	);
};
