import i18n from "i18n";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import SupplierForm from "./supplier_form";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "purch/common/PageTitleAlt2";
import { AlertNotification } from "purch/common/alert_notification";

class NewSupplier extends Component {
	constructor() {
		super();
		this.state = { loading: false, newSupplier: {}, paymentTerms: [], taxes: [] };
	}

	getValue = (e) => {
		let target = e.target.name;
		let value = e.target.value;
		let newSupplier = this.state.newSupplier;
		if (e.target.type === "checkbox") {
			let checked = e.target.checked;
			value = checked;
		}
		newSupplier[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		newSupplier[target] = value;
		this.setState({ newSupplier: newSupplier });
	};

	getSelectPaymentTerm = (paymentTerm) => {
		let newSupplier = this.state.newSupplier;
		newSupplier["paymentTerm"] = paymentTerm.id;
		newSupplier["paymentTermObj"] = paymentTerm;
		newSupplier["invalidPaymentTerm"] = false;
		this.setState({ newSupplier: newSupplier });
	};

	getSelectTax = (tax) => {
		let newSupplier = this.state.newSupplier;
		newSupplier["tax"] = tax.id;
		newSupplier["taxObj"] = tax;
		newSupplier["invalidTax"] = false;
		this.setState({ newSupplier: newSupplier });
	};

	validate = (e) => {
		let newSupplier = this.state.newSupplier;
		newSupplier[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = true;
		this.setState({ newSupplier: newSupplier });
	};

	showAlert = () => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			showCancelButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			cancelButtonText: i18n.t("no"),
			title: i18n.t("created"),
			text: i18n.t("doYouWantToMoveToSupplierList"),
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-info",
				cancelButton: "btn btn-dark ml-3",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.history.push({
					pathname: "/list-supplier",
				});
			} else {
			}
			this.props.history.go(0);
		});
	};

	submit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`supplier/?serializer=create`, this.state.newSupplier)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("created"), "success");
					this.setState({ newSupplier: {}, loading: false });
					this.showAlert();
				}
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	getPaymentTerms = () => {
		this.setState({ loading: true });
		axios
			.get(`paymentterm/`)
			.then((res) => {
				this.setState({ paymentTerms: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	getTaxes = () => {
		this.setState({ loading: true });
		axios
			.get(`tax/`)
			.then((res) => {
				this.setState({ taxes: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getPaymentTerms();
		this.getTaxes();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<PageTitleAlt2 heading={t("newSupplier")} icon="bi bi-building icon-gradient bg-happy-fisher" />
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<LoadingOverlay
							tag="div"
							active={this.state.loading}
							styles={{
								overlay: (base) => ({
									...base,
									background: "#fff",
									opacity: 0.5,
								}),
							}}
							spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
						>
							<SupplierForm
								validate={this.validate}
								submit={this.submit}
								getValue={this.getValue}
								supplier={this.state.newSupplier}
								perm="purch.add_supplier"
								taxes={this.state.taxes}
								getSelectTax={this.getSelectTax}
								paymentTerms={this.state.paymentTerms}
								getSelectPaymentTerm={this.getSelectPaymentTerm}
								submitBtnName="submit"
							/>
							{/* <Card className="main-card mb-3" key="0">
								<CardBody>
									<Form onInvalid={this.validate} onSubmit={this.submit}>
										<Row>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("code")}</Label>
													<Input
														required
														onChange={this.getValue}
														type="text"
														name="code"
														invalid={this.state.newSupplier["invalidCode"]}
														value={this.state.newSupplier["code"] || ""}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("name")}</Label>
													<Input
														required
														onChange={this.getValue}
														type="text"
														name="name"
														invalid={this.state.newSupplier["invalidName"]}
														value={this.state.newSupplier["name"] || ""}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("email")}</Label>
													<Input
														type="email"
														name="email"
														onChange={this.getValue}
														value={this.state.newSupplier["email"] || ""}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("address")}</Label>
													<Input
														type="text"
														name="address"
														onChange={this.getValue}
														value={this.state.newSupplier["address"] || ""}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("phoneNumber")}</Label>
													<Input
														type="number"
														name="phoneNumber"
														onChange={this.getValue}
														value={this.state.newSupplier["phoneNumber"] || ""}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("mobileNumber")}</Label>
													<Input
														type="number"
														name="mobileNumber"
														onChange={this.getValue}
														value={this.state.newSupplier["mobileNumber"] || ""}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs="12" md="6">
												<FormGroup className="d-flex ml-4">
													<Label>{t("hasTax")}</Label>
													<Input
														type="checkbox"
														defaultChecked
														name="hasTax"
														onClick={this.getValue}
														value={this.state.newSupplier["hasTax"] || true}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" md="6">
												<FormGroup className="d-flex ml-4">
													<Label>{t("hasWithHolding")}</Label>
													<Input
														type="checkbox"
														defaultChecked
														name="hasWithHolding"
														onClick={this.getValue}
														value={this.state.newSupplier["hasWithHolding"] || true}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("paymentTerm")}</Label>
													<CustomSelect
														required
														name="paymentTerm"
														onChange={this.getSelectPaymentTerm}
														options={this.state.paymentTerms}
														optionlable="paymentTerm"
														inValid={this.state.newSupplier["invalidPaymentTerm"]}
														value={this.state.newSupplier["paymentTermObj"] || null}
													/>
												</FormGroup>
											</Col>
											<Col xs="12" md="6">
												<FormGroup>
													<Label>{t("tax")}</Label>
													<CustomSelect
														name="tax"
														inValid={this.state.newSupplier["invalidTax"]}
														onChange={this.getSelectTax}
														options={this.state.taxes}
														optionlable="VAT"
														value={this.state.newSupplier["taxObj"] || null}
													/>
												</FormGroup>
											</Col>
										</Row>
										<GetPermission perm="purch.add_supplier">
											<Button code="perm" type="submit" color="info" className="float-right">
												{t("submit")}
											</Button>
										</GetPermission>
									</Form>
								</CardBody>
							</Card> */}
						</LoadingOverlay>
					</Col>
				</Row>
			</>
		);
	}
}

export default withRouter(NewSupplier);
