import React from "react";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

/* A generic dropdown component takes a list of objects each object has {id: , name:} */

class DropDown extends React.Component {
	componentDidMount() {
		this.setState({ title: this.props.title });
	}

	onClick = (event) => {
		this.setState({ title: event.target.value });
		this.props.onclick(event);
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.title !== this.props.title) {
			this.setState({ title: this.props.title });
		}
	}

	render() {
		return (
			<div style={{ float: "left" }}>
				<UncontrolledButtonDropdown>
					<DropdownToggle hidden={this.props.hide} disabled={this.props.disabled} caret className="mb-2 mr-2" color="info">
						{this.state ? this.state.title : this.props.title}
					</DropdownToggle>
					<DropdownMenu right={this.props.right} className="dropdown-menu-rounded">
						{this.props.values.map((value, index) => (
							<DropdownItem key={value.id} index={index} data-id={value.id} value={value.name ? value.name : value.code} onClick={this.onClick}>
								{value.name ? value.name : value.code}
							</DropdownItem>
						))}
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</div>
		);
	}
}

export default DropDown;
