import axios from "axios";
import Loader from "react-loaders";
import { getCookie } from "cookies";
import { withRouter } from "react-router-dom";
import ViewIcon from "purch/common/view_icon";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import { getCurrentMonthDate } from "../../helper/date";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

class ListPO extends Component {
  constructor(props) {
    super(props);
    this.poStatus = [
      { id: 1, name: "All Status", status: "" },
      { id: 2, name: "Pending", status: "Pending", color: "text-warning" },
      { id: 3, name: "Approved", status: "Approved", color: "text-info" },
      { id: 4, name: "Rejected", status: "Rejected", color: "text-danger" },
    ];

    this.state = {
      loading: true,
      pos: [],
      isUnReceived: true,
      allPOs: [],
      showZeroCost: false,
      showPendingUserApproval: true,
      dates: { startDate: "", endDate: "", todayDate: "" },
    };
  }

  componentDidMount() {
    const dates = getCurrentMonthDate();
    // Get purchase Orders after dates saved in state
    this.setState({ dates }, function() {
      this.getPurchaseOrders();
    });
  }

  getPurchaseOrders = (status = "") => {
    let startDate = this.state.dates.startDate + " 00:00";
    let endDate = this.state.dates.endDate + " 23:59";
    let dateRange = [startDate, endDate];
    this.setState({ loading: true });
    axios
      .get(
        `po/?unreceiverd=${this.state.isUnReceived}&creationDate__range=${dateRange}&serializer=get&ordering=-creationDate&status=${status}`
      )
      .then((res) => {
        this.handlePOsCheckboxFilter(res.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        if (error.response)
          AlertNotification(
            error.response.data.split(".")[0],
            "error",
            error.response.status
          );
        else AlertNotification(null, "error");
        this.setState({ loading: false });
      });
  };

  handleSelectDate = (e) => {
    let newDate = this.state.dates;
    newDate[e.target.name] = e.target.value;
    this.setState({ dates: newDate });
  };

  onSelectFilter = (event) => {
    const filterIndex = event.target.getAttribute("index");
    this.getPurchaseOrders(this.poStatus[filterIndex].status);
  };

  getStatusColor = (status) =>
    this.poStatus.find((element) => element.status === status).color;

  // check if field is null set col to -
  nullChecker = (cell) => (!cell ? "-" : cell);

  actionButtons = (cell, item, rowIndex) => (
    <Fragment>
      <GetPermission perm="purch.view_purchaseorder">
        <div code="perm">
          <ViewIcon
            target="_blank"
            index={rowIndex}
            to={{ pathname: `/viewpo/?id=${item.id}` }}
          />
        </div>
      </GetPermission>
    </Fragment>
  );

  handleReceivedFilter = () => {
    this.setState((prevState) => {
      return { isUnReceived: !prevState.isUnReceived };
    });
  };

  handleZeroCostFilter = () => {
    this.setState(
      (prevState) => {
        return { showZeroCost: !prevState.showZeroCost };
      },
      () => {
        this.handlePOsCheckboxFilter();
      }
    );
  };

  handleGetPendingUserApprovalFilter = () => {
    this.setState(
      (prevState) => {
        return { showPendingUserApproval: !prevState.showPendingUserApproval };
      },
      () => {
        this.handlePOsCheckboxFilter();
      }
    );
  };

  handlePOsCheckboxFilter = (allPOs) => {
    if (!allPOs) allPOs = this.state.allPOs;
    let pos = [];
    if (!this.state.showZeroCost & !this.state.showPendingUserApproval) {
      pos = allPOs.filter((po) => Number(po.totalCostAfterTax) !== 0);
    } else if (this.state.showZeroCost & !this.state.showPendingUserApproval) {
      pos = allPOs;
    } else if (!this.state.showZeroCost & this.state.showPendingUserApproval) {
      pos = allPOs.filter(
        (po) =>
          Number(po.totalCostAfterTax) !== 0 &&
          po.nextApprovalUsers.includes(Number(getCookie("userId")))
      );
    } else {
      pos = allPOs;
    }
    this.setState({ allPOs: allPOs, pos: pos, loading: false });
  };

  render() {
    const headerSortingStyle = { backgroundColor: "#dee2e6" };
    const { t } = this.props;
    const headerFormatter = (column, colIndex) => <span>{t(column.text)}</span>;
    const columns = [
      {
        text: "code",
        dataField: "code",
        headerFormatter: headerFormatter,
      },
      {
        text: "poType",
        dataField: "poType",
        headerFormatter: headerFormatter,
      },
      {
        text: "status",
        dataField: "status",
        classes: (cell) => this.getStatusColor(cell),
        searchable: false,
        headerFormatter: headerFormatter,
      },
      {
        text: "supplier",
        dataField: "supplier.name",
        formatter: this.nullChecker,
        headerFormatter: headerFormatter,
      },
      {
        text: "totalCostAfterTax",
        dataField: "totalCostAfterTax",
        headerFormatter: headerFormatter,
        formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
      },
      {
        text: "orderDate",
        dataField: "creationDate",
        headerFormatter: headerFormatter,
        formatter: this.nullChecker,
        sort: true,
        searchable: false,
        headerSortingStyle,
      },
      {
        text: "actions",
        dataField: "",
        headerFormatter: headerFormatter,
        formatter: this.actionButtons,
      },
    ];

    return (
      <Fragment>
        <LoadingOverlay
          tag="div"
          active={this.state.loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={
            <Loader active color="#30b1ff" type="line-spin-fade-loader" />
          }
        >
          <PageTitleAlt2
            heading={t("purchaseOrders")}
            icon="bi bi-bag icon-gradient bg-happy-fisher"
          />
          <Row>
            <Col xs="12" sm="4" md="3" lg="3" xl="3">
              <FormGroup>
                <Label>{t("startDate")}</Label>
                <Input
                  type="date"
                  name="startDate"
                  onChange={this.handleSelectDate}
                  max={this.state.dates.endDate}
                  value={this.state.dates.startDate}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="4" md="3" lg="3" xl="3">
              <FormGroup>
                <Label>{t("endDate")}</Label>
                <Input
                  type="date"
                  name="endDate"
                  onChange={this.handleSelectDate}
                  min={this.state.dates.startDate}
                  max={this.state.dates.todayDate}
                  value={this.state.dates.endDate}
                />
              </FormGroup>
            </Col>
            <Col
              xs="12"
              sm="4"
              md="2"
              lg="2"
              xl="2"
              className="d-flex align-items-center"
            >
              <GetPermission perm="purch.view_purchaseorder">
                <Button
                  className="mt-2"
                  code="perm"
                  color="info"
                  onClick={() => this.getPurchaseOrders()}
                >
                  {t("submit")}
                </Button>
              </GetPermission>
            </Col>
          </Row>
          <Row className="my-1">
            <Col xs="12" sm="4" md="4" lg="4" xl="4">
              <FormGroup check>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                      <div className="widget-title ">
                        <Input
                          type="checkbox"
                          name="pendingUserApproval"
                          onChange={this.handleGetPendingUserApprovalFilter}
                          checked={this.state.showPendingUserApproval}
                        />
                        {t("pendingUserApproval")}
                      </div>
                      <div className="widget-title ">
                        <Input
                          type="checkbox"
                          name="zeroCost"
                          onChange={this.handleZeroCostFilter}
                          checked={this.state.showZeroCost}
                        />
                        {t("includeZeroCostPOs")}
                      </div>
                      <div className="widget-title ">
                        <Input
                          type="checkbox"
                          name="unreceived"
                          onChange={this.handleReceivedFilter}
                          checked={this.state.isUnReceived}
                        />
                        {t("showUnReceivedPOs")}
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Card className="main-card mb-3" key="0">
                <CardBody>
                  <TableWithPaginationSearch
                    scrollable
                    search
                    keyfield="id"
                    columns={columns}
                    data={this.state.pos}
                    loading={this.state.loading}
                    filtersOptions={this.poStatus}
                    filterTitle={this.poStatus[0].name}
                    onSelectFilter={this.onSelectFilter}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </LoadingOverlay>
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(ListPO));
