import { Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import EditIcon from "purch/common/edit_icon";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import BootstrapTable from "react-bootstrap-table-next";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";

class ListPRApprovalCycle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			approvalCycles: [
				{
					name: "Test Cycle",
					prType: "Technical",
					urgency: "High",
					department: "IT",
					prCycleEntity: [
						{
							title: "Senior Technical Support",
							sortOrder: 1,
							approvalUser: [{ name: "Ahmed" }, { name: "Sherif" }],
						},
						{ title: "IT Section Head", sortOrder: 2, approvalUser: [{ name: "Mariam" }, { name: "Noura" }] },
						{ title: "CIO", sortOrder: 3, approvalUser: [{ name: "Karim" }] },
					],
				},
				{
					name: "Admin Cycle",
					prType: "Admin",
					urgency: "Normal",
					department: "Administration",
					prCycleEntity: [
						{
							title: "Senior Technical Support",
							sortOrder: 1,
							approvalUser: [{ name: "Osama" }, { name: "Sherif" }],
						},
						{ title: "IT Section Head", sortOrder: 2, approvalUser: [{ name: "Mariam" }, { name: "Salama" }] },
						{ title: "CIO", sortOrder: 3, approvalUser: [{ name: "Karim" }] },
					],
				},
				{
					name: "Suzan Cycle",
					prType: "HR",
					urgency: "Normal",
					department: "Human Resources",
					prCycleEntity: [
						{
							title: "Senior Technical Support",
							sortOrder: 1,
							approvalUser: [{ name: "Ahmed" }, { name: "Sherif" }],
						},
						{ title: "IT Section Head", sortOrder: 2, approvalUser: [{ name: "Mariam" }, { name: "Noura" }] },
						{ title: "CIO", sortOrder: 3, approvalUser: [{ name: "Karim" }] },
					],
				},
				{
					name: "Magdi Cycle",
					prType: "Admin",
					urgency: "High",
					department: "Management",
					prCycleEntity: [
						{
							title: "Senior Technical Support",
							sortOrder: 1,
							approvalUser: [{ name: "Ahmed" }, { name: "Sherif" }],
						},
						{ title: "IT Section Head", sortOrder: 2, approvalUser: [{ name: "Mariam" }, { name: "Noura" }] },
						{ title: "CIO", sortOrder: 3, approvalUser: [{ name: "Karim" }] },
					],
				},
			],
		};
	}

	onCLickAddNew = () => {
		this.props.history.push({ pathname: "/addprapprovalcycle/" });
	};
	actionButtons = (cell, item, rowIndex) => (
		<div>
			<EditIcon
				index={rowIndex}
				onEdit={() => this.props.history.push({ pathname: "/addprapprovalcycle/", prApprovalCycle: 1 })}
			/>
		</div>
	);

	render() {
		const columns = [
			{
				text: "",
				dataField: "name",
				headerFormatter: () => {
					return "Name";
				},
				style: { cursor: "zoom-in" },
			},
			{
				text: "",
				dataField: "department",
				headerFormatter: () => {
					return "Department";
				},
				style: { cursor: "zoom-in" },
			},
			{
				text: "",
				dataField: "prType",
				headerFormatter: () => {
					return "PR Type";
				},
				style: { cursor: "zoom-in" },
			},
			{
				text: "",
				dataField: "urgency",
				headerFormatter: () => {
					return "Urgency";
				},
				style: { cursor: "zoom-in" },
			},
			{
				text: "",
				dataField: "actions",
				formatter: this.actionButtons,
				headerFormatter: () => {
					return "Actions";
				},
			},
		];

		const entityColumns = [
			{
				text: "",
				dataField: "sortOrder",
				headerFormatter: () => {
					return "Approval Order";
				},
			},
			{
				text: "",
				dataField: "title",
				headerFormatter: () => {
					return "Job Title";
				},
			},
			{
				text: "",
				dataField: "users",
				headerFormatter: () => {
					return "Approval Users";
				},
				formatter: (cellContent, row) => {
					return row.approvalUser.map((user) => {
						return (
							<div key={Math.floor(Math.random() * 1000000)} className="mb-2 mr-2 badge badge-dark text-capitalize">
								{user.name}
							</div>
						);
					});
				},
			},
		];

		const expandRow = {
			renderer: (row) => <BootstrapTable columns={entityColumns} data={row.prCycleEntity} keyField="sortOrder" />,
		};

		return (
			<Fragment>
				<PageTitleAlt2 heading="List PR Approval Cycles" icon="pe-7s-pen icon-gradient bg-happy-fisher" />
				<Card className="main-card mb-3">
					<CardBody>
						<TableWithPaginationSearch
							pagination
							sizePerpage={10}
							search
							keyfield="name"
							columns={columns}
							expandRow={expandRow}
							data={this.state.approvalCycles}
							addNew={true}
							onCLickAddNew={this.onCLickAddNew}
						/>
					</CardBody>
				</Card>
			</Fragment>
		);
	}
}

export default withTranslation()(withRouter(ListPRApprovalCycle));
