import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as serviceWorker from "./serviceWorker";

import {HashRouter} from "react-router-dom";
import "./assets/base.scss";
import Main from "./Main";
import configureStore from "./config/configureStore";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import {axiosInterceptors} from "./axios_interceptors";

const store = configureStore();
const rootElement = document.getElementById("root");

// add axios interceptor to handle request and response before return to called method
axiosInterceptors();

const renderApp = (Component) => {
	ReactDOM.render(
		<Provider store={store}>
			<ToastContainer />
			<HashRouter>
				<Component />
			</HashRouter>
		</Provider>,
		rootElement
	);
};

renderApp(Main);

if (module.hot) {
	module.hot.accept("./Main", () => {
		const NextApp = require("./Main").default;
		renderApp(NextApp);
	});
}
serviceWorker.unregister();
