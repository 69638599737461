import React from "react";
import axios from "axios";
import ItemCard from "./item_card";
import { Loader } from "react-loaders";
import { withTranslation } from "react-i18next";
import Search from "purch/common/generic_search";
import DeleteIcon from "purch/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import CustomSelect from "purch/common/custom_select";
import BootstrapTable from "react-bootstrap-table-next";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "purch/common/alert_notification";
import { Form, Row, Col, Card, CardHeader, CardFooter, Button, Input } from "reactstrap";

class AddItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			selectedItems: [],
			itemConditions: [],
			formLoading: false,
		};
	}

	addItem = (e) => {
		let index = e.target.getAttribute("index");
		let items = this.state.items;
		items[index]["catalogItem"] = items[index].id;
		items[index]["originalItem"] = items[index].id;
		items[index]["purchaseRequest"] = this.props.pr;
		this.setState({ selectedItems: this.state.selectedItems.concat(items[index]) });
		items.splice(index, 1);
		this.setState({ items: items });
	};

	removeItem = (e) => {
		let selected = this.state.selectedItems,
			items = this.state.items;
		items.unshift(selected[e.target.getAttribute("index")]);
		selected.splice(e.target.getAttribute("index"), 1);
		this.setState({ items: items, selectedItems: selected });
	};

	getPRItemValues = (e) => {
		let selected = [...this.state.selectedItems];
		let newObj = JSON.parse(JSON.stringify(selected[e.target.getAttribute("index")]));
		newObj[e.target.name] = e.target.value;
		newObj[`inValid_${e.target.name}`] = false;
		selected[e.target.getAttribute("index")] = newObj;
		this.setState({ selectedItems: selected });
	};

	getPRItemSelectedValues = (e, r, index) => {
		let selected = [...this.state.selectedItems];
		selected[index][r.name] = e.id;
		selected[index][`${r.name}_obj`] = e;
		this.setState({ selectedItems: selected });
	};

	validate = (e) => {
		e.preventDefault();
		if (e.target.name === "prType" || e.target.name === "requestDepartment" || e.target.name === "urgency")
			return this.validatePR(e);
		let arr = [...this.state.selectedItems];
		let itemObj = JSON.parse(JSON.stringify(arr[e.target.getAttribute("index")]));
		itemObj[`inValid_${e.target.name}`] = true;
		arr[e.target.getAttribute("index")] = itemObj;
		this.setState({ selectedItems: arr });
	};

	submitPR = (e) => {
		e.preventDefault();
		this.setState({ formLoading: true });
		axios
			.post(`purchaseRequestItem/`, this.state.selectedItems)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					this.props.refresh(this.props.pr);
					this.setState({ items: [], selectedItems: [] });
					AlertNotification("Items are added", "success");
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	componentDidMount() {
		axios.get(`prItemCondition`).then((res) => {
			this.setState({ itemConditions: res.data });
		});
	}

	componentDidUpdate() {
		if (this.props.items !== this.state.items) {
			this.setState({
				items: this.props.items,
			});
		}
	}

	render() {
		const { t } = this.props;
		const itemsColumns = [
			{
				dataField: "itemCode",
				text: "Item Code",
				headerFormatter: () => {
					return t("itemCode");
				},
			},
			{
				dataField: "name",
				text: "Item Name",
				headerFormatter: () => {
					return t("itemName");
				},
			},
			{
				dataField: "quantity",
				text: "Quantity",
				headerFormatter: () => {
					return t("quantity");
				},
				style: { width: "150px" },
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							required
							type="number"
							name="quantity"
							index={rowIndex}
							defaultValue={cell}
							className={`text-center`}
							onBlur={this.getPRItemValues}
							invalid={this.state.selectedItems[rowIndex]["inValid_quantity"]}
						/>
					);
				},
			},
			{
				text: "UoM",
				dataField: "uom",
				headerFormatter: () => {
					return t("uom");
				},
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							name="uom"
							index={rowIndex}
							optionlable="fullName"
							options={row.UoMsOfDimension}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["inValid_uom"]}
							defaultValue={this.state.selectedItems[rowIndex]["uom_obj"]}
						/>
					);
				},
			},
			{
				text: "Condition",
				dataField: "condition",
				headerFormatter: () => {
					return t("condition");
				},
				formatter: (cell, row, rowIndex) => {
					return (
						<CustomSelect
							required
							index={rowIndex}
							name="condition"
							optionlable="condition"
							options={this.state.itemConditions}
							onChange={(e, r) => {
								this.getPRItemSelectedValues(e, r, rowIndex);
							}}
							inValid={this.state.selectedItems[rowIndex]["inValid_condition"]}
							defaultValue={this.state.selectedItems[rowIndex]["condition_obj"]}
						/>
					);
				},
			},
			{
				dataField: "note",
				text: "Note",
				headerFormatter: () => {
					return t("note");
				},
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							name="note"
							type="text"
							index={rowIndex}
							defaultValue={cell}
							className="text-center"
							onBlur={this.getPRItemValues}
						/>
					);
				},
			},
			{
				dataField: "action",
				text: "Actions",
				headerFormatter: () => {
					return t("actions");
				},
				formatter: (cell, row, rowIndex) => {
					return <DeleteIcon index={rowIndex} ondelete={this.removeItem} />;
				},
			},
		];
		return (
			<LoadingOverlay
				tag="div"
				active={this.state.formLoading}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
				<Form onInvalid={this.validate} onSubmit={this.submitPR}>
					<Row>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.state.selectedItems.length > 0 ? false : true}>
							<Card key="1" className="mb-3">
								<CardHeader className="card-header-tab">
									<div className="card-header-title font-size-lg text-capitalize font-weight-normal">{t("selectedItems")}</div>
								</CardHeader>
								<div className="table-responsive">
									<BootstrapTable
										hover
										striped
										condensed
										tabIndexCell
										keyField="id"
										bordered={false}
										columns={itemsColumns}
										bodyClasses="text-center"
										data={this.state.selectedItems}
										headerWrapperClasses="text-center"
									/>
								</div>
								<CardFooter className="d-flex justify-content-end">
									<GetPermission perm="purch.add_purchaserequestitem">
										<div code="perm">
											<Button color="info" type="submit">
												{t("submit")}
											</Button>
										</div>
									</GetPermission>
								</CardFooter>
							</Card>
						</Col>
					</Row>
					<div hidden={this.state.items.length > 0 ? false : true}>
						<Row className="d-flex justify-content-evenly">
							<Search searchkey="itemcode">
								{this.state.items.map((item, index) => {
									return (
										<Col xs="12" sm="12" md="12" lg="3" xl="3" key={index} index={index} itemcode={item.itemCode}>
											<ItemCard
												addfunction={this.addItem}
												itemcode={item.itemCode}
												itemname={item.name}
												itemImage={item.image}
												index={index}
												key={index}
											/>
										</Col>
									);
								})}
							</Search>
						</Row>
					</div>
				</Form>
			</LoadingOverlay>
		);
	}
}

export default withTranslation()(AddItem);
