import React, { Component } from "react";
import NewPO from "../components/new_po";
import GetPermission from "purch/common/get_permission";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewPOScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}>
				<GetPermission perm="purch.add_purchaseorder">
					<div code="perm">
						<NewPO />
					</div>
				</GetPermission>
			</CSSTransitionGroup>
		);
	}
}

export default NewPOScene;
