import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Progress, Card } from "reactstrap";
import {withTranslation} from "react-i18next";

class InstallmentCard extends Component {
	render() {
		const { t } = this.props;

		return (
			<Fragment>
				<CardHeader className="mb-3 h-auto pl-0 pr-0 bg-transparent no-border">
					<div className="card-header-title fsize-2 text-capitalize text-dark font-weight-normal">
						{t("installments")}
					</div>
				</CardHeader>
				<Row>
					{this.props.installments.map((i, index) => {
						let colors = ["danger", "warning", "info", "success"];
						let color = 0;
						if (i.days > 30 && i.days <= 60) {
							color = 1;
						} else if (i.days > 60 && i.days < 90) {
							color = 2;
						} else if (i.days >= 90 && i.days < 365) color = 3;
						return (
							<Col key={index} md="6" lg="3">
								<Card key={index} className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
									<div className="widget-content p-0 w-100">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left pr-2 fsize-1">
													<div
														className={`widget-numbers mt-0 fsize-3 text-${colors[color]}`}>{`${i.percentage}%`}</div>
												</div>
												<div className="widget-content-right w-100">
													<Progress
														className="progress-bar-xs"
														color={colors[color]}
														value={Number(i.percentage)}
													/>
												</div>
											</div>
											<div className="widget-content-left fsize-1">
												<div>{`During ${i.days.toLocaleString()} days due at ${this.props.addDays(
													i.days
												)}`}</div>
											</div>
										</div>
									</div>
								</Card>
							</Col>
						);
					})}
				</Row>
			</Fragment>
		);
	}
}

export default withTranslation()(InstallmentCard);
