import Swal from "sweetalert2";

function PopUpWithInput(props) {
    return Swal.fire({
        icon: "warning",
        text: props.text,
        title: props.title,
        input: "text",
        inputLabel: props.label,
        inputPlaceholder: props.inputPlaceholder,
        inputValidator: (value) => {
            if (!value) {
                return 'This field is required'
            }
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: props.cancelbtntxt,
        confirmButtonText: props.confirmbtntxt,
        customClass: {
            icon: "icon-danger",
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-dark ml-3",
        },
    }).then((result) => {
        if (result.isConfirmed) {
            props.confirmedResult(result.value);
        }
    });
}
export default PopUpWithInput;
