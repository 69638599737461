import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import ReactToPrint from "react-to-print";
import PrintTransaction from "./print_transaction";
import LoadingOverlay from "react-loading-overlay";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { AlertNotification } from "../../common/alert_notification";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

/* A transactions report. The user must select a date range first then the data for that range is fetched into an exportable table */

class TransactionTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			startDate: "",
			endDate: "",
			loading: false,
			selectedData: [],
		};
	}

	startDateInput = (e) => {
		let startDate = e.target.value + " 00:00";
		this.setState({ startDate: startDate });
	};

	enDateInput = (e) => {
		let enDate = e.target.value + " 23:59";
		this.setState({ endDate: enDate });
		this.getData(enDate);
	};

	getData = (enDate) => {
		this.setState({ loading: true });
		if (!this.state.startDate) {
			AlertNotification("Please select a start date", "warning");
		} else {
			axios
				.get(
					`transactions/?serializer=purchaseTrx&inspectionTrx__delivery__purchaseOrderItem__isnull=false&startDate=` +
						this.state.startDate +
						`&enDate=` +
						enDate
				)
				.then((res) => {
					this.setState({ data: res.data, loading: false });
				})
				.catch(() => AlertNotification("Something went wrong, please contact the technical support team.", "error"));
		}
	};

	render() {
		const t = i18n.t;

		const headerFormatter = (column, colIndex) => <span>{t(column.text)}</span>;

		const columns = [
			{
				dataField: "catalogItemItemCode",
				text: "itemCode",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "catalogItemName",
				text: "itemName",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "catalogItemDescription",
				text: "itemDescription",
				sort: true,
				hidden: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "poNumber",
				text: "poNumber",
				sort: true,
				headerFormatter: headerFormatter,
				formatter: (cell, trx) => {
					return (
						<Link className="btn-link" to={{ pathname: `/viewpo/?id=${trx.po}` }} target="_blank">
							{cell}
						</Link>
					);
				},
			},
			{
				dataField: "vendor",
				text: "supplier",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "trxDate",
				text: "transactionDate",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "baseUnitQty",
				text: "transactionQuantity",
				sort: true,
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				dataField: "kind",
				text: "kind",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "code",
				text: "transactionCode",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "extendedCost",
				text: "cost",
				sort: true,
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				dataField: "displayUoM",
				text: "uom",
				sort: true,
				headerFormatter: headerFormatter,
			},
			{
				dataField: "warehouseName",
				text: "warehouse",
				sort: true,
				headerFormatter: headerFormatter,
			},
		];

		const selectRow = {
			mode: "checkbox",
			headerColumnStyle: { backgroundColor: "white" },
			onSelect: (row, isSelect, rowIndex, e) => {
				let selectedData = [...this.state.selectedData];
				if (isSelect) {
					selectedData.push(row);
				} else {
					selectedData = selectedData.filter((trx) => trx.id !== row.id);
				}
				this.setState({ selectedData: selectedData });
			},
			onSelectAll: (isSelect, rows, e) => {
				if (isSelect) {
					this.setState({ selectedData: rows });
				} else {
					this.setState({ selectedData: [] });
				}
			},
		};

		return (
			<Fragment>
				<LoadingOverlay
					tag="div"
					active={this.state.loading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col>
							<PageTitleAlt2 heading={t("transactions")} icon="pe-7s-bookmarks icon-gradient bg-happy-fisher" />
						</Col>
						<Col className="text-center">
							<GetPermission perm="inventory.view_transaction">
								<div code="perm">
									<ReactToPrint
										documentTitle={`${t("transaction")} ${this.state.startDate} - ${this.state.endDate}`}
										trigger={() => {
											// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
											// to the root node of the returned component as it will be overwritten.
											return (
												<Button className="float-right mt-2" color="dark" disabled={this.state.selectedData.length === 0}>
													{t("print")}
												</Button>
											);
										}}
										content={() => this.componentRef}
									/>
								</div>
							</GetPermission>
							<div style={{ display: "none" }}>
								<PrintTransaction
									data={this.state.selectedData}
									ref={(el) => (this.componentRef = el)}
									columns={columns}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<Row className="mb-3">
										<Col md={4}>
											<Label>{t("startDate")}</Label>
											<Input type="date" onChange={this.startDateInput} />
										</Col>
										<Col md={4}>
											<Label>{t("endDate")}</Label>
											<Input type="date" onChange={this.enDateInput} />
										</Col>
									</Row>
									<Row>
										<Col xs="12" sm="12" md="12" lg="12" xl="12">
											<TableWithPaginationSearch
												scrollable
												keyfield="code"
												search
												exportable
												columns={columns}
												data={this.state.data}
												filename="Transactions.csv"
												selectRow={selectRow}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withRouter(TransactionTable);
