/* Cards that need to be searched should be wraped by this component. Provide the search key that the component will use to check the value of the card against the value of the searching input */

import React, { Fragment } from "react";
import { Col, Input } from "reactstrap";
import { withTranslation } from "react-i18next";

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			elements: [],
		};
	}

	search = (children, value) => {
		let newElements = React.Children.map(children, (child) => {
			let childProps = { hidden: false };
			if (value && !child.props.itemcode.toLowerCase().includes(value.toLowerCase())) {
				childProps = { hidden: true };
			}
			return React.cloneElement(child, childProps);
		});
		this.setState({ elements: newElements });
	};

	componentDidMount() {
		this.setState({ elements: this.props.children });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.children.length !== this.props.children.length && prevState.value === this.state.value) {
			this.setState({ elements: this.props.children });
		}
	}

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<Input
						className="mb-3"
						placeholder={t("searchField")}
						style={{ width: "200px" }}
						onChange={(e) => {
							this.setState({ value: e.target.value });
							this.search(this.props.children, e.target.value);
						}}
					/>
				</Col>
				{this.state.elements}
			</Fragment>
		);
	}
}
export default withTranslation()(Search);
