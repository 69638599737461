import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { inventoryAxios } from "axios_interceptors";

const SearchItem = (props) => {
  const [state, setState] = useState({ searchValue: "" });
  const search = () => {
    inventoryAxios.get(`item?search=${state.searchValue}${props.params}`).then((res) => {
      if (res) {
        props.callback(res);
      }
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <Input
        placeholder="Search for an item"
        onChange={(e) => {
          setState({ searchValue: e.target.value });
        }}
      />
      <Button color="dark" onClick={search} className="ml-2">
        Search
      </Button>
    </div>
  );
};
export default SearchItem;
