import React from "react";
import Login from "../components/login";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class LoginScene extends React.Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<Login />
			</CSSTransitionGroup>
		);
	}
}

export default LoginScene;
