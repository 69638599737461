import React, {Component} from "react";

class ExportIcon extends Component {
	render() {
		return (
			<i
				hidden={this.props.hidden}
				onClick={this.props.onclick}
				className={`bi bi-box-arrow-right info-hover ${this.props.cssclass}`}
				style={{fontSize: this.props.iconsize || "1.5rem", cursor: "pointer"}}
			/>
		);
	}
}

export default ExportIcon;
