import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Transactions from "../components/view_transactions";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ViewTransactionScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}>
				<Transactions />
			</CSSTransitionGroup>
		);
	}
}
export default withRouter(ViewTransactionScene);
