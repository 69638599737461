import axios from "axios";
import moment from "moment";
import PrintPO from "./print_po";
import Approval from "./approvals";
import { Loader } from "react-loaders";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InstallmentCard from "./installment_card";
import DeleteIcon from "purch/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { PurchaseOrderDetails } from "./purchase_order_details";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Card, CardBody, CardHeader, Col, Row, Input, CardFooter, Button, Form } from "reactstrap";

class ViewPurchaseOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentTerm: "",
			editable: false,
			formLoading: false,
			purchaseOrder: null,
			approvalLoading: false,
		};
	}

	componentDidMount() {
		let poID = this.props.poid;
		if (!poID) poID = new URLSearchParams(this.props.location.search).get("id");
		this.getPO(poID);
	}

	getPO = (poID) => {
		axios
			.get(`po/${poID}/?serializer=poItems`)
			.then((res) => {
				this.setState({ purchaseOrder: res.data });
				this.setPaymentTerm();
				this.calculateTaxesValues();
			})
			.catch((error) => {
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	calculateTaxesValues = () => {
		let purchaseOrder = this.state.purchaseOrder;
		const taxValue = (purchaseOrder["totalCostBeforeTax"] * purchaseOrder["tax"]) / 100;
		let withHoldingTaxValue = 0;
		purchaseOrder.poItems.forEach((item) => {
			withHoldingTaxValue += (item.totalCostBeforeTax * item.withHoldingTax) / 100;
		});
		purchaseOrder["taxValue"] = taxValue;
		purchaseOrder["withHoldingTaxValue"] = withHoldingTaxValue;
		this.setState({ purchaseOrder });
	};

	getPOItemValues = (e) => {
		let po = this.state.purchaseOrder,
			items = po.poItems;
		items[e.target.getAttribute("index")][e.target.name] = e.target.value;
		this.setState({ purchaseOrder: po });
	};

	updatePOItems = (e) => {
		e.preventDefault();
		this.setState({ formLoading: true });
		axios
			.put(`poitem/bulkupdate/`, this.state.purchaseOrder.poItems)
			.then((res) => {
				if (res) {
					AlertNotification("Updated Successfully", "success");
					this.getPO(this.state.purchaseOrder.id);
					this.setState({ formLoading: false });
				}
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	deletePOItem = (e) => {
		let po = { ...this.state.purchaseOrder };
		let arr = [...this.state.purchaseOrder.poItems];
		let index = e.target.getAttribute("index");
		this.setState({ formLoading: true });
		axios
			.delete(`poitem/${arr[index].id}/`)
			.then((res) => {
				if (res) {
					arr.splice(index, 1);
					po.poItems = arr;
					this.setState({ purchaseOrder: po, formLoading: false });
				}
			})
			.catch((error) => {
				this.setState({ formLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	updatePOApprovalAPI = (index, approved, status, rejectionReason = null) => {
		let poApproval = this.state.purchaseOrder.poApproval;
		this.setState({ approvalLoading: true });
		axios
			.patch(`poApproval/${poApproval[index].id}/`, { approved: approved })
			.then((res) => {
				poApproval[index] = res.data;
				// Only update po status if this is last po approval or po approval rejected
				// eslint-disable-next-line
				if (index == poApproval.length - 1 || status === "Rejected") this.updatePOAPI(status, rejectionReason);
				// If po approval is approved after rejection then make po pending
				else if (this.state.purchaseOrder.status === "Rejected" && status === "Approved")
					this.updatePOAPI("Pending", rejectionReason);

				this.setState({
					editable: false,
					poApproval: poApproval,
					approvalLoading: false,
				});
			})
			.catch((error) => {
				this.setState({ approvalLoading: false });
				if (error.response) AlertNotification(error.response.data.split(".")[0], "error", error.response.status);
				else AlertNotification(null, "error");
			});
	};

	updatePOAPI = (status, rejectionReason = null) => {
		let purchaseOrder = this.state.purchaseOrder;
		purchaseOrder.status = status;
		purchaseOrder.rejectionReason = rejectionReason;
		this.setState({ purchaseOrder: purchaseOrder });
	};

	setApproval = (event) => {
		let index = event.target.getAttribute("index");
		let approved = event.target.getAttribute("approved");
		let status = event.target.getAttribute("name");
		this.updatePOApprovalAPI(index, approved, status);
	};

	setRejection = (data, rejectionReason) => this.updatePOApprovalAPI(data.index, data.approved, data.status, rejectionReason);

	getStatusColor = (status) => {
		if (status === "Pending") return "bg-warning";
		if (status === "Approved") return "bg-info";
		if (status === "Rejected") return "bg-danger";
	};

	setPaymentTerm = () => {
		if (this.state.purchaseOrder.paymentTerm === "Cash" || this.state.purchaseOrder.paymentTerm === "Installment")
			return this.setState({
				paymentTerm: this.state.purchaseOrder.paymentTerm,
			});
		return this.setState({
			paymentTerm: `${this.state.purchaseOrder.paymentTerm} days`,
		});
	};

	addDaysToPODate = (numberOfDays) => {
		let poDate = this.state.purchaseOrder.creationDate;
		let date = new moment(poDate.slice(0, 10), "DD-MM-YYYY");
		date.add(numberOfDays, "days");
		return date.format("DD-MM-YYYY");
	};

	render() {
		const { t } = this.props;

		const headerFormatter = (column) => <div style={{ width: "180px", minWidth: "fit-content" }}>{t(column.text)}</div>;

		const itemsColumns = [
			{
				hidden: true,
				text: "itemCode",
				dataField: "catalogItem.itemCode",
				headerFormatter: headerFormatter,
			},
			{
				hidden: false,
				text: "itemName",
				dataField: "catalogItem.name",
				headerFormatter: headerFormatter,
			},
			{
				hidden: false,
				text: "condition",
				dataField: "purchaseRequestItem.condition",
				headerFormatter: headerFormatter,
			},
			{
				hidden: false,
				text: "purchaseRequest",
				dataField: "purchaseRequestItem.purchaseRequest",
				headerFormatter: headerFormatter,
			},
			{
				hidden: false,
				text: "quantity",
				dataField: "quantity",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<div>
							{cell + " " + row.uom.abbreviation}
							<Input
								type="number"
								index={rowIndex}
								className="mr-1"
								defaultValue={0}
								name="decreaseNumber"
								min="0"
								onChange={this.getPOItemValues}
								max={Number(row.quantity) - Number(row.purchasedQuantity)}
							/>
						</div>
					);
				},
			},
			{
				hidden: false,
				text: "purchasedQuantity",
				dataField: "purchasedQuantity",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "receiveQuantity",
				dataField: "updatedQuantity",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							min={0}
							type="number"
							index={rowIndex}
							name="updatedQuantity"
							className="text-center"
							onBlur={this.getPOItemValues}
							defaultValue={cell ? cell : ""}
							max={row.quantity - row.purchasedQuantity}
							disabled={this.state.purchaseOrder.status === "Pending"}
						/>
					);
				},
			},
			{
				hidden: false,
				text: "unitPrice",
				dataField: "unitPrice",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "discount",
				dataField: "discount",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "unitPriceAfterDiscount",
				dataField: "unitPriceAfterDiscount",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "totalCostBeforeTax",
				dataField: "totalCostBeforeTax",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "withHolding",
				dataField: "withHoldingTax",
				headerFormatter: headerFormatter,
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
			},
			{
				hidden: false,
				text: "note",
				dataField: "note",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<Input
							name="note"
							type="textarea"
							rows={1}
							index={rowIndex}
							className="text-center"
							onBlur={this.getPOItemValues}
							defaultValue={cell ? cell : ""}
						/>
					);
				},
			},
			{
				hidden: false,
				text: "actions",
				dataField: "",
				headerFormatter: headerFormatter,
				formatter: (cell, row, rowIndex) => {
					return (
						<Fragment>
							<GetPermission perm="purch.delete_purchaseorderitem">
								<div code="perm">
									<DeleteIcon index={rowIndex} ondelete={this.deletePOItem} hidden={Number(row.purchasedQuantity) > 0} />
								</div>
							</GetPermission>
						</Fragment>
					);
				},
			},
		];

		return (
			<Form onSubmit={this.updatePOItems}>
				{this.state.purchaseOrder && (
					<Fragment>
						<Row>
							<Col>
								<PageTitleAlt2 heading={t("purchaseOrder")} icon="bi bi-bag icon-gradient bg-happy-fisher" />
							</Col>
							<Col className="text-center">
								<GetPermission perm="purch.change_purchaseorder">
									<div code="perm">
										<ReactToPrint
											documentTitle={this.state.purchaseOrder.code}
											trigger={() => {
												// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
												// to the root node of the returned component as it will be overwritten.
												return (
													<Button className="float-right mt-2" color="dark">
														{t("print")}
													</Button>
												);
											}}
											content={() => this.componentRef}
										/>
									</div>
								</GetPermission>
								<div style={{ display: "none" }}>
									<PrintPO
										purchaseOrder={this.state.purchaseOrder}
										ref={(el) => (this.componentRef = el)}
										addDaysToPODate={this.addDaysToPODate}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col md="6" xl="7">
								<div className={`card mb-3 widget-content ${this.getStatusColor(this.state.purchaseOrder.status)}`}>
									<div className="widget-content-wrapper text-white">
										<div className="widget-content-left">
											<div className="widget-heading">{t("status")}</div>
										</div>
										<div className="widget-content-right">
											<div className="widget-numbers text-white">{this.state.purchaseOrder.status}</div>
										</div>
									</div>
								</div>
							</Col>
							{this.state.purchaseOrder.rejectionReason && (
								<Col md="6" xl="5">
									<div className="card mb-3 widget-content bg-dark">
										<div className="widget-content-wrapper text-white">
											<div className="widget-content-left">
												<div className="widget-heading text-danger">{t("rejectionReason")}</div>
												<div>{this.state.purchaseOrder.rejectionReason}</div>
											</div>
										</div>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col xs="12" sm="12" md="7" lg="7" xl="7">
								<PurchaseOrderDetails podata={this.state.purchaseOrder} paymentTerm={this.state.paymentTerm} />
							</Col>
							<Col xs="12" sm="12" md="5" lg="5" xl="5">
								<Approval
									setApproval={this.setApproval}
									setRejection={this.setRejection}
									approvalLoading={this.state.approvalLoading}
									poStatus={this.state.purchaseOrder.status}
									poApprovals={this.state.purchaseOrder.poApproval}
								/>
							</Col>
							<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.state.purchaseOrder.installment.length === 0}>
								<InstallmentCard installments={this.state.purchaseOrder.installment} addDays={this.addDaysToPODate} />
							</Col>
						</Row>
						<Row>
							<Col>
								<LoadingOverlay
									tag="div"
									active={this.state.formLoading}
									styles={{
										overlay: (base) => ({
											...base,
											background: "#fff",
											opacity: 0.5,
										}),
									}}
									spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
									<Card>
										<CardHeader className="card-header-tab">
											<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
												<i className="header-icon bi bi-layers mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher"> </i>
												{t("items")}
											</div>
										</CardHeader>
										<CardBody>
											<TableWithPaginationSearch scrollable columns={itemsColumns} data={this.state.purchaseOrder.poItems} />
										</CardBody>
										<CardFooter className="d-flex justify-content-end">
											<GetPermission perm="purch.change_purchaseorder">
												<Button code="perm" color="info" type="submit">
													{t("submit")}
												</Button>
											</GetPermission>
										</CardFooter>
									</Card>
								</LoadingOverlay>
							</Col>
						</Row>
					</Fragment>
				)}
			</Form>
		);
	}
}

export default withTranslation()(withRouter(ViewPurchaseOrder));
