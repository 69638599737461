import i18n from "i18n";
import React, { Component } from "react";
import CustomSelect from "purch/common/custom_select";
import GetPermission from "purch/common/get_permission";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class SupplierForm extends Component {
	render() {
		const t = i18n.t;
		const {
			cancelBtn,
			cancel,
			submitBtnName,
			validate,
			submit,
			getValue,
			supplier,
			perm,
			taxes,
			getSelectTax,
			paymentTerms,
			getSelectPaymentTerm,
		} = this.props;
		return (
			<Card className="main-card mb-3" key="0">
				<CardBody>
					<Form id="supplier-form" onInvalid={validate} onSubmit={submit}>
						<Row>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("code")}</Label>
									<Input required onChange={getValue} type="text" name="code" invalid={supplier["invalidCode"]} value={supplier["code"] || ""} />
								</FormGroup>
							</Col>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("name")}</Label>
									<Input required onChange={getValue} type="text" name="name" invalid={supplier["invalidName"]} value={supplier["name"] || ""} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("email")}</Label>
									<Input type="email" name="email" onChange={getValue} value={supplier["email"] || ""} />
								</FormGroup>
							</Col>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("address")}</Label>
									<Input type="text" name="address" onChange={getValue} value={supplier["address"] || ""} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("phoneNumber")}</Label>
									<Input type="number" name="phoneNumber" onChange={getValue} value={supplier["phoneNumber"] || ""} />
								</FormGroup>
							</Col>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("mobileNumber")}</Label>
									<Input type="number" name="mobileNumber" onChange={getValue} value={supplier["mobileNumber"] || ""} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<FormGroup className="d-flex ml-4">
									<Label>{t("hasTax")}</Label>
									<Input
										type="checkbox"
										defaultChecked={supplier["hasTax"] !== undefined ? supplier["hasTax"] : true}
										name="hasTax"
										onClick={getValue}
									/>
								</FormGroup>
							</Col>
							<Col xs="12" md="6">
								<FormGroup className="d-flex ml-4">
									<Label>{t("hasWithHolding")}</Label>
									<Input
										type="checkbox"
										defaultChecked={supplier["hasWithHolding"] !== undefined ? supplier["hasWithHolding"] : true}
										name="hasWithHolding"
										onClick={getValue}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("paymentTerm")}</Label>
									<CustomSelect
										required
										name="paymentTerm"
										onChange={getSelectPaymentTerm}
										options={paymentTerms}
										optionlable="paymentTerm"
										inValid={supplier["invalidPaymentTerm"]}
										defaultValue={supplier["paymentTermObj"] || null}
									/>
								</FormGroup>
							</Col>
							<Col xs="12" md="6">
								<FormGroup>
									<Label>{t("tax")}</Label>
									<CustomSelect
										name="tax"
										inValid={supplier["invalidTax"]}
										onChange={getSelectTax}
										options={taxes}
										optionlable="VAT"
										defaultValue={supplier["taxObj"] || null}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</CardBody>
				<CardFooter className="d-flex justify-content-end">
					{cancelBtn && (
						<Button code="perm" color="dark" onClick={cancel} className="float-right mx-2">
							{t("cancel")}
						</Button>
					)}
					<GetPermission perm={perm}>
						<Button form="supplier-form" code="perm" type="submit" color="info">
							{t(submitBtnName || "submit")}
						</Button>
					</GetPermission>
				</CardFooter>
			</Card>
		);
	}
}
export default SupplierForm;
