import React, {Component} from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import GetPermission from "../../common/get_permission";
import NewApprovalNew from "../components/new_approval_entity";

class NewApprovalNewScene extends Component {
    render() {
        return (
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <GetPermission perm="purch.add_prapprovalsentity">
                    <div code="perm">
                        <NewApprovalNew />
                    </div>
                </GetPermission>
            </CSSTransitionGroup>
        );
    }
}

export default NewApprovalNewScene;
