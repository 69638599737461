/* This component checks at the backend for a specific permission passed to it as a prop named "perm".
The API returns true if the user does not have a permission and false if it has then this.state.permission
is updated with the result of the backend call. If permission is true the buttons responsible for post operations are hidden because the user does not have a permission to create, update or delete.
To use this component wrape your view as a child for it, make sure your submit buttons have prop named code='perm' and pass the permission you want to apply. */

import React from "react";
import { connect } from "react-redux";
import { authAxios } from "../../axios_interceptors";
import { AlertNotification } from "./alert_notification";
import { setUserPermissions } from "../../reducers/ThemeOptions";

class GetPermission extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			permission: true,
		};
	}

	withPermission = (children) => {
		return React.Children.map(children, (child) => {
			let childProps = {};
			if (typeof child !== "object") return child;
			if (child.props.code === "perm") {
				childProps = { hidden: this.state.permission };
			}
			childProps.children = this.withPermission(child.props.children);
			return React.cloneElement(child, childProps);
		});
	};

	setPermission = () => {
		this.setState({ permission: !this.props.allUserPermissions[this.props.perm] });
	};

	getUserPerms = () => {
		authAxios
			.get(`checkperm`)
			.then((res) => {
				if (res) this.props.dispatch(setUserPermissions(res.data.data));
			})
			.then(() => {
				return this.setState({ permission: !this.props.allUserPermissions[this.props.perm] });
			})
			.catch((err) => {
				AlertNotification("Can not get permission, please contact the IT team.", "error");
				console.error(err.response.data);
			});
	};

	componentDidMount() {
		if (this.props.allUserPermissions.length === 0) {
			this.getUserPerms();
		} else {
			this.setPermission();
		}
	}

	componentDidUpdate(prev) {
		if (prev.perm !== this.props.perm) this.setPermission();
	}

	render() {
		return this.withPermission(this.props.children);
	}
}
export default connect(({ ThemeOptions }) => {
	return { allUserPermissions: ThemeOptions.permissions };
})(GetPermission);
