import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import TableWithPaginationSearch from "purch/common/table_with_pagination_search";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import i18n from "../../../i18n";

class PrintPO extends Component {
	render() {
		const nullChecker = (cell) => (!cell ? "-" : cell);
		const itemsColumns = [
			{
				dataField: "id",
				text: "ID",
				hidden: true,
			},
			{
				dataField: "catalogItem.itemCode",
				text: i18n.t("itemCode"),
				hidden: true,
				formatter: nullChecker,
			},
			{
				hidden: false,
				text: i18n.t("itemName"),
				dataField: "catalogItem.name",
			},
			{
				dataField: "purchaseRequestItem.condition",
				text: i18n.t("condition"),
				formatter: nullChecker,
			},
			{
				dataField: "quantity",
				text: i18n.t("quantity"),
				formatter: (cell, row) => <div>{Number(cell).toLocaleString("en") + row.uom.abbreviation}</div>,
			},
			{
				dataField: "unitPrice",
				text: i18n.t("unitPrice"),
				formatter: (cell) => <div> {Number(cell).toLocaleString("en")}</div>,
			},
			{
				dataField: "discount",
				text: i18n.t("discount"),
				formatter: (cell) => <div> {Number(cell).toLocaleString("en")}</div>,
			},
			{
				dataField: "unitPriceAfterDiscount",
				text: i18n.t("unitPriceAfterDiscount"),
				formatter: (cell) => <div> {Number(cell).toLocaleString("en")}</div>,
			},
			{
				dataField: "totalCostBeforeTax",
				text: i18n.t("total"),
				formatter: (cell) => <div> {Number(cell).toLocaleString("en")}</div>,
			},
			{
				dataField: "withHoldingTax",
				text: i18n.t("withHolding"),
				formatter: (cell) => <div> {Number(cell).toLocaleString("en")}</div>,
			},
			{
				dataField: "note",
				text: i18n.t("note"),
				formatter: nullChecker,
			},
		];
		return (
			<Fragment>
				{this.props.purchaseOrder && (
					<div className="mt-3">
						<PageTitleAlt2
							heading={
								i18n.language === "en"
									? i18n.t("purchaseOrder") + ` ${this.props.purchaseOrder.code}`
									: `${this.props.purchaseOrder.code} ` + i18n.t("purchaseOrder")
							}
						/>

						<Row>
							<Col>
								<div className="widget-content pr-0 pt-0 pb-0">
									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">{i18n.t("supplier")}:</div>
										</div>
										<div className="widget-content-left ml-2">
											<div>{this.props.purchaseOrder.supplier.name}</div>
										</div>
									</div>
									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">{i18n.t("poData")}:</div>
										</div>
										<div className="widget-content-left ml-2">
											<div>{this.props.purchaseOrder.creationDate}</div>
										</div>
									</div>
									{this.props.purchaseOrder.offerNumber && (
										<div className="widget-content-wrapper">
											<div className="widget-content-left">
												<div className="widget-heading">{i18n.t("accordingToOfferNumber")}:</div>
											</div>
											<div className="widget-content-left ml-2">
												<div>{this.props.purchaseOrder.offerNumber}</div>
											</div>
											<div className="widget-content-left ml-3">
												<div className="widget-heading">{i18n.t("onDate")}:</div>
											</div>
											<div className="widget-content-left ml-2">
												<div>{this.props.purchaseOrder.offerDate}</div>
											</div>
										</div>
									)}

									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">{i18n.t("supplyRepairMessage")}:</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<TableWithPaginationSearch columnToggle={false} columns={itemsColumns} data={this.props.purchaseOrder.poItems} />
							</Col>
						</Row>

						<Row>
							<Col xs="7" sm="7" md="7" lg="7" xl="7"></Col>
							<Col xs="5" sm="5" md="5" lg="5" xl="5">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-subheading">{i18n.t("subTotal")}</div>
												</div>
												<div className="widget-content-right">
													<div className="widget-heading">{Number(this.props.purchaseOrder.totalCostBeforeTax).toLocaleString("en")}</div>
												</div>
											</div>
										</div>
									</ListGroupItem>

									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-subheading">{i18n.t("taxValue")}</div>
												</div>
												<div className="widget-content-right">
													<div className="widget-heading">{this.props.purchaseOrder.taxValue}</div>
												</div>
											</div>
										</div>
									</ListGroupItem>

									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-subheading">{i18n.t("withHoldingTaxValue")}</div>
												</div>
												<div className="widget-content-right">
													<div className="widget-heading">{this.props.purchaseOrder.withHoldingTaxValue}</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-subheading">{i18n.t("deliveryFees")}</div>
												</div>
												<div className="widget-content-right">
													<div className="widget-heading">{this.props.purchaseOrder.deliveryFees}</div>
												</div>
											</div>
										</div>
									</ListGroupItem>

									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-subheading font-weight-bold">{i18n.t("total")}</div>
												</div>
												<div className="widget-content-right">
													<div className="font-weight-bold">{Number(this.props.purchaseOrder.totalCostAfterTax).toLocaleString("en")}</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</Col>
						</Row>

						<Row>
							<Col className="mr-5 ml-5 mt-2">
								<hr />
							</Col>
						</Row>

						<Row className="text-center mt-2">
							{this.props.purchaseOrder.poApproval.map((poApproval, index) => {
								return (
									<Col key={index}>
										<div className="card no-shadow rm-border bg-transparent widget-chart text-left">
											<div className="widget-chart-content">
												<div className="widget-subheading" style={{ fontSize: "12px" }}>
													{poApproval.poApprovalEntity.jobTitle}
												</div>
												<div style={{ fontSize: "12px" }}>{poApproval.approvalUser ? poApproval.approvalUser.username : "....."}</div>
												<div style={{ fontSize: "8px" }}>{poApproval.approvalUser ? poApproval.approvalUserSignature : "....."}</div>
											</div>
										</div>
										<div className="divider m-0 d-md-none d-sm-block" />
									</Col>
								);
							})}
							<Col>
								<div className="card no-shadow rm-border bg-transparent widget-chart text-left">
									<div className="widget-chart-content">
										<div className="widget-subheading" style={{ fontSize: "12px" }}>
											{i18n.t("poCreator")}
										</div>
										<div style={{ fontSize: "12px" }}>{this.props.purchaseOrder.issuingUser}</div>
									</div>
								</div>
								<div className="divider mt-2 m-0 d-md-none d-sm-block" />
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="widget-content pr-0 pb-0 mt-3">
									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">{i18n.t("TermsOfSupply")}:</div>
										</div>
									</div>
									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">{i18n.t("paymentTerm")}:</div>
										</div>
										<div className="widget-content-left ml-2">
											<div>{this.props.purchaseOrder.paymentTerm}</div>
										</div>
									</div>
									{this.props.purchaseOrder.paymentTerm === "Installment" && (
										<Fragment>
											{this.props.purchaseOrder.installment.map((inst) => (
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{i18n.t("pay")}:</div>
													</div>
													<div className="widget-content-left ml-2">
														<div>{inst.percentage}%</div>
													</div>
													<div className="widget-content-left ml-3">
														<div className="widget-heading">{i18n.t("after")}:</div>
													</div>
													<div className="widget-content-left ml-2">
														<div>{inst.days + " " + i18n.t("days")}</div>
													</div>
													<div className="widget-content-left ml-3">
														<div className="widget-heading">{i18n.t("due")}:</div>
													</div>
													<div className="widget-content-left ml-2">
														<div>{this.props.addDaysToPODate(inst.days)}</div>
													</div>
												</div>
											))}
										</Fragment>
									)}
									<div className="widget-content-wrapper">
										<div className="widget-content-left">
											<div className="widget-heading">
												{this.props.purchaseOrder.isDeliveredBySupplier && <span>{i18n.t("deliverToOurCompany")}</span>}
												{!this.props.purchaseOrder.isDeliveredBySupplier && <span>{i18n.t("pickUpFromSupplier")}</span>}
											</div>
										</div>
									</div>
									<div className="widget-content-wrapper mt-3">
										<div className="widget-content-left">
											<div className="">{i18n.t("policyMessage")}</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</Fragment>
		);
	}
}

export default PrintPO;
