import i18n from "i18n";
import React, { Component } from "react";
import GetPermission from "purch/common/get_permission";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

class SupplierCard extends Component {
	constructor() {
		super();
		this.state = {};
	}
	render() {
		const t = i18n.t;
		const { confirmDeleteSupplier, supplier, editSupplier } = this.props;
		const { deletable, code, name, address, email, phoneNumber, mobileNumber, paymentTerm, tax__vat, tax__withHolding } = this.props.supplier;
		return (
			<>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab d-flex justify-content-between">
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
							<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
							{t("supplierData")}
						</div>
					</CardHeader>
					<Row className="no-gutters">
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left ">
														<div className="widget-heading  ">{t("supplierCode")}</div>
														<div className="widget-heading text-info">{code}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left ">
														<div className="widget-heading ">{t("name")}</div>
														<div className="widget-heading text-info">{name}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left ">
														<div className="widget-heading ">{t("address")}</div>
														<div className="widget-heading text-info">{address || t("N/A")}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("email")}</div>
														<div className="widget-heading text-info">{email || t("N/A")}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("phoneNumber")}</div>
														<div className="widget-heading text-info">{phoneNumber || t("N/A")}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("mobileNumber")}</div>
														<div className="widget-heading text-info">{mobileNumber || t("N/A")}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("paymentTerm")}</div>
														<div className="widget-heading text-info">{paymentTerm}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("vat")}</div>
														<div className="widget-heading text-info">{tax__vat || t("N/A")}%</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("withHolding")}</div>
														<div className="widget-heading text-info">{tax__withHolding || t("N/A")}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
					</Row>
					<CardFooter className="d-flex justify-content-end">
						<GetPermission perm="purch.change_supplier">
							<Button color="info" onClick={editSupplier}>
								{t("edit")}
							</Button>
						</GetPermission>
						{deletable && (
							<GetPermission perm="purch.delete_supplier">
								<Button className="ml-2" color="danger" onClick={() => confirmDeleteSupplier(supplier)}>
									{t("delete")}
								</Button>
							</GetPermission>
						)}
					</CardFooter>
				</Card>
			</>
		);
	}
}

export default SupplierCard;
