import React, {Component} from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ViewPurchaseOrder from "../components/view_purchase_order.js";

class ViewPOScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<ViewPurchaseOrder poid={this.props.history.location.poID} />
			</CSSTransitionGroup>
		);
	}
}

export default ViewPOScene;
