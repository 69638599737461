import React, {Component, Fragment} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import CustomSelect from "../../common/custom_select";
import PageTitleAlt2 from "../../common/PageTitleAlt2";

class NewApprovalNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFormValid: true,
            editMode: false,
            departments: [],
            users: [],
            prApprovalCycles: [],
        }

    }


    onInvalidForm = () => {

    }

    onSubmit = () => {

    }

    handleOnSelectPRApprovalCycle = () => {

    }

    handleOnSelectDepartment = () => {

    }

    handleOnSelectUser = () => {

    }

    render() {
        const {t} = this.props;

        return (
            <Fragment>
                <PageTitleAlt2 heading={t('newPRApprovalEntity')} icon="bi bi-patch-check icon-gradient bg-happy-fisher"/>

                <Row>
                    <Col md="12" lg="12">
                        <Card className="mt-3">
                            <CardBody>
                                <Form onInvalid={this.onInvalidForm} onSubmit={this.onSubmit}>
                                    <Row className="mt-2">
                                        <Col>
                                            <FormGroup>
                                                <Label>{t("title")}</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.title}
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="prType">{t("prApprovalCycle")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectPRApprovalCycle}
                                                    label="PrType"
                                                    options={this.state.prApprovalCycles}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="PrType"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="department">{t("approvedDepartment")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectDepartment}
                                                    label="Department"
                                                    options={this.state.departments}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="Department"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="urgency">{t("approvedUser")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectUser}
                                                    label="Urgency"
                                                    options={this.state.users}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="Urgency"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label>{t("sortOrder")}</Label>
                                                <Input
                                                    type="number"
                                                    defaultValue={this.state.sortOrder}
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="info"
                                            hidden={this.state.editMode}
                                            className="float-right">Submit</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withTranslation()(withRouter(NewApprovalNew));
