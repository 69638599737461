import { getCookie } from "cookies";
import { Loader } from "react-loaders";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import PopUpWithInput from "../../common/popup_with_input";
import { Card, ListGroup, ListGroupItem, CardHeader, Button } from "reactstrap";

class Approval extends Component {
	rejectionReason = () => {
		PopUpWithInput({
			title: "Please enter rejection reason",
			cancelbtntxt: "Exit",
			confirmbtntxt: "Reject",
			confirmedresult: () => this.this.props.setApproval,
		});
	};

	render() {
		const { t } = this.props;

		return (
			<LoadingOverlay
				tag="div"
				active={this.props.approvalLoading}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab">
						<i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher"> </i>
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">{t("approvals")}</div>
					</CardHeader>
					<ListGroup className="todo-list-wrapper" flush>
						{this.props.poApprovals.map((poApproval, index) => {
							let colors = { Approved: "info", Rejected: "danger", Pending: "warning" };
							let status = poApproval.approvalUser ? (poApproval.approved ? "Approved" : "Rejected") : "Pending";
							let previousEntity = poApproval.sortOrder > 1 ? !this.props.poApprovals[index - 1].approved : false;
							return (
								<ListGroupItem key={index}>
									<div className={`todo-indicator bg-${colors[status]}`} />
									<div className="widget-content p-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left mr-2"></div>
											<div className="widget-content-left">
												<div className="widget-heading">
													{poApproval.poApprovalEntity.jobTitle}
													<div className={`badge badge-${colors[status]} ml-2`}>{status}</div>
												</div>
												<div className="widget-subheading">
													<i>{poApproval.approvalUser ? poApproval.approvalUser.username : "....."}</i>
												</div>
											</div>
											<div
												className="widget-content-right"
												hidden={
													!poApproval.poApprovalEntity.approvalUser.includes(Number(getCookie("userId"))) || previousEntity
												}>
												<div hidden={poApproval.approved}>
													<Button index={index} color="info" approved="true" name="Approved" onClick={this.props.setApproval}>
														{t("approve")}
													</Button>
													<Button
														index={index}
														color="danger"
														approved="false"
														name="Rejected"
														hidden={this.props.poStatus === "Rejected"}
														className="ml-1"
														onClick={(event) => {
															let buttonData = {};
															buttonData["index"] = index;
															buttonData["approved"] = false;
															buttonData["status"] = "Rejected";
															PopUpWithInput({
																title: "Please enter rejection reason",
																cancelbtntxt: t("cancel"),
																confirmbtntxt: t("reject"),
																inputPlaceholder: t("rejectionReasonInput"),
																confirmedResult: (value) => this.props.setRejection(buttonData, value),
															});
														}}>
														{t("reject")}
													</Button>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							);
						})}
					</ListGroup>
				</Card>
			</LoadingOverlay>
		);
	}
}

export default withTranslation()(Approval);
