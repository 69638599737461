import i18n from "i18n";
import { getCookie } from "cookies";
import AppHeader from "../../Layout/AppHeader";
import LocaleContext from "../../LocaleContext";
import AppSidebar from "../../Layout/AppSidebar/";
import LoginScene from "../../login/scenes/login";
import React, { useState, Suspense } from "react";
import HomeScene from "../../purch/home/scenes/home";
import LoginIndicator from "purch/common/login_indicator";
import ViewPOScene from "purch/purchase_order/scenes/view_po";
import { Route, Redirect, withRouter } from "react-router-dom";
import NewSupplierScene from "purch/supplier/scenes/new_supplier";
import DataStudioRaw from "purch/reports/components/ds_pr_po_raw";
import NewPOScene from "../../purch/purchase_order/scenes/new_po";
import ReceivingScene from "purch/receiving/scenes/receiving_scene";
import ListPOScene from "../../purch/purchase_order/scenes/list_pos";
import ListPRScene from "../../purch/purchase_request/scenes/list_prs";
import ListSupplierScene from "../../purch/supplier/scenes/list_supplier";
import ViewSupplierScene from "../../purch/supplier/scenes/view_supplier";
import GPExportedPOScene from "purch/export_po_macro/scenes/gp_exported_po";
import GPExportPOScene from "../../purch/export_po_macro/scenes/gp_export_po";
import ViewTransactionScene from "../../purch/transaction/scenes/view_transaction";
import ListPRApprovalScene from "../../purch/pr_approval_setting/scenes/list_approvals";
import NewApprovalNewScene from "../../purch/pr_approval_setting/scenes/new_approval_entity";
import NewPurchaseRequestScene from "../../purch/purchase_request/scenes/new_purchase_request";
import ViewPurchaseRequestScene from "../../purch/purchase_request/scenes/view_purchase_request";
import NewApprovalCycleScene from "../../purch/pr_approval_setting/scenes/new_pr_approval_cycle";
import ViewPRApprovalCycleScene from "../../purch/pr_approval_setting/scenes/view_approval_cycle";

const AppMain = (props) => {
	const cookie = getCookie("toki");
	const [locale, setLocale] = useState(i18n.language);
	i18n.on("languageChanged", () => setLocale(i18n.language));
	const url = new URLSearchParams(props.location.search).get("redirect");

	if (cookie && url) props.history.push("/home");

	if (cookie) {
		return (
			<LocaleContext.Provider value={{ locale, setLocale }}>
				<Suspense fallback={<LoginIndicator />}>
					<AppHeader />
					<div className="app-main">
						<AppSidebar />
						<div className="app-main__outer">
							<div className="app-main__inner">
								<Route path="/home" component={HomeScene} />
								<Route path="/login" component={LoginScene} />
								<Route path="/newpo" component={NewPOScene} />
								<Route path="/listpr" component={ListPRScene} />
								<Route path="/listpo" component={ListPOScene} />
								<Route path="/viewpo" component={ViewPOScene} />
								<Route path="/receiving" component={ReceivingScene} />
								<Route path="/ds-pr-po-raw" component={DataStudioRaw} />
								<Route path="/gpExportPO" component={GPExportPOScene} />
								<Route path="/new-supplier" component={NewSupplierScene} />
								<Route path="/newpr" component={NewPurchaseRequestScene} />
								<Route path="/gpexportedpo" component={GPExportedPOScene} />
								<Route path="/list-supplier" component={ListSupplierScene} />
								<Route path="/view-supplier" component={ViewSupplierScene} />
								<Route path="/viewpr" component={ViewPurchaseRequestScene} />
								<Route exact path="/" render={() => <Redirect to="/home" />} />
								<Route path="/listprapproval" component={ListPRApprovalScene} />
								<Route path="/viewtransaction" component={ViewTransactionScene} />
								<Route path="/newprapprovalentity" component={NewApprovalNewScene} />
								<Route path="/addprapprovalcycle" component={NewApprovalCycleScene} />
								<Route path="/viewprapprovalcycle" component={ViewPRApprovalCycleScene} />
							</div>
						</div>
					</div>
				</Suspense>
			</LocaleContext.Provider>
		);
	} else {
		return (
			<div className="app-main__inner">
				<Route path="/" component={LoginScene} />
			</div>
		);
	}
};
export default withRouter(AppMain);
