import React from "react";
import { Link } from "react-router-dom";

const ViewIcon = (props) => {
	return (
		<>
			{props.target ? (
				<Link target={props.target} to={props.to} className="btn ">
					<i
						className={`bi bi-eye-fill info-hover ${props.cssclass}`}
						style={{ fontSize: props.iconsize || "1.1rem", cursor: "pointer" }}></i>
				</Link>
			) : (
				<i
					onClick={props.onview}
					className={`bi bi-eye-fill info-hover ${props.cssclass}`}
					style={{ fontSize: props.iconsize || "1.1rem", cursor: "pointer" }}
				/>
			)}
		</>
	);
};
export default ViewIcon;
