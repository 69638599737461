import i18n from "i18n";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import exportMacroFile from "./export_po_macro";
import React, { Component, Fragment } from "react";
import ConfirmPopUP from "../../common/confirm_popup";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "purch/common/get_permission";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { AlertNotification } from "../../common/alert_notification";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class GPExportPO extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			pos: [],
			selectedPOs: [],
		};
	}

	getNotExportedPOs = () => {
		this.setState({ loading: true });
		axios
			.get(`po/?serializer=get&status=Approved&isExportedGP=False&ordering=-creationDate`)
			.then((res) => {
				this.setState({ pos: res.data, loading: false });
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else {
					AlertNotification(null, "error");
				}
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getNotExportedPOs();
	}

	confirmExport = () => {
		ConfirmPopUP({
			title: i18n.t("confirmExportToGP"),
			cancelbtntxt: i18n.t("Cancel"),
			confirmbtntxt: i18n.t("confirm"),
			confirmedResult: () => this.exportPo(),
		});
	};

	exportPo = () => {
		this.setState({ loading: true });
		const poIDS = this.state.selectedPOs.map((po) => po.id);
		axios
			.put(`po/bulkupdate/`, { ids: poIDS, isExportedGP: true })
			.then((res) => {
				const remainingPOs = this.state.pos.filter((po) => !poIDS.includes(po.id));
				this.setState({ pos: remainingPOs, selectedPOs: [] }, () => {
					this.setState({ loading: false });
				});
				exportMacroFile(res.data);
			})
			.catch((err) => {
				if (err.response) {
					AlertNotification(err.response.data.split(".")[0], "error", err.response.status);
					console.error(err.response);
				} else {
					AlertNotification(null, "error");
				}
				this.setState({ loading: false });
			});
	};

	goToIsExported = () => {
		this.props.history.push({ pathname: "/gpexportedpo" });
	};

	// check if field is null set col to -
	nullChecker = (cell) => (!cell ? "-" : cell);

	render() {
		const t = i18n.t;
		const headerFormatter = (column, colIndex) => <span>{t(column.text)}</span>;
		const columns = [
			{
				text: "code",
				dataField: "code",
				headerFormatter: headerFormatter,
			},
			{
				text: "status",
				dataField: "status",
				classes: (cell) => "text-info",
				headerFormatter: headerFormatter,
			},
			{
				text: "supplier",
				dataField: "supplier.name",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
			{
				text: "totalCostAfterTax",
				dataField: "totalCostAfterTax",
				formatter: (cell) => <div>{Number(cell).toLocaleString()}</div>,
				headerFormatter: headerFormatter,
			},
			{
				text: "orderDate",
				dataField: "creationDate",
				formatter: this.nullChecker,
				headerFormatter: headerFormatter,
			},
		];
		const selectRow = {
			mode: "checkbox",
			headerColumnStyle: { backgroundColor: "white" },
			onSelect: (row, isSelect, rowIndex, e) => {
				let { selectedPOs } = this.state;
				if (isSelect) {
					selectedPOs.push(row);
				} else {
					selectedPOs = selectedPOs.filter((po) => po.id !== row.id);
				}
				this.setState({ selectedPOs });
			},
			onSelectAll: (isSelect, rows, e) => {
				if (isSelect) {
					this.setState({ selectedPOs: rows });
				} else {
					this.setState({ selectedPOs: [] });
				}
			},
		};

		return (
			<Fragment>
				<Row>
					<Col>
						<PageTitleAlt2 heading={t("purchaseOrders")} icon="bi bi-bag icon-gradient bg-happy-fisher" />
					</Col>
					<Col>
						<div className="text-right mb-4 mx-2">
							<GetPermission perm="purch.change_purchaseorder">
								<Button className="right" color="danger" onClick={this.goToIsExported} code="perm">
									{t("gpExportedPOs")}
								</Button>
							</GetPermission>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<div className="text-right mb-4 mx-2">
									<GetPermission perm="purch.change_purchaseorder">
										<Button className="right" color="dark" disabled={this.state.selectedPOs.length === 0} onClick={this.confirmExport} code="perm">
											{t("gpExport")}
										</Button>
									</GetPermission>
								</div>
								<TableWithPaginationSearch
									scrollable
									search
									keyfield="id"
									columns={columns}
									data={this.state.pos}
									loading={this.state.loading}
									selectRow={selectRow}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withTranslation()(withRouter(GPExportPO));
