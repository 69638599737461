import React, {Component, Fragment} from "react";
import {
    Button,
    Card,
    CardBody, CardHeader,
    Col, Collapse,
    Form,
    FormGroup,
    Input,
    Label, ListGroup, ListGroupItem,
    Row
} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import CustomSelect from "../../common/custom_select";
import PageTitleAlt2 from "../../common/PageTitleAlt2";

class NewApprovalCycle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFormValid: true,
            editMode: false,
            prTypes: [],
            departments: [],
            urgency: [],
            entities: [],
            accordion: [true],
            users: []
        }
    }

    componentDidMount() {
        if (this.props.history.location.prApprovalCycle) {
            this.setState({
                editMode: true,
                accordion: [false, false, false, false],
                entities: [{
                    title: "Software",
                    sortOrder: 1,
                    approvalUser: "Noura",
                    approvalDepartment: ""
                }, {
                    title: "IT",
                    sortOrder: 2,
                    approvalUser: "Ahmed",
                    approvalDepartment: ""
                }, {
                    title: "DevOps",
                    sortOrder: 3,
                    approvalUser: "Mariam",
                    approvalDepartment: ""
                }, {
                    title: "HR Manager",
                    sortOrder: 4,
                    approvalUser: "",
                    approvalDepartment: "HR"
                }]
            });
        }
    }

    onInvalidForm = () => {

    }

    onSubmit = () => {

    }

    onInvalidEntityForm = () => {

    }

    onSubmitEntityForm = () => {

    }

    handleOnSelectPrType = () => {

    }

    handleOnSelectDepartment = () => {

    }

    handleOnSelectUrgency = () => {

    }

    handleOnSelectUser = () => {

    }

    toggleAccordion(tab) {
        /* This method to only view one Accordion and collapse others*/
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({accordion: state});
    }

    addFormAccordion = () => {
        let {accordion} = this.state;
        accordion = accordion.map((v) => (v = false));
        accordion.push(true);
        this.setState({accordion});
    };

    removeLastFormAccordion = () => {
        let {accordion} = this.state;
        accordion.splice(accordion.length - 1, 1);
        this.setState({accordion});
    };

    render() {
        const {t} = this.props;

        return (
            <Fragment>
                <PageTitleAlt2 heading={"PR Approval Cycle"} icon="bi bi-patch-check icon-gradient bg-happy-fisher"/>

                <Row>
                    <Col md="12" lg="12">
                        <Card className="mt-3">
                            <CardHeader className="card-header-tab">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
                                        {" "}
                                    </i>
                                    PR Approval Cycle
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form onInvalid={this.onInvalidForm} onSubmit={this.onSubmit}>
                                    <Row className="mt-2">
                                        <Col>
                                            <FormGroup>
                                                <Label>{t("name")}</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.name}
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="prType">{t("prType")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectPrType}
                                                    label="PrType"
                                                    options={this.state.prTypes}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="PrType"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="department">{t("department")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectDepartment}
                                                    label="Department"
                                                    options={this.state.departments}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="Department"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label for="urgency">{t("urgency")}</Label>
                                                <CustomSelect
                                                    required
                                                    inValid={!this.state.isFormValid}
                                                    onChange={this.handleOnSelectUrgency}
                                                    label="Urgency"
                                                    options={this.state.urgency}
                                                    optionlable="name"
                                                    defaultValue={null}
                                                    name="Urgency"
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label>{t("costLimit")}</Label>
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.costLimit}
                                                    disabled={this.state.editMode}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="info"
                                            hidden={this.state.editMode}
                                            className="float-right">{t("submit")}</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md="7" lg="7">
                        <Card className="mt-3">
                            <CardHeader className="card-header-tab">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className="header-icon bi bi-patch-check mr-2 text-muted opacity-6 icon-gradient bg-happy-fisher">
                                        {" "}
                                    </i>
                                    PR Approval Entity
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="form-wizard-content">
                                    <Row>
                                        <Col>
                                            <div id="accordion" className="accordion-wrapper mb-3">
                                                {this.state.accordion.map((value, index) => {
                                                        return <Card key={index}>
                                                            <CardHeader id="headingOne">
                                                                <Button block color="white" className="text-left m-0 p-0"
                                                                        onClick={() => this.toggleAccordion(index)}
                                                                        aria-expanded={this.state.accordion[index]}
                                                                        aria-controls="collapseOne">
                                                                    <h5>{this.state.entities[index] ?
                                                                        this.state.entities[index].title :
                                                                        <span>Entity #{index + 1}</span>}
                                                                    </h5>
                                                                </Button>
                                                            </CardHeader>
                                                            <Collapse isOpen={this.state.accordion[index]}
                                                                      data-parent="#accordion"
                                                                      id="collapseOne" aria-labelledby="headingOne">
                                                                <CardBody>
                                                                    <Form onInvalid={this.onInvalidEntityForm}
                                                                          onSubmit={this.onSubmitEntityForm}>
                                                                        <Row className="mt-2">
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label>{t("title")}</Label>
                                                                                    <Input type="text"/>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label for="department">
                                                                                        {t("approvalDepartment")}</Label>
                                                                                    <CustomSelect
                                                                                        required
                                                                                        inValid={!this.state.isFormValid}
                                                                                        onChange={this.handleOnSelectDepartment}
                                                                                        label="Department"
                                                                                        options={this.state.departments}
                                                                                        optionlable="name"
                                                                                        defaultValue={null}
                                                                                        name="Department"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label for="department">
                                                                                        {t("approvalUser")}</Label>
                                                                                    <CustomSelect
                                                                                        required
                                                                                        inValid={!this.state.isFormValid}
                                                                                        onChange={this.handleOnSelectUser}
                                                                                        label="User"
                                                                                        options={this.state.users}
                                                                                        optionlable="name"
                                                                                        defaultValue={null}
                                                                                        name="user"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label>{t("sortOrder")}</Label>
                                                                                    <Input type="number"/>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Button type="submit"
                                                                                color="info"
                                                                                className="float-right mb-2">
                                                                            {t("submit")}</Button>
                                                                    </Form>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    }
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            {this.state.accordion.length > 1 &&
                                                this.state.entities.length < this.state.accordion.length && (
                                                    <Button color="danger" className="float-left"
                                                            onClick={this.removeLastFormAccordion}>
                                                        Remove Last Entity
                                                    </Button>
                                                )}
                                        </Col>
                                        <Col>
                                            <Button
                                                color="dark"
                                                className="float-right"
                                                // disabled={this.state.entities.length !== this.state.accordion.length}
                                                onClick={this.addFormAccordion}>
                                                Add Entity
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="12" lg="5">
                        <Card className="main-card mt-3">
                            <CardHeader style={{color: '#495057'}}>Pr Approval Entities</CardHeader>
                        </Card>
                        <ListGroup className="todo-list-wrapper" flush style={{backgroundColor: "none"}}>
                            {this.state.entities &&
                                this.state.entities.map((entity, index) => {
                                    return (
                                        <ListGroupItem key={entity.id}>
                                            <div className="todo-indicator bg-info"/>
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                             <span className="badge badge-pill badge-dark mr-2">
																{entity.sortOrder}
															</span>
                                                            {entity.title}
                                                        </div>
                                                        <div className="widget-subheading ml-2">
                                                            <i>{entity.approvalDepartment}</i>
                                                        </div>
                                                        <div className="widget-subheading ml-2">
                                                            <i>{entity.approvalUser}</i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    );
                                })}
                        </ListGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withTranslation()(withRouter(NewApprovalCycle));
