import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { sideMenu } from "./NavItems";
import GetPermission from "purch/common/get_permission";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { withTranslation } from "react-i18next";

class Nav extends Component {
	state = {};

	toggleMobileSidebar = () => {
		let { enableMobileMenu, setEnableMobileMenu } = this.props;
		setEnableMobileMenu(!enableMobileMenu);
	};

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<MetisMenu
					iconNamePrefix=""
					activeLinkFromLocation
					content={sideMenu(t).Home}
					className="vertical-nav-menu"
					onSelected={this.toggleMobileSidebar}
					classNameStateIcon="pe-7s-angle-down"
				/>
				<GetPermission perm="purch.view_purchaserequest">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).PR}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="purch.view_purchaseorder">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).PO}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="purch.view_receiving">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							className="vertical-nav-menu"
							content={sideMenu(t).Receiving}
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="purch.view_supplier">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							className="vertical-nav-menu"
							content={sideMenu(t).supplier}
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="inventory.view_transaction">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).trx}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="purch.view_purchaseorder">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
							content={sideMenu(t).dataStudioReports}
						/>
					</div>
				</GetPermission>
			</Fragment>
		);
	}

	isPathActive = (path) => {
		return this.props.location.pathname.startsWith(path);
	};
}

const mapStateToProps = (state) => ({
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav)));
